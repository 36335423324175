import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nav" }
const _hoisted_2 = { class: "aside-body-content" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_calendar_outlined = _resolveComponent("calendar-outlined")!
  const _component_bar_chart_outlined = _resolveComponent("bar-chart-outlined")!
  const _component_team_outlined = _resolveComponent("team-outlined")!
  const _component_scissor_outlined = _resolveComponent("scissor-outlined")!
  const _component_logo = _resolveComponent("logo")!
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_calendar_filters = _resolveComponent("calendar-filters")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'app-iconbar': true, 'iconbar-open': _ctx.appState.isIconbarOpen })
  }, [
    _withDirectives(_createElementVNode("div", {
      class: "burger-open",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.appState.isIconbarOpen = true))
    }, [
      _createVNode(_component_menu_unfold_outlined, {
        class: _normalizeClass({ 'hide-burger-open': _ctx.appState.isIconbarOpen, 'iconbar-icon': true })
      }, null, 8, ["class"])
    ], 512), [
      [_vShow, _ctx.appState.currentPage === _ctx.getCalendarPageId()]
    ]),
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("a", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changePage(_ctx.getCalendarPageId()))),
        class: _normalizeClass({
          'nav-link': true,
          'iconbar-nav-link-active': _ctx.appState.currentPage === _ctx.getCalendarPageId(),
        })
      }, [
        _createVNode(_component_calendar_outlined, { class: "iconbar-icon" }),
        _cache[6] || (_cache[6] = _createTextVNode(" Calendario "))
      ], 2),
      _createElementVNode("a", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changePage(_ctx.getAnalysisPageId()))),
        class: _normalizeClass({
          'nav-link': true,
          'iconbar-nav-link-active': _ctx.appState.currentPage === _ctx.getAnalysisPageId(),
        })
      }, [
        _createVNode(_component_bar_chart_outlined, { class: "iconbar-icon" }),
        _cache[7] || (_cache[7] = _createTextVNode(" Analisi "))
      ], 2),
      _createElementVNode("a", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changePage(_ctx.getUsersPageId()))),
        class: _normalizeClass({
          'nav-link': true,
          'iconbar-nav-link-active': _ctx.appState.currentPage === _ctx.getUsersPageId(),
        })
      }, [
        _createVNode(_component_team_outlined, { class: "iconbar-icon" }),
        _cache[8] || (_cache[8] = _createTextVNode("Utenti "))
      ], 2),
      _createElementVNode("a", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changePage(_ctx.getPerformancesPageId()))),
        class: _normalizeClass({
          'nav-link': true,
          'iconbar-nav-link-active': _ctx.appState.currentPage === _ctx.getPerformancesPageId(),
        })
      }, [
        _cache[9] || (_cache[9] = _createElementVNode("i", { class: "las la-cog" }, null, -1)),
        _createVNode(_component_scissor_outlined, { class: "iconbar-icon" }),
        _cache[10] || (_cache[10] = _createTextVNode(" Prestazioni "))
      ], 2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({ 'aside-header': true, 'aside-header-show': _ctx.appState.isIconbarOpen })
    }, [
      _createVNode(_component_logo),
      _createVNode(_component_menu_fold_outlined, {
        class: _normalizeClass({
          'iconbar-icon': true,
          'burger-close': true,
        }),
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.appState.isIconbarOpen = false))
      })
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass({ 'aside-body': true, 'aside-body-show': _ctx.appState.isIconbarOpen })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_date_picker, {
          visible: _ctx.appState.currentPage === _ctx.getCalendarPageId() && _ctx.appState.isIconbarOpen,
          weekFilter: true,
          maxRangeFilter: true,
          maxRange: 6,
          onChange: _ctx.calendarDatePickerChange,
          plocKey: _ctx.getCalendarPLOCKey()
        }, null, 8, ["visible", "onChange", "plocKey"]),
        (_ctx.appState.currentPage === _ctx.getCalendarPageId() && _ctx.appState.isIconbarOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_calendar_filters)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 2))
}