import { v4 as uuidv4 } from "uuid";
import { CUSTOMER_TYPE_ID, EMPLOYEE_TYPE_ID, TUserWithValidation, UserDS } from "../UserDS";
import { User } from "../User";
import UsersRepository from "../UsersRepository";
import GetFilteredUsersUseCase from "./GetFilteredUsersUseCase";
import { IPivotOptions } from "@/core/pivot/domain/PivotOptionsDS";
import NotificationsManager from "@/core/notifications/domain/NotificationsManager";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";
export default class UserDetailUseCase {
  private static PWD_PLACEHOLDER = "********";
  public static createNewUser() {
    return {
      id: uuidv4(),
      name: "",
      surname: "",
      type: CUSTOMER_TYPE_ID,
      color: "",
      phone: "",
      mail: "",
      deleted: false,
      password: "",
      isNew: true,
      colorOk: false,
      mailOk: false,
      pwdOk: false,
      nameOk: false,
      surnameOk: false,
    };
  }

  public static changeUserType(userDetail: TUserWithValidation, newUserType: string) {
    return {
      ...userDetail,
      type: newUserType,
      color: newUserType === CUSTOMER_TYPE_ID ? "" : "#c2d9ed",
    };
  }

  public static validateName(name: string) {
    return name !== "" && name.length <= 20;
  }

  public static validateSurname(surname: string) {
    return surname !== "" && surname.length <= 20;
  }

  public static validateColor(userDetail: TUserWithValidation, usersRepository: UsersRepository) {
    let result = true;

    if (userDetail.type === EMPLOYEE_TYPE_ID) {
      const employees = GetFilteredUsersUseCase.getEmployeesByColor(usersRepository, userDetail.color, userDetail.id);
      result = employees.length === 0;
    }

    return result;
  }

  public static validatePwd(userDetail: TUserWithValidation) {
    let result = true;
    if (userDetail.type === EMPLOYEE_TYPE_ID) {
      const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
      if (userDetail.password !== UserDetailUseCase.PWD_PLACEHOLDER || userDetail.isNew)
        result = passwordRegex.test(userDetail.password);
    }
    return result;
  }

  public static validateMail(userDetail: TUserWithValidation, usersRepository: UsersRepository) {
    let result = true;
    if (userDetail.type === EMPLOYEE_TYPE_ID) {
      result =
        User.checkMail(userDetail.mail) &&
        GetFilteredUsersUseCase.getEmployeesByMail(usersRepository, userDetail.mail, userDetail.id).length === 0;
    }
    return result;
  }

  public static validateUser(userDetail: TUserWithValidation, usersRepository: UsersRepository) {
    const nameOk = UserDetailUseCase.validateName(userDetail.name);
    const surnameOk = UserDetailUseCase.validateSurname(userDetail.surname);
    const colorOk = UserDetailUseCase.validateColor(userDetail, usersRepository);
    const mailOk = UserDetailUseCase.validateMail(userDetail, usersRepository);
    const pwdOk = UserDetailUseCase.validatePwd(userDetail);
    return {
      colorOk,
      mailOk,
      pwdOk,
      nameOk,
      surnameOk,
      saving: true,
      canSave: nameOk && mailOk && surnameOk && colorOk && pwdOk,
    };
  }

  public static async saveUser(userDetail: TUserWithValidation, usersRepository: UsersRepository) {
    let saveResult = { success: true, errorMsg: "" };
    const notificationsManager = NotificationsManager.getInstance();
    let okMsg = "";
    let koMsg = "";
    const userToSave: UserDS = {
      id: userDetail.id,
      name: userDetail.name,
      surname: userDetail.surname,
      type: userDetail.type,
      color: userDetail.color,
      phone: userDetail.phone,
      mail: userDetail.mail,
      password: userDetail.password,
      deleted: false,
      pivotOptions: {} as IPivotOptions,
    };

    if (userDetail.isNew) {
      saveResult = await usersRepository.saveUser(userToSave);
      okMsg = "Utente creato con successo";
      koMsg = "Creazione dell'utente fallita";
    } else {
      const oldUser = usersRepository.getUserById(userDetail.id);
      userToSave.pivotOptions = oldUser.pivotOptions;
      userToSave.password = userDetail.password === UserDetailUseCase.PWD_PLACEHOLDER ? "" : userDetail.password;
      saveResult = await usersRepository.updateUser(userToSave);
      okMsg = "Utente aggiornato con successo";
      koMsg = "Aggiornamento dell'utente fallito";
    }

    if (saveResult.success) notificationsManager.pushNewNotification(okMsg, "", NotificationType.Ok);
    else notificationsManager.pushNewNotification(koMsg, saveResult.errorMsg, NotificationType.Ko);

    return saveResult;
  }

  public static discardChanges() {
    return {
      id: "",
      name: "",
      surname: "",
      type: "",
      color: "",
      phone: "",
      mail: "",
      password: "",
      deleted: false,
      visible: false,
      title: "",
      nameOk: false,
      mailOk: false,
      surnameOk: false,
      colorOk: false,
      isNew: false,
      saving: false,
      pwdOk: false,
    };
  }

  public static editUser(userId: string, usersRepository: UsersRepository, showModal: boolean) {
    const user = usersRepository.getUserById(userId);
    return {
      ...user,
      password: UserDetailUseCase.PWD_PLACEHOLDER,
      visible: showModal,
      title: "",
      nameOk: false,
      mailOk: false,
      surnameOk: false,
      colorOk: false,
      isNew: false,
      saving: false,
      pwdOk: false,
    };
  }

  public static resetPwd(userDetail: TUserWithValidation) {
    return {
      ...userDetail,
      password: userDetail.type === EMPLOYEE_TYPE_ID && !userDetail.isNew ? "" : userDetail.password,
    };
  }

  public static canDeleteCurrentUser(userDetail: TUserWithValidation, loggedUserId: string) {
    return !userDetail.isNew && loggedUserId !== userDetail.id;
  }

  public static canDeleteUser(userId: string, loggedUserId: string) {
    return loggedUserId !== userId;
  }
}
