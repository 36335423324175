import UsersRepository from "@/core/users/domain/UsersRepository";
import Calendar from "../Calendar";
import CalendarEventDS, { EventAction, TCalendarEventWithValidation } from "../CalendarEventDS";
import EventsRepository from "../EventsRepository";
import EventValidationUseCase from "./EventValidationUseCase";
import EventColorsApplier from "../EventColorsApplier";
import NotificationsManager from "@/core/notifications/domain/NotificationsManager";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";

export default class EditEventUseCase {
  public static setup(event: CalendarEventDS): TCalendarEventWithValidation {
    return {
      ...event,
      timeExtremesOk: true,
      performanceOk: true,
      customerOk: true,
      employeesOk: true,
      costOk: true,
      toSave: true,
      isNew: false,
    };
  }

  public static async tearDown(
    action: EventAction,
    calendar: Calendar,
    eventsRepo: EventsRepository,
    usersRepo: UsersRepository,
    event: TCalendarEventWithValidation
  ) {
    const notificationsManager = NotificationsManager.getInstance();
    const result = {
      success: false,
      event: {} as TCalendarEventWithValidation,
      errorMsg: "",
    };
    let notificaitonTitleOk = "";
    let notificationTitleKo = "";
    switch (action) {
      case EventAction.Save:
        // eslint-disable-next-line no-case-declarations
        const validationResult = EventValidationUseCase.validateEvent(event);
        if (validationResult.canSave) {
          EventColorsApplier.applyEventColors(event, usersRepo);

          const repoUpdateOk = await eventsRepo.update(event);

          if (repoUpdateOk.success) calendar.updateEvent(event);
          else result.errorMsg = repoUpdateOk.errorMsg;

          result.success = repoUpdateOk.success;
        } else result.event = validationResult.validatedEvent;

        notificaitonTitleOk = "Evento modificato con successo";
        notificationTitleKo = "Modifica dell'evento fallita";
        break;
      case EventAction.Delete:
        // eslint-disable-next-line no-case-declarations
        const eventDeleteOk = await eventsRepo.deleteEvent(event.id);
        if (eventDeleteOk.success) calendar.removeEvent(event.id);
        else result.errorMsg = eventDeleteOk.errorMsg;

        result.success = eventDeleteOk.success;

        notificaitonTitleOk = "Evento cancellato con successo";
        notificationTitleKo = "Cancellazione dell'evento fallita";
        break;
      case EventAction.DiscardChanges:
        break;
    }

    if (result.success) notificationsManager.pushNewNotification(notificaitonTitleOk, "", NotificationType.Ok);
    else notificationsManager.pushNewNotification(notificationTitleKo, result.errorMsg, NotificationType.Ko);

    return result;
  }
}
