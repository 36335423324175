import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal user-modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-close" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "title-icon" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "modal-field" }
const _hoisted_8 = { class: "modal-field-value" }
const _hoisted_9 = { class: "error" }
const _hoisted_10 = { class: "modal-field" }
const _hoisted_11 = { class: "modal-field-value" }
const _hoisted_12 = { class: "error" }
const _hoisted_13 = { class: "modal-field" }
const _hoisted_14 = { class: "modal-field-value" }
const _hoisted_15 = { class: "error" }
const _hoisted_16 = { class: "modal-field" }
const _hoisted_17 = { class: "modal-field-value" }
const _hoisted_18 = { class: "modal-field" }
const _hoisted_19 = { class: "modal-field" }
const _hoisted_20 = { class: "modal-field" }
const _hoisted_21 = { class: "modal-field-value" }
const _hoisted_22 = { class: "color-picker" }
const _hoisted_23 = { class: "color-code" }
const _hoisted_24 = { class: "error" }
const _hoisted_25 = { class: "modal-field" }
const _hoisted_26 = { class: "modal-field-value" }
const _hoisted_27 = { class: "error" }
const _hoisted_28 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_user_outlined = _resolveComponent("user-outlined")!
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_close_outlined, { onClick: _ctx.discardChanges }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_user_outlined)
        ]),
        _createElementVNode("h3", null, _toDisplayString(_ctx.usersState.userDetail.title), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[16] || (_cache[16] = _createElementVNode("label", {
            class: "modal-field-label",
            for: "name"
          }, "Nome: ", -1)),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              placeholder: "nome",
              class: "txt-input",
              type: "text",
              id: "name",
              name: "name",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.usersState.userDetail.name) = $event)),
              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateName && _ctx.validateName(...args)))
            }, null, 544), [
              [_vModelText, _ctx.usersState.userDetail.name]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_9, " Campo obbligatorio e al massimo di 20 caratteri ", 512), [
              [_vShow, _ctx.usersState.userDetail.saving && !_ctx.usersState.userDetail.nameOk]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[17] || (_cache[17] = _createElementVNode("label", {
            class: "modal-field-label",
            for: "surname"
          }, "Cognome: ", -1)),
          _createElementVNode("div", _hoisted_11, [
            _withDirectives(_createElementVNode("input", {
              placeholder: "cognome",
              class: "txt-input",
              type: "text",
              id: "surname",
              name: "surname",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.usersState.userDetail.surname) = $event)),
              onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.validateSurname && _ctx.validateSurname(...args)))
            }, null, 544), [
              [_vModelText, _ctx.usersState.userDetail.surname]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_12, " Campo obbligatorio e al massimo di 20 caratteri ", 512), [
              [_vShow, _ctx.usersState.userDetail.saving && !_ctx.usersState.userDetail.surnameOk]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[18] || (_cache[18] = _createElementVNode("label", {
            class: "modal-field-label",
            for: "mail"
          }, "Mail: ", -1)),
          _createElementVNode("div", _hoisted_14, [
            _withDirectives(_createElementVNode("input", {
              placeholder: "Es. pippo@gmail.com",
              class: "txt-input",
              type: "text",
              id: "mail",
              name: "mail",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.usersState.userDetail.mail) = $event)),
              onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.validateMail && _ctx.validateMail(...args)))
            }, null, 544), [
              [_vModelText, _ctx.usersState.userDetail.mail]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_15, " Inserire un indirizzo mail valido ", 512), [
              [_vShow, 
                _ctx.usersState.userDetail.saving &&
                _ctx.usersState.userDetail.type === _ctx.getEmployeeTypeId() &&
                !_ctx.usersState.userDetail.mailOk
              ]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _cache[19] || (_cache[19] = _createElementVNode("label", {
            class: "modal-field-label",
            for: "tel"
          }, "Telefono: ", -1)),
          _createElementVNode("div", _hoisted_17, [
            _withDirectives(_createElementVNode("input", {
              placeholder: "Es. 3317199550",
              class: "txt-input",
              type: "tel",
              id: "tel",
              name: "tel",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.usersState.userDetail.phone) = $event))
            }, null, 512), [
              [_vModelText, _ctx.usersState.userDetail.phone]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _cache[20] || (_cache[20] = _createElementVNode("label", { class: "modal-field-label" }, "Tipologia: ", -1)),
          _createVNode(_component_Select, {
            placeholder: 'Seleziona un valore',
            showLabels: false,
            width: 350,
            options: _ctx.getUserTypesIds(),
            selectedSingle: _ctx.usersState.userDetail.type,
            customLabelFn: _ctx.getUserTypeLabel,
            searchable: false,
            selectKey: 'userTypeKey',
            changeFn: _ctx.changeUserType,
            disabled: !_ctx.usersState.userDetail.isNew
          }, null, 8, ["options", "selectedSingle", "customLabelFn", "changeFn", "disabled"])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_19, [
          _cache[21] || (_cache[21] = _createElementVNode("label", {
            class: "modal-field-label",
            for: "cust-color"
          }, "Colore: ", -1)),
          _withDirectives(_createElementVNode("input", {
            placeholder: "Es. 10ml 7cc +20mlclear 6 30ml +7.4 15ml 20vol",
            class: "txt-input",
            type: "text",
            name: "cust-color",
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.usersState.userDetail.color) = $event))
          }, null, 512), [
            [_vModelText, _ctx.usersState.userDetail.color]
          ])
        ], 512), [
          [_vShow, _ctx.usersState.userDetail.type === _ctx.getCustomerTypeId()]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_20, [
          _cache[22] || (_cache[22] = _createElementVNode("label", {
            class: "modal-field-label",
            for: "emp-color"
          }, "Colore: ", -1)),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _withDirectives(_createElementVNode("input", {
                type: "color",
                id: "emp-color",
                name: "emp-color",
                onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.validateColor && _ctx.validateColor(...args))),
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.usersState.userDetail.color) = $event))
              }, null, 544), [
                [_vModelText, _ctx.usersState.userDetail.color]
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("span", null, _toDisplayString(_ctx.usersState.userDetail.color), 1)
              ])
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_24, " Il colore è in uso da un altro utente ", 512), [
              [_vShow, 
                _ctx.usersState.userDetail.saving &&
                _ctx.usersState.userDetail.type === _ctx.getEmployeeTypeId() &&
                !_ctx.usersState.userDetail.colorOk
              ]
            ])
          ])
        ], 512), [
          [_vShow, _ctx.usersState.userDetail.type === _ctx.getEmployeeTypeId()]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_25, [
          _cache[24] || (_cache[24] = _createElementVNode("label", {
            class: "modal-field-label",
            for: "pwd"
          }, "Password: ", -1)),
          _createElementVNode("div", _hoisted_26, [
            _withDirectives(_createElementVNode("input", {
              onFocus: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.onPwdFocus && _ctx.onPwdFocus(...args))),
              onInput: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.validatePwd && _ctx.validatePwd(...args))),
              placeholder: "",
              class: "txt-input",
              type: "password",
              name: "pwd",
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.usersState.userDetail.password) = $event))
            }, null, 544), [
              [_vModelText, _ctx.usersState.userDetail.password]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_27, _cache[23] || (_cache[23] = [
              _createTextVNode(" Password non valida, deve avere: "),
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, "almeno 8 caratteri"),
                _createElementVNode("li", null, "almeno un numero"),
                _createElementVNode("li", null, "almeno una maiuscola")
              ], -1)
            ]), 512), [
              [_vShow, 
                _ctx.usersState.userDetail.saving &&
                _ctx.usersState.userDetail.type === _ctx.getEmployeeTypeId() &&
                !_ctx.usersState.userDetail.pwdOk
              ]
            ])
          ])
        ], 512), [
          [_vShow, _ctx.usersState.userDetail.type === _ctx.getEmployeeTypeId()]
        ])
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-confirm",
          onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.saveUser && _ctx.saveUser(...args)))
        }, "Salva"),
        _withDirectives(_createElementVNode("button", {
          class: "btn btn-outline btn-outline-remove",
          onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.tryDeleteUser && _ctx.tryDeleteUser(...args)))
        }, " Elimina ", 512), [
          [_vShow, _ctx.canDeleteUser()]
        ]),
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-discard",
          onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.discardChanges && _ctx.discardChanges(...args)))
        }, "Annulla")
      ])
    ])
  ]))
}