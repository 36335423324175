<template>
  <div class="modal event-modal">
    <!-- Modal content -->
    <div class="modal-content">
      <div class="modal-close">
        <close-outlined @click="closeModal()"></close-outlined>
      </div>
      <div class="modal-header">
        <h3>{{ calendarState.event.title }}</h3>
      </div>
      <div class="modal-body">
        <div class="modal-field">
          <label class="modal-field-label">{{ getEventCustomerLabel() }}</label>
          <div class="modal-field-value">
            <Select
              :placeholder="getSelectEventCustomerPh()"
              :showLabels="true"
              :width="300"
              :options="getActiveCustomersIds()"
              :selectedSingle="calendarState.event.customer"
              :customLabelFn="formatUser"
              :searchable="true"
              :changeFn="setEventCustomer"
            >
            </Select>
            <div class="error" v-show="!calendarState.event.customerOk && calendarState.event.toSave">
              {{ getEventCustomerErrorMsg() }}
            </div>
          </div>
        </div>
        <div class="modal-field">
          <label class="modal-field-label">{{ getEventEmployeesLabel() }}</label>
          <div class="modal-field-value employees-field-value">
            <Select
              :placeholder="getSelectEventEmployeesPh()"
              :showLabels="true"
              :width="300"
              :multiple="true"
              :options="getActiveEmployeesIds()"
              :customLabelFn="formatUser"
              :searchable="true"
              :selectedMultiple="calendarState.event.employees"
              :changeFn="setEventEmployees"
            >
            </Select>
            <div class="error" v-show="!calendarState.event.employeesOk && calendarState.event.toSave">
              {{ getEventEmployeesErrorMsg() }}
            </div>
          </div>
        </div>
        <div class="modal-field">
          <label class="modal-field-label">{{ getEventPerformanceLabel() }}</label>
          <div class="modal-field-value">
            <Select
              :placeholder="getSelectEventPerformancePh()"
              :showLabels="true"
              :width="300"
              :options="getAllPerformancesIds()"
              :selectedSingle="calendarState.event.performance"
              :customLabelFn="getPefromanceName"
              :searchable="true"
              :changeFn="setEventPerformance"
            >
            </Select>
            <div class="error" v-show="!calendarState.event.performanceOk && calendarState.event.toSave">
              {{ getEventPerformanceErrorMsg() }}
            </div>
          </div>
        </div>
        <div class="modal-field">
          <label class="modal-field-label">{{ getEventHoursLabel() }}</label>
          <div class="modal-field-value">
            <div class="event-hour-ranges">
              <div class="event-hour">
                <label>{{ getEventHoursLabelStart() }}</label
                ><TimePicker
                  :minTime="getMinSelectableTime()"
                  :maxTime="getMaxSelectableTime()"
                  :date="calendarState.event.start"
                  :changeFn="setEventStart"
                >
                </TimePicker>
              </div>
              <div class="event-hour">
                <label>{{ getEventHoursLabelEnd() }}</label>
                <TimePicker
                  :minTime="getMinSelectableTime()"
                  :maxTime="getMaxSelectableTime()"
                  :date="calendarState.event.end"
                  :changeFn="setEventEnd"
                >
                </TimePicker>
              </div>
            </div>
            <div class="error" v-show="!calendarState.event.timeExtremesOk && calendarState.event.toSave">
              {{ getTimeExtremesErrorMsg() }}
            </div>
          </div>
        </div>
        <div class="modal-field">
          <label class="modal-field-label">{{ getSelectEventCostPh() }}</label>
          <div class="modal-field-value">
            <input
              type="number"
              v-model="calendarState.event.cost"
              class="form-control-thin"
              @change="validateEventCost"
            />
            <div class="error" v-show="!calendarState.event.costOk && calendarState.event.toSave">
              {{ getEventCostErrorMsg() }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline btn-outline-confirm" @click="saveEvent">
          {{ getSaveString() }}
        </button>
        <button class="btn btn-outline btn-outline-remove" @click="tryDeleteEvent">
          {{ getDeleteString() }}
        </button>
        <button class="btn btn-outline btn-outline-discard" @click="closeModal">
          {{ getDiscardString() }}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { CloseOutlined } from "@ant-design/icons-vue";
import TimePicker, { ITimePickerModel } from "../controls/TimePicker.vue";
import Select from "../controls/Select.vue";
import {
  EVENT_COST,
  SELECT_EVENT_CUSTOMER,
  SELECT_EVENT_EMPLOYEES,
  EVENT_HOURS,
  EVENT_HOURS_END,
  EVENT_HOURS_START,
  SELECT_EVENT_PERFORMANCE,
  EVENT_CUSTOMER,
  EVENT_PERFORMANCE,
  EVENT_EMPLOYEES,
  SAVE,
  DELETE,
  DISCARD,
  TIME_EXTREMES_ERROR,
  EVENT_CUSTOMER_ERROR,
  EVENT_EMPLOYEES_ERROR,
  EVENT_PERFORMANCE_ERROR,
  EVENT_COST_ERROR,
} from "@/core/common/utils/ResourceStrings";
import { CALENDAR_PLOC_KEY, PERFORMANCES_PLOC_KEY, USERS_PLOC_KEY } from "@/components/common/GUIConsts";
import { CalendarPLOC } from "@/core/calendar/presentation/CalendarPLOC";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import UsersPLOC from "@/core/users/presentation/UsersPLOC";
import PerformancesPLOC from "@/core/performances/presentation/PerformancesPLOC";
export default defineComponent({
  components: { CloseOutlined, TimePicker, Select },
  setup() {
    const calendarPLOC = inject(CALENDAR_PLOC_KEY) as CalendarPLOC;
    const calendarState = manageSubscription(calendarPLOC);
    const usersPLOC = inject(USERS_PLOC_KEY) as UsersPLOC;
    const performancesPLOC = inject(PERFORMANCES_PLOC_KEY) as PerformancesPLOC;

    const closeModal = () => {
      calendarPLOC.closeEventModal();
    };

    const getSelectEventPerformancePh = () => {
      return SELECT_EVENT_PERFORMANCE;
    };
    const getSelectEventCustomerPh = () => {
      return SELECT_EVENT_CUSTOMER;
    };
    const getSelectEventEmployeesPh = () => {
      return SELECT_EVENT_EMPLOYEES;
    };

    const getEventHoursLabel = () => {
      return EVENT_HOURS;
    };

    const getEventHoursLabelStart = () => {
      return EVENT_HOURS_START;
    };

    const getEventHoursLabelEnd = () => {
      return EVENT_HOURS_END;
    };
    const getSelectEventCostPh = () => {
      return EVENT_COST;
    };

    const getEventCustomerLabel = () => {
      return EVENT_CUSTOMER;
    };

    const getEventPerformanceLabel = () => {
      return EVENT_PERFORMANCE;
    };

    const getEventEmployeesLabel = () => {
      return EVENT_EMPLOYEES;
    };

    const getSaveString = () => {
      return SAVE;
    };
    const getDeleteString = () => {
      return DELETE;
    };
    const getDiscardString = () => {
      return DISCARD;
    };

    const getActiveCustomersIds = () => {
      return usersPLOC.getActiveCustomersIds();
    };

    const getActiveEmployeesIds = () => {
      return usersPLOC.getActiveEmployeesIds();
    };

    const formatUser = (id: string) => {
      return usersPLOC.formatUser(id);
    };

    const getAllPerformancesIds = () => {
      return performancesPLOC.getAllPerformancesIds();
    };

    const getPefromanceName = (id: string) => {
      return performancesPLOC.getPerformanceLabel(id);
    };

    const getCalendarPLOCKey = () => {
      return CALENDAR_PLOC_KEY;
    };

    const setEventCustomer = (customer: string) => {
      calendarState.value.event.customer = customer;
      calendarPLOC.validateEventCustomer();
    };

    const setEventEmployees = (employees: Array<string>) => {
      calendarState.value.event.employees = employees;
      calendarPLOC.validateEventEmployees();
    };

    const setEventPerformance = (performance: string) => {
      calendarState.value.event.performance = performance;
      calendarPLOC.validateEventPerformance();
      calendarState.value.event.cost = performancesPLOC.getPerformanceById(calendarState.value.event.performance).cost;
    };

    const setEventStart = (newStart: ITimePickerModel) => {
      calendarState.value.event.start.setHours(newStart.hours);
      calendarState.value.event.start.setMinutes(newStart.minutes);
      calendarPLOC.validateEventExtremes();
    };

    const setEventEnd = (newEnd: ITimePickerModel) => {
      calendarState.value.event.end.setHours(newEnd.hours);
      calendarState.value.event.end.setMinutes(newEnd.minutes);
      calendarPLOC.validateEventExtremes();
    };

    const getMinSelectableTime = () => {
      return calendarPLOC.getMinSelectableTime();
    };
    const getMaxSelectableTime = () => {
      return calendarPLOC.getMaxSelectableTime();
    };

    const getTimeExtremesErrorMsg = () => {
      return TIME_EXTREMES_ERROR;
    };
    const getEventCustomerErrorMsg = () => {
      return EVENT_CUSTOMER_ERROR;
    };
    const getEventEmployeesErrorMsg = () => {
      return EVENT_EMPLOYEES_ERROR;
    };
    const getEventPerformanceErrorMsg = () => {
      return EVENT_PERFORMANCE_ERROR;
    };
    const getEventCostErrorMsg = () => {
      return EVENT_COST_ERROR;
    };

    const validateEventCost = () => {
      return calendarPLOC.validateEventCost();
    };

    const saveEvent = () => {
      calendarPLOC.saveEvent();
    };

    const tryDeleteEvent = () => {
      calendarPLOC.tryDeleteEvent();
    };
    return {
      getSelectEventPerformancePh,
      getSelectEventCustomerPh,
      getSelectEventEmployeesPh,
      getEventHoursLabel,
      getEventHoursLabelStart,
      getEventHoursLabelEnd,
      getSelectEventCostPh,
      getEventCustomerLabel,
      getEventPerformanceLabel,
      getEventEmployeesLabel,
      getDeleteString,
      getSaveString,
      getDiscardString,
      closeModal,
      calendarState,
      getActiveCustomersIds,
      formatUser,
      getActiveEmployeesIds,
      getCalendarPLOCKey,
      getAllPerformancesIds,
      getPefromanceName,
      setEventCustomer,
      setEventEmployees,
      setEventPerformance,
      setEventStart,
      setEventEnd,
      getMaxSelectableTime,
      getMinSelectableTime,
      getTimeExtremesErrorMsg,
      getEventCustomerErrorMsg,
      getEventPerformanceErrorMsg,
      getEventCostErrorMsg,
      getEventEmployeesErrorMsg,
      validateEventCost,
      saveEvent,
      tryDeleteEvent,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

/* Modal Content */

.event-modal {
  .modal-body {
    align-items: center;
  }
  .event-hour-ranges {
    width: 300px;
    display: flex;
    justify-content: space-between;
    .event-hour {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 10px;
      }
      label {
        margin-right: 10px;
      }
    }
  }

  .form-control-thin {
    width: 300px;
    padding: 10px;
    height: $fields-height;
  }

  .employees-field-value {
    .multiselect {
      height: auto;
      min-height: $fields-height;

      .multiselect__select {
        height: 100%;
      }
      .multiselect__tags {
        height: auto;
        padding: 5px;
        min-height: calc($fields-height - 5px);

        .multiselect__placeholder {
          margin: 0px 0px 0px 4px;
          padding-top: 0px;
        }
      }
    }
  }
}
</style>
