import { IPivotOptions, allDimensions } from "../PivotOptionsDS";

export default class PivotEntitiesUtils {
  public static getSelectedMeasuresIds(opts: IPivotOptions) {
    return opts.measures.map((meas) => {
      return meas.alias;
    });
  }

  public static getSelectedDimensionsIds(opts: IPivotOptions) {
    return PivotEntitiesUtils.getSelectedDimensions(opts).map((dim) => {
      return dim.alias;
    });
  }

  public static getSelectedDimensions(opts: IPivotOptions) {
    return opts.rows.concat(opts.columns);
  }

  public static getDimensionDescription(dimAlias: string) {
    let result = "Dimensione non trovata";
    allDimensions.forEach((dim) => {
      if (dim.alias === dimAlias) result = dim.desc;
    });

    return result;
  }

  public static getDimensionFormat(dimAlias: string, opts: IPivotOptions) {
    let result = "";
    PivotEntitiesUtils.getSelectedDimensions(opts).forEach((dim) => {
      if (dim.alias === dimAlias) result = <string>dim.format;
    });

    return result;
  }
}
