<template>
  <div class="modal pivot-modal pivot-settings-modal">
    <div class="modal-content">
      <div class="modal-close">
        <close-outlined @click="closeModal()"></close-outlined>
      </div>
      <div class="modal-header">
        <div class="title-icon"><setting-outlined></setting-outlined></div>
        <h3>Impostazioni</h3>
      </div>
      <div class="modal-body">
        <fieldset class="opts-fieldset">
          <legend>Misure:</legend>
          <div class="opts-group">
            <div>
              <input
                type="radio"
                id="measuresOnColumn"
                checked
                name="measuresOnColumn"
                v-bind:value="true"
                v-model="pivotState.optsWorkingCopy.measuresOnColumn"
              />
              <label for="measuresOnColumn">Misure in colonna</label>
            </div>
            <div>
              <input
                type="radio"
                id="measuresOnRow"
                name="measuresOnColumn"
                v-bind:value="false"
                v-model="pivotState.optsWorkingCopy.measuresOnColumn"
              />
              <label for="measuresOnRow">Misure in riga</label>
            </div>
          </div>
        </fieldset>
        <fieldset class="opts-fieldset">
          <legend>Totali</legend>
          <div class="opts-group">
            <div>
              <input
                type="checkbox"
                id="bigTotalOnColumn"
                name="bigTotalOnColumn"
                v-model="pivotState.optsWorkingCopy.bigTotalOnColumn"
              />
              <label for="bigTotalOnColumn">Totale di colonna</label>
            </div>

            <div>
              <input
                type="checkbox"
                id="bigTotalOnRow"
                name="bigTotalOnRow"
                v-model="pivotState.optsWorkingCopy.bigTotalOnRow"
              />
              <label for="bigTotalOnRow">Totale di riga</label>
            </div>
          </div>
        </fieldset>
        <fieldset class="opts-fieldset">
          <legend>Subtotali</legend>
          <div class="opts-group">
            <div>
              <input
                type="checkbox"
                id="subTotalsOnColumn"
                name="subTotalsOnColumn"
                v-model="pivotState.optsWorkingCopy.subTotalsOnColumn"
              />
              <label for="subTotalsOnColumn">Subtotali di colonna</label>
            </div>

            <div>
              <input
                type="checkbox"
                id="subTotalsOnRow"
                name="subTotalsOnRow"
                v-model="pivotState.optsWorkingCopy.subTotalsOnRow"
              />
              <label for="subTotalsOnRow">Subtotali di riga</label>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline btn-outline-confirm" @click="saveOptions">
          {{ getSaveString() }}
        </button>

        <button class="btn btn-outline btn-outline-discard" @click="closeModal">
          {{ getDiscardString() }}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { CloseOutlined, SettingOutlined } from "@ant-design/icons-vue";
import { DISCARD, SAVE } from "@/core/common/utils/ResourceStrings";
import { PIVOT_PLOC_KEY } from "@/components/common/GUIConsts";
import PivotPLOC from "@/core/pivot/presentation/PivotPLOC";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import { SETTINGS_MODAL_ID } from "@/core/pivot/common/PivotConsts";
export default defineComponent({
  components: { CloseOutlined, SettingOutlined },
  setup() {
    const pivotPLOC = inject(PIVOT_PLOC_KEY) as PivotPLOC;
    let pivotState = manageSubscription(pivotPLOC);

    const closeModal = () => {
      pivotPLOC.closeOptionsModal();
    };

    const getSaveString = () => {
      return SAVE;
    };
    const getDiscardString = () => {
      return DISCARD;
    };

    const saveOptions = async () => {
      pivotPLOC.saveOptions(SETTINGS_MODAL_ID);
    };

    return {
      getSaveString,
      getDiscardString,
      closeModal,
      saveOptions,
      pivotState,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

/* Modal Content */

.pivot-settings-modal {
  .modal-content {
    .modal-body {
      .opts-fieldset {
        border: none;
        display: flex;
        flex-direction: row;

        legend {
          font-weight: 500;
        }

        .opts-group {
          display: flex;
          flex-direction: column;

          input {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
