import {
  APPLY_OPTIONS_MSG,
  ERROR_MSG,
  EXPORT_AS_CSV_MSG,
  GET_PIVOT_CHUNK_MSG,
  SET_INDEXED_DATA_MSG,
  TOGGLE_CELL_OPENING_MSG,
} from "../common/PivotConsts";
import IOnMessageResponseExternal from "./IOnMessageResponseExternal";
import IOnMessageResponseGUI from "./IOnMessageResponseExternal";
import IOnMessageResponseInternal from "./IOnMessageResponseInternal";
import IOnMessageResponsePivot from "./IOnMessageResponseInternal";
import IWorkerPostMessage from "./IWorkerPostMessage";

export default class WorkerManager extends IWorkerPostMessage {
  private worker: Worker;
  private lastPivotChunkWaitResponse: boolean;
  private internalListener: IOnMessageResponsePivot;
  private externalListener: IOnMessageResponseGUI;
  private workerInitialized: boolean;

  constructor(externalListener: IOnMessageResponseExternal, internalListener: IOnMessageResponseInternal) {
    super();
    this.worker = {} as Worker;
    this.lastPivotChunkWaitResponse = false;
    this.externalListener = externalListener;
    this.internalListener = internalListener;
    this.workerInitialized = false;
  }

  initWorker() {
    this.worker = new Worker(new URL("./pivot-worker.js", import.meta.url));
    this.worker.onmessage = ({ data }) => {
      switch (data.type) {
        case SET_INDEXED_DATA_MSG:
          this.externalListener.onSetIndexedData();
          break;
        case APPLY_OPTIONS_MSG:
          this.internalListener.onApplyOptions(data.result);
          this.externalListener.onApplyOptions();
          break;
        case TOGGLE_CELL_OPENING_MSG:
          this.internalListener.onToggleCellOpening(data.result);
          break;
        case GET_PIVOT_CHUNK_MSG:
          this.internalListener.onGetPivotChunk(data.result);
          this.lastPivotChunkWaitResponse = false;
          break;
        case EXPORT_AS_CSV_MSG:
          this.externalListener.onExportAsCSV(data);
          break;
        case ERROR_MSG:
          this.externalListener.onError(data);
          break;
        default:
      }
    };
    this.workerInitialized = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postMessage(messageObj: any) {
    this.worker.postMessage(messageObj);
  }

  public terminate() {
    if (this.workerInitialized && this.worker) this.worker.terminate();
    this.workerInitialized = false;
  }

  public isWaitingForPivotChunk(): boolean {
    return this.lastPivotChunkWaitResponse;
  }
  public setWaitingForPivotChunk(value: boolean): void {
    this.lastPivotChunkWaitResponse = value;
  }
}
