import { NotificationDS } from "../domain/NotificationDS";

export interface NotificationsState {
  visibleNotifications: Array<NotificationDS>;
  maxVisibleNotifications: number;
}

export const notificationsInitialState = {
  visibleNotifications: new Array<NotificationDS>(),
  maxVisibleNotifications: 0,
};
