import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createBlock(_component_VueDatePicker, {
    class: "app-time-picker",
    modelValue: _ctx.state,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state) = $event)),
      _ctx.onChange
    ],
    "time-picker": "",
    "max-time": _ctx.maxTime,
    "min-time": _ctx.minTime,
    "auto-apply": true,
    clearable: false,
    "minutes-increment": 15,
    "minutes-grid-increment": 15,
    "hide-input-icon": false,
    "input-class-name": 'form-control-thin'
  }, {
    "input-icon": _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("img", {
        class: "input-slot-image",
        src: "icons/clock-icon-ant-design.png"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["modelValue", "max-time", "min-time", "onUpdate:modelValue"]))
}