import { IEntity, IPivotOptions, allDimensions, allMeasures, defaultOptions } from "../PivotOptionsDS";
import {
  APPLY_OPTIONS_ACTION,
  CLOSE_MODAL_ACTION,
  ENTITIES_ERROR_ACTION,
  ENTITIES_MODAL_ID,
  FILTERS_MODAL_ID,
  FORMATS_MODAL_ID,
  INDEX_DATA_ACTION,
  ORDERING_MODAL_ID,
  SETTINGS_MODAL_ID,
} from "../../common/PivotConsts";
import PivotEntitiesUtils from "./PivotEntitiesUtils";
import PivotData from "../PivotData";
import { isObjectEmpty } from "@/core/common/utils/GenericUtils";
import NotificationsManager from "@/core/notifications/domain/NotificationsManager";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";

export interface ISaveResult {
  newOptions: IPivotOptions;
  actions: Array<string>;
  success: boolean;
}

export interface IOptionsOpening {
  optsWorkingCopy: IPivotOptions;
  availableDimensions: Array<IEntity>;
  availableMeasures: Array<IEntity>;
  filterSubtitle: string;
  modalVisible: boolean;
}

export class PivotOptionsEditing {
  private static calculateAvailableDimensions(dimSelection: Array<IEntity>) {
    const result = new Array<IEntity>();
    allDimensions.forEach((dim) => {
      const isSelected = dimSelection.find((selectedDim) => {
        return selectedDim.alias === dim.alias;
      });
      if (!isSelected) result.push(dim);
    });

    return result;
  }

  private static calculateAvailableMeasures(measureSelection: Array<IEntity>) {
    const result = new Array<IEntity>();
    allMeasures.forEach((meas) => {
      const isSelected = measureSelection.find((selectedMeas) => {
        return selectedMeas.alias === meas.alias;
      });
      if (!isSelected) result.push(meas);
    });

    return result;
  }

  public static async loadOptions(pivotData: PivotData) {
    const loadOptsResponse = await pivotData.loadOptions();
    if (loadOptsResponse.success) {
      return isObjectEmpty(loadOptsResponse.opts) ? defaultOptions : loadOptsResponse.opts;
    } else {
      console.error("PivotOptionsEditing/loadOptions cannot load options, using defaults");
      return defaultOptions;
    }
  }

  public static async saveOptions(
    workingOpts: IPivotOptions,
    optionsId: string,
    pivotData: PivotData
  ): Promise<ISaveResult> {
    const result = {} as ISaveResult;
    const newOptions = {
      ...workingOpts,
      rows: [...workingOpts.rows],
      columns: [...workingOpts.columns],
      measures: [...workingOpts.measures],
    };
    let saveOk = true;

    switch (optionsId) {
      case FILTERS_MODAL_ID:
        result.newOptions = newOptions;
        result.actions = [CLOSE_MODAL_ACTION, INDEX_DATA_ACTION];
        break;
      case FORMATS_MODAL_ID:
      case ORDERING_MODAL_ID:
      case SETTINGS_MODAL_ID:
        result.newOptions = newOptions;
        result.actions = [CLOSE_MODAL_ACTION, APPLY_OPTIONS_ACTION];
        break;
      case ENTITIES_MODAL_ID:
        if (newOptions.rows.length > 0 && newOptions.measures.length > 0) {
          //rimuovo i filtri relativi a dimensioni rimosse
          const newSelection = PivotEntitiesUtils.getSelectedDimensions(newOptions);
          const newFilters = newOptions.filters.filter((filter) => {
            return newSelection.some((dim) => {
              return filter.entityAlias === dim.alias;
            });
          });
          newOptions.filters = newFilters;

          result.newOptions = newOptions;
          result.actions = [CLOSE_MODAL_ACTION, INDEX_DATA_ACTION];
        } else {
          result.actions = [ENTITIES_ERROR_ACTION];
          saveOk = false;
        }
        break;
    }
    if (saveOk) {
      const notificationsManager = NotificationsManager.getInstance();
      const saveOptionsResponse = await pivotData.saveOptions(newOptions);
      if (saveOptionsResponse.success) {
        result.success = true;
        notificationsManager.pushNewNotification(
          "Salvataggio delle opzioni pivot avvenuto con successo",
          "",
          NotificationType.Ok
        );
      } else {
        result.success = false;
        console.error("PivotOptionsEditing/saveOptions: saving failed " + saveOptionsResponse.errorMsg);
        notificationsManager.pushNewNotification(
          "Salvataggio delle opzioni pivot fallito",
          "consultare i log per dettagli",
          NotificationType.Ko
        );
      }
    }

    return Promise.resolve(result);
  }

  private static createOptsWorkingCopy(currentOpts: IPivotOptions) {
    return {
      ...currentOpts,
      rows: JSON.parse(JSON.stringify(currentOpts.rows)),
      columns: JSON.parse(JSON.stringify(currentOpts.columns)),
      measures: JSON.parse(JSON.stringify(currentOpts.measures)),
      filters: JSON.parse(JSON.stringify(currentOpts.filters)),
    };
  }

  public static openOptions(currentOpts: IPivotOptions, optionsId: string, loading: boolean): IOptionsOpening {
    let availableDimensions = new Array<IEntity>();
    let availableMeasures = new Array<IEntity>();
    let filterSubtitle = "";

    if (loading)
      return {
        optsWorkingCopy: {} as IPivotOptions,
        availableDimensions,
        availableMeasures,
        filterSubtitle,
        modalVisible: false,
      };
    else {
      if (optionsId === ENTITIES_MODAL_ID) {
        availableDimensions = PivotOptionsEditing.calculateAvailableDimensions(
          PivotEntitiesUtils.getSelectedDimensions(currentOpts)
        );
        availableMeasures = PivotOptionsEditing.calculateAvailableMeasures(currentOpts.measures);
      }
      if (optionsId === FILTERS_MODAL_ID) {
        filterSubtitle = "Elenco filtri attivi";
      }
      return {
        optsWorkingCopy: PivotOptionsEditing.createOptsWorkingCopy(currentOpts),
        availableDimensions,
        availableMeasures,
        filterSubtitle,
        modalVisible: true,
      };
    }
  }
}
