/* eslint-disable */
import Calendar from "../domain/Calendar";
import { Calendar as FullCalendar } from "@fullcalendar/core";
import {
  DAY_VIEW_ID,
  FIVE_DAYS_VIEW_ID,
  FOUR_DAYS_VIEW_ID,
  LIST_WEEK_VIEW_ID,
  MONTH_VIEW_ID,
  SIX_DAYS_VIEW_ID,
  THREE_DAYS_VIEW_ID,
  TWO_DAYS_VIEW_ID,
  WEEEK_VIEW_ID,
} from "./CalendarConsts";
import DateUtils from "@/core/common/utils/DateUtils";
import FullCalendarController from "./FullCalendarController";
import CalendarEventDS from "../domain/CalendarEventDS";

interface ViewDurationMap {
  [id: string]: number;
}

export default class FullCalendarAdapter implements Calendar {
  private calendar: FullCalendar;

  private static viewIdToDuration: ViewDurationMap = {
    [TWO_DAYS_VIEW_ID]: 1,
    [THREE_DAYS_VIEW_ID]: 2,
    [FOUR_DAYS_VIEW_ID]: 3,
    [FIVE_DAYS_VIEW_ID]: 4,
    [SIX_DAYS_VIEW_ID]: 5,
    [WEEEK_VIEW_ID]: 6,
    [LIST_WEEK_VIEW_ID]: 6,
    [MONTH_VIEW_ID]: 28,
  };
  constructor(calendar: FullCalendar) {
    this.calendar = calendar;
  }

  public changeView(targetView: string): void {
    this.calendar.changeView(targetView);
  }

  public render(): void {
    this.calendar.render();
  }

  public goToDate(target: Date): void {
    this.calendar.gotoDate(target);
  }

  public getDateRange(): Array<Date> {
    if (this.calendar.view.type === DAY_VIEW_ID)
      return [this.calendar.view.currentStart, this.calendar.view.currentStart];
    else {
      const endDate = DateUtils.addDays(
        this.calendar.view.currentStart,
        FullCalendarAdapter.viewIdToDuration[this.calendar.view.type]
      );
      return [this.calendar.view.currentStart, endDate];
    }
  }

  public moveNext(): void {
    this.calendar.next();
  }

  public movePrev(): void {
    this.calendar.prev();
  }

  public getCurrentViewId(): string {
    return this.calendar.view.type;
  }

  public getDayViewId(): string {
    return DAY_VIEW_ID;
  }

  public getViewIds(): Array<string> {
    return [
      DAY_VIEW_ID,
      TWO_DAYS_VIEW_ID,
      THREE_DAYS_VIEW_ID,
      FOUR_DAYS_VIEW_ID,
      FIVE_DAYS_VIEW_ID,
      SIX_DAYS_VIEW_ID,
      WEEEK_VIEW_ID,
      MONTH_VIEW_ID,
      LIST_WEEK_VIEW_ID,
    ];
  }

  public getMonthViewId(): string {
    return MONTH_VIEW_ID;
  }

  public addEvent(event: CalendarEventDS) {
    this.calendar.addEvent(FullCalendarController.appToFullcalendarEvent(event));
  }

  public updateEvent(upToDateEvent: CalendarEventDS): void {
    const toRemove = this.calendar.getEventById(upToDateEvent.id);
    toRemove?.remove();
    this.calendar.addEvent(FullCalendarController.appToFullcalendarEvent(upToDateEvent));
  }

  public removeEvent(eventId: string): void {
    const toRemove = this.calendar.getEventById(eventId);
    toRemove?.remove();
  }

  refetchEvents() {
    this.calendar.refetchEvents();
  }

  async removeAllEvents() {
    await this.calendar.removeAllEvents();
  }
}
