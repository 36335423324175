<template>
  <div class="performances-list-container section-content" v-show="!performancesState.error">
    <virtual-searchable-list
      :full-list-count="performancesState.activePerformancesCount"
      :get-list-item-fn="getActivePerformanceByIndex"
      :get-list-item-text-fn="getListItemTextFn"
      :key="performancesState.redrawList"
      :item-height="42"
      @fiteredValuesLength="onSearchChange"
    >
      <template #customButtons>
        <div class="list-section-macro-part">
          <div class="fixed-part">
            <div class="macro-btns">
              <button class="list-macro-btn" @click="selectAllPerformances">Seleziona tutto</button>
              <button class="list-macro-btn" @click="deselectAllPerformances">Deseleziona tutto</button>
            </div>
            <div class="fixed-right-part">
              <div class="list-len-container">
                <span class="list-len">{{ performancesState.visiblePerformancesCount }} </span>
                elementi
              </div>
              <span class="separator">|</span>
              <i class="plus-icon" @click="createNewPerformance"><plus-outlined></plus-outlined> </i>
            </div>
          </div>
          <div class="selection-part" v-show="performancesState.selectedPerformancesCount > 0">
            <div>
              <span class="selection-number">{{ performancesState.selectedPerformancesCount }} </span>
              <span>elementi selezionati</span>
            </div>
            <span
              ><button class="list-macro-btn delete-selected" @click="tryDeletePerformance">
                <delete-outlined></delete-outlined></button
            ></span>
          </div>
        </div>
      </template>
      <template #searchedItem="{ item }">
        <div class="performance-container">
          <div class="left-part">
            <label class="custom-checkbox" :for="'input_' + item.id">
              <input
                type="checkbox"
                :id="'input_' + item.id"
                v-model="item.selected"
                @click="togglePerformanceSelection(item.id)"
              />
              <span class="checkmark"></span>
            </label>
            <div class="performance">
              <span class="performance-icon"><scissor-outlined></scissor-outlined></span>
              <span class="performance-desc" @click="editPerformance(item.id)"> {{ item.description }}</span>
            </div>
          </div>
          <div class="performance-actions">
            <span class="performance-action" @click="editPerformance(item.id)"> <edit-outlined></edit-outlined></span>
            <span class="performance-action" @click="deletePerformance(item.id)">
              <delete-outlined></delete-outlined
            ></span>
          </div>
        </div> </template
    ></virtual-searchable-list>
  </div>
  <div v-show="performancesState.error" class="status-icon-container">
    <div class="error-icon"><exclamation-circle-outlined></exclamation-circle-outlined></div>
    <span>Errore durante il caricamento delle prestazioni, consultare i log per dettagli</span>
  </div>
  <performance-detail v-show="performancesState.performanceDetail.visible"></performance-detail>

  <modal-confirm
    v-show="performancesState.performanceDeleteConfirmVisible"
    :title="'Sei sicuro di voler procedere con l\'eliminazione?'"
    :body="'L\'operazione è irreversibile.'"
    :show-btn-deny="true"
    :showBtnOk="true"
    :btnClickFn="onDeletePerformanceConfirmClose"
  ></modal-confirm>
</template>
<script lang="ts">
import { PERFORMANCES_PLOC_KEY } from "@/components/common/GUIConsts";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import { defineComponent, inject, onMounted, ref } from "vue";
import VirtualSearchableList from "../controls/list/VirtualSearchableList.vue";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  ScissorOutlined,
} from "@ant-design/icons-vue";
import PerformanceDetail from "./PerformanceDetail.vue";
import ModalConfirm from "../controls/ModalConfirm.vue";
import PerformancesPLOC from "@/core/performances/presentation/PerformancesPLOC";
import WindowResize from "@/core/common/utils/WindowResize";
export default defineComponent({
  components: {
    VirtualSearchableList,
    EditOutlined,
    DeleteOutlined,
    PerformanceDetail,
    PlusOutlined,
    ModalConfirm,
    ExclamationCircleOutlined,
    ScissorOutlined,
  },

  setup() {
    const performancesPLOC = inject(PERFORMANCES_PLOC_KEY) as PerformancesPLOC;
    const performancesState = manageSubscription(performancesPLOC);
    const itemHeight = ref({ heightValue: 42 });

    const getActivePerformanceByIndex = (index: number) => {
      return performancesPLOC.getActivePerformanceByIndex(index);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getListItemTextFn = (item: any) => {
      return item.description;
    };

    const togglePerformanceSelection = (userId: string) => {
      performancesPLOC.togglePerformanceSelection(userId);
    };

    const onWindowResize = (width: number) => {
      if (width <= 950) itemHeight.value.heightValue = 39;
      else itemHeight.value.heightValue = 42;
    };

    onMounted(async () => {
      await performancesPLOC.loadActivePefromances();
      WindowResize.getInstance().addListener("performanceList", onWindowResize.bind(this));
      onWindowResize(window.innerWidth);
    });

    const onSearchChange = (usersLength: number) => {
      performancesPLOC.visiblePerformancesChange(usersLength);
    };

    const createNewPerformance = () => {
      performancesPLOC.createNewPerformance();
    };

    const editPerformance = (id: string) => {
      performancesPLOC.editPerformance(id);
    };

    const onDeletePerformanceConfirmClose = async (confirm: boolean) => {
      if (confirm) await performancesPLOC.deletePerformances();
      else performancesPLOC.closePerformanceDeleteConfirm();
    };

    const tryDeletePerformance = () => {
      performancesPLOC.tryDeletePerformance();
    };

    const selectAllPerformances = () => {
      performancesPLOC.selectAllPerformances();
    };
    const deselectAllPerformances = () => {
      performancesPLOC.deselectAllPerformances();
    };

    const deletePerformance = async (id: string) => {
      performancesPLOC.setListPerformanceClicked(id);
      tryDeletePerformance();
    };

    return {
      performancesState,
      getActivePerformanceByIndex,
      getListItemTextFn,
      togglePerformanceSelection,
      onSearchChange,
      createNewPerformance,
      editPerformance,
      onDeletePerformanceConfirmClose,
      tryDeletePerformance,
      selectAllPerformances,
      deselectAllPerformances,
      deletePerformance,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

.performances-list-container {
  .performance-container {
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
    justify-content: space-between;

    .left-part {
      display: flex;
      align-items: center;
      padding-left: 10px;

      label {
        height: 25px;
        width: 25px;

        .checkmark {
          background: white;
          border: 1px solid $gray-400;
          &:hover {
            background: $gray-400;
          }
        }

        input {
          &:checked {
            ~ .checkmark {
              border: none;
            }
          }
        }
      }

      .performance {
        display: flex;
        align-items: center;
        margin-left: 5px;
        justify-content: space-between;

        .performance-icon {
          color: rgb(186, 126, 255);
          padding: 5px;
          font-size: 15px;
          @media only screen and (min-width: 750px) {
            font-size: 18px;
          }
        }

        .performance-desc {
          cursor: pointer;

          font-size: 16px;

          @media only screen and (max-width: 950px) {
            font-size: 12px;
          }
        }
      }
    }

    .performance-actions {
      display: flex;
      justify-self: flex-end;
      font-size: 20px;
      margin-right: 15px;

      .performance-action {
        cursor: pointer;
        color: $gray-500;

        &:first-child {
          margin-right: 5px;
        }

        &:hover {
          color: $gray-600;
        }
      }
    }

    &:hover {
      background: $gray-200;
    }
  }

  .list-item.even {
    /* Stili delle righe chiare */
    background-color: $gray-100;
  }
  /* Stili delle righe scure */
  .list-item.odd {
    background-color: white;
  }
}
</style>
