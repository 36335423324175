import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "modal pivot-modal pivot-fromats-modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-close" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "title-icon" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "entity-name" }
const _hoisted_8 = { class: "entity-format-opts" }
const _hoisted_9 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_font_size_outlined = _resolveComponent("font-size-outlined")!
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_close_outlined, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_font_size_outlined)
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Formattazione", -1))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "entity-format-header" }, [
          _createElementVNode("h5", null, "Dimensione"),
          _createElementVNode("h5", null, "Formato")
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFormattableDimensions(), (dim) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "entity-format",
            key: dim.alias
          }, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(dim.desc), 1),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Select, {
                placeholder: _ctx.getSelectPh(),
                showLabels: false,
                width: 270,
                options: [..._ctx.getEntityFormats(dim.alias)],
                selectedSingle: (dim.format as string),
                customLabelFn: _ctx.getEntityFormatDesc,
                searchable: false,
                selectKey: dim.alias,
                changeFn: _ctx.setEntityFormat
              }, null, 8, ["placeholder", "options", "selectedSingle", "customLabelFn", "selectKey", "changeFn"])
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-confirm",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.saveOptions && _ctx.saveOptions(...args)))
        }, _toDisplayString(_ctx.getSaveString()), 1),
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-discard",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, _toDisplayString(_ctx.getDiscardString()), 1)
      ])
    ])
  ]))
}