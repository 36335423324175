import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "page-header-right" }
const _hoisted_2 = {
  key: 0,
  class: "app-select-container"
}
const _hoisted_3 = { class: "page-header-account" }
const _hoisted_4 = { class: "user-header-profile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_profile_outlined = _resolveComponent("profile-outlined")!
  const _component_logout_outlined = _resolveComponent("logout-outlined")!

  return (_openBlock(), _createElementBlock("div", {
    class: "page-header",
    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeLoggedUserMenu()))
  }, [
    _createElementVNode("h3", {
      class: _normalizeClass({
        'page-header-title': true,
        'calendar-filtered': _ctx.appState.currentPage === _ctx.getCalendarPageId() && _ctx.calendarState.filters.isFilterActive,
      })
    }, _toDisplayString(_ctx.getCurrentPageTitle(_ctx.appState.currentPage)), 3),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.appState.currentPage === _ctx.getCalendarPageId())
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Select, {
              showLabels: false,
              width: 120,
              multiple: false,
              options: _ctx.calendarState.selectableViews,
              customLabelFn: _ctx.getViewTitleFromId,
              searchable: false,
              changeFn: _ctx.changeCalendarView,
              initialSelected: _ctx.calendarState.currrentView,
              selectedSingle: _ctx.calendarState.currentView
            }, null, 8, ["options", "customLabelFn", "changeFn", "initialSelected", "selectedSingle"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_UserAvatar, {
          userName: _ctx.getLoggedUser(_ctx.loginState.loggedUserId).name,
          userSurname: _ctx.getLoggedUser(_ctx.loginState.loggedUserId).surname,
          color: _ctx.getLoggedUser(_ctx.loginState.loggedUserId).color,
          clickable: true,
          show: true,
          dimension: 'md',
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggleLoggedUserMenuActive()), ["stop"]))
        }, null, 8, ["userName", "userSurname", "color"]),
        _withDirectives(_createElementVNode("div", {
          class: "dropdown-menu",
          onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createVNode(_component_close_outlined, {
            class: "user-menu-exit",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.closeLoggedUserMenu()), ["stop"]))
          }),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_UserAvatar, {
              userName: _ctx.getLoggedUser(_ctx.loginState.loggedUserId).name,
              userSurname: _ctx.getLoggedUser(_ctx.loginState.loggedUserId).surname,
              color: _ctx.getLoggedUser(_ctx.loginState.loggedUserId).color,
              clickable: false,
              dimension: 'lg'
            }, null, 8, ["userName", "userSurname", "color"]),
            _createElementVNode("h6", null, _toDisplayString(_ctx.getLoggedUser(_ctx.loginState.loggedUserId).name + " " + _ctx.getLoggedUser(_ctx.loginState.loggedUserId).surname), 1),
            _cache[6] || (_cache[6] = _createElementVNode("span", null, "Impiegato", -1))
          ]),
          _createElementVNode("a", {
            class: "dropdown-item",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.editLoggedUser && _ctx.editLoggedUser(...args)))
          }, [
            _createVNode(_component_profile_outlined, { class: "user-menu-item-icon" }),
            _cache[7] || (_cache[7] = _createTextVNode(" Impostazioni profilo"))
          ]),
          _createElementVNode("a", {
            class: "dropdown-item",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.doLogout && _ctx.doLogout(...args)))
          }, [
            _createVNode(_component_logout_outlined, { class: "user-menu-item-icon" }),
            _cache[8] || (_cache[8] = _createTextVNode(" Logout"))
          ])
        ], 512), [
          [_vShow, _ctx.appState.loggedUserMenuActive]
        ])
      ])
    ])
  ]))
}