import { PageNavigator } from "./app-navigation/domain/PageNavigator";
import { AppPLOC } from "./app-navigation/presentation/AppPLOC";
import Calendar from "./calendar/domain/Calendar";
import CalendarOptions from "./calendar/domain/CalendarOptions";
import EventsRepository from "./calendar/domain/EventsRepository";
import { CalendarPLOC } from "./calendar/presentation/CalendarPLOC";
import { HTTPClient } from "./common/communication/HTTPClient";
import { LoginPLOC } from "./login/presentation/LoginPLOC";
import NotificationsPLOC from "./notifications/presentation/NotificationsPLOC";
import PerformancesRepository from "./performances/domain/PerformancesRepository";
import PerformancesPLOC from "./performances/presentation/PerformancesPLOC";
import PivotData from "./pivot/domain/PivotData";
import PivotPLOC from "./pivot/presentation/PivotPLOC";
import UsersRepository from "./users/domain/UsersRepository";
import UsersPLOC from "./users/presentation/UsersPLOC";

export abstract class Factory {
  public abstract createAppPLOC(httpClient: HTTPClient): AppPLOC;
  public abstract createHTTPClient(): HTTPClient;
  public abstract createLoginPLOC(httpClient: HTTPClient): LoginPLOC;
  public abstract createPageNavigator(pageId: string, loggedIn: boolean): PageNavigator;
  public abstract createCalendarPLOC(
    calendarOptions: CalendarOptions,
    eventsRepository: EventsRepository,
    usersRepository: UsersRepository
  ): CalendarPLOC;
  public abstract createCalendarOptions(
    usersRepository: UsersRepository,
    performancesRepository: PerformancesRepository,
    eventsRepository: EventsRepository
  ): CalendarOptions;
  public abstract createPerformancesRepostory(httpClient: HTTPClient): PerformancesRepository;
  public abstract createUsersRepository(httpClient: HTTPClient): UsersRepository;
  public abstract createCalendar(mountPoint: HTMLElement, calendarOptions: object): Calendar | Error;
  public abstract createEventsRepository(httpClient: HTTPClient): EventsRepository;
  public abstract createUsersPLOC(usersRepository: UsersRepository): UsersPLOC;
  public abstract createPerformancesPLOC(performancesRepository: PerformancesRepository): PerformancesPLOC;
  public abstract createPivotData(httpClient: HTTPClient): PivotData;
  public abstract createPivotPLOC(
    performancesPLOC: PerformancesPLOC,
    indexer: PivotData,
    usersPLOC: UsersPLOC
  ): PivotPLOC;
  public abstract createNotificationsPLOC(): NotificationsPLOC;
}
