export default class DateUtils {
  // Number of milliseconds per day =
  //   24 hrs/day * 60 minutes/hour * 60 seconds/minute * 1000 ms/second
  private static MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

  public static diffDays(arg1: Date, arg2: Date): number {
    if (arg1 && arg2) {
      const milliSecondsDiff = Math.abs(arg1.getTime() - arg2.getTime());
      // Math.round is used instead of Math.floor to account for certain DST cases
      return Math.round(milliSecondsDiff / DateUtils.MILLISECONDS_PER_DAY);
    }
    return 0;
  }

  public static diffMinutes(arg1: Date, arg2: Date) {
    const milliSecondsDiff = Math.abs(arg1.getTime() - arg2.getTime());
    const diffMins = Math.round(((milliSecondsDiff % 86400000) % 3600000) / 60000); // minutes
    return diffMins;
  }

  public static dateRangesAreEquals(arg1: Array<Date>, arg2: Array<Date>): boolean {
    let result = arg1 && arg2 && arg1.length === arg2.length;
    if (result)
      arg1.forEach((arg1Date: Date, i: number) => {
        result = result && arg1Date && arg2[i] && arg1Date.getTime() === arg2[i].getTime();
      });
    return result;
  }

  public static addDays(arg1: Date, arg2: number): Date {
    const arg1Millis = arg1.getTime();
    const sumMillis = arg1Millis + DateUtils.MILLISECONDS_PER_DAY * arg2;
    return new Date(sumMillis);
  }
}
