import PerformancesRepository from "../PerformancesRepository";

export default class FormatPerformanceUseCase {
  public static doFormat(performancesRepository: PerformancesRepository, performanceId: string) {
    const performance = performancesRepository.getPerformanceById(performanceId);
    if (performance) {
      if (performance.deleted) return performance.name + " (eliminata)";
      else return performance.name;
    } else return "prestazione " + performanceId + " non trovata";
  }
}
