import { PageNavigator } from "../PageNavigator";
import { FactoryNavigationGateway } from "../FactoryNavigationGateway";
import { CURRENT_PAGE_KEY, WebEnvGateway } from "../WebEnvGateway";
import { LOGIN_PAGE_ID } from "../PagesConsts";

export class RetrieveCurrentPageUseCase {
  public static retrieveCurrentPage(
    webEnvGW: WebEnvGateway,
    factory: FactoryNavigationGateway,
    loggedIn: boolean
  ): string {
    const lMemCurrPageId = <string>webEnvGW.localStorage.getItem(CURRENT_PAGE_KEY);
    const pageNavigator = <PageNavigator>factory.createPageNavigator(lMemCurrPageId, loggedIn);
    const lCurrentPageId = <string>pageNavigator.getPageId();
    if (lCurrentPageId) webEnvGW.location.hash = lCurrentPageId;
    else webEnvGW.location.hash = LOGIN_PAGE_ID;
    return lCurrentPageId;
  }
}
