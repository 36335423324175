import { PageNavigator } from "./PageNavigator";
import { LOGIN_PAGE_ID } from "./PagesConsts";
import { pagesMap } from "./PagesMap";

export class PageNavigatorImpl extends PageNavigator {
  private pageId: string;
  private loggedIn: boolean;

  constructor(pageId: string, loggedIn = false) {
    super();
    this.pageId = "";
    this.loggedIn = loggedIn;
    this.setPageId(pageId);
  }
  public setPageId(pageId: string) {
    if (!pageId || !this.loggedIn) this.pageId = LOGIN_PAGE_ID;
    else this.pageId = pageId;
  }
  public getPageId(): string {
    return this.pageId;
  }
  public changePage(newCurrentPageId: string): boolean {
    const isToChange = newCurrentPageId != this.pageId && Object.keys(pagesMap).includes(newCurrentPageId);

    if (isToChange) this.setPageId(newCurrentPageId);

    return isToChange;
  }
}
