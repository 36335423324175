import { HTTPClient, HTTPMethod, HTTPResponse } from "./HTTPClient";
import axios, { AxiosError, AxiosResponse } from "axios";

export class AxiosHTTPClient extends HTTPClient {
  private fromAxiosErrToHttpResponse(error: AxiosError): HTTPResponse {
    if (error.response) {
      return {
        success: false,
        errorMsg: error.response.status + " " + error.response.statusText,
        data: {},
      };
    } else {
      return {
        success: false,
        errorMsg: error.message,
        data: {},
      };
    }
  }

  public async send(method: HTTPMethod, url: string, params: object): Promise<HTTPResponse> {
    let httpResponse: HTTPResponse;
    try {
      let axiosResponse: AxiosResponse;
      switch (method) {
        case HTTPMethod.GET:
          axiosResponse = await axios.get(url, params);
          break;
        case HTTPMethod.POST:
          axiosResponse = await axios.post(url, params);
          break;
      }
      httpResponse = {
        success: true,
        errorMsg: "",
        data: axiosResponse.data,
      };
    } catch (error) {
      httpResponse = this.fromAxiosErrToHttpResponse(<AxiosError>error);
    }
    return Promise.resolve(httpResponse);
  }
}
