type Subscription<S> = (state: S) => void;
export abstract class PLOC<S> {
  private internalState: S;
  private listeners: Subscription<S>[] = [];

  constructor(aInitalState: S) {
    this.internalState = aInitalState;
  }

  public get state(): S {
    return this.internalState;
  }

  changeState(aState: S) {
    this.internalState = aState;
    if (this.listeners.length > 0) {
      this.listeners.forEach((listener) => listener(this.state));
    }
  }

  subscribe(aListener: Subscription<S>) {
    this.listeners.push(aListener);
  }

  unsubscribe(aListener: Subscription<S>) {
    const index = this.listeners.indexOf(aListener);
    if (index > -1) {
      this.listeners.splice(index, 1);
    }
  }
}
