import { PLOC } from "../../common/presentation/PLOC";
import Calendar from "../domain/Calendar";
import CalendarOptions, { AvailableViews, IEditEventInfo, ISelectInfo } from "../domain/CalendarOptions";
import ChangeViewUseCase from "../domain/usecases/ChangeViewUseCase";
import GoToDateRangeUseCase from "../domain/usecases/GoToDateRangeUseCase";
import GoToDayUseCase from "../domain/usecases/GoToDayUseCase";
import MoveCalendarByDirectionUseCase from "../domain/usecases/MoveCalendarByDirectionUseCase";
import { calendarInitialState, CalendarState } from "./CalendarState";
import NewEventUseCase from "../domain/usecases/NewEventUseCase";
import CalendarEvent from "../domain/CalendarEvent";
import EventValidationUseCase from "../domain/usecases/EventValidationUseCase";
import EventsRepository from "../domain/EventsRepository";
import UsersRepository from "@/core/users/domain/UsersRepository";
import { EventAction } from "../domain/CalendarEventDS";
import EditEventUseCase from "../domain/usecases/EditEventUseCase";
import FiltersOperationsUseCase from "../domain/usecases/FiltersOperationsUseCase";

export class CalendarPLOC extends PLOC<CalendarState> {
  private calendarOptions: CalendarOptions;
  private calendar: Calendar;
  private eventsRepository: EventsRepository;
  private usersRepository: UsersRepository;
  private filtersOperations: FiltersOperationsUseCase;

  constructor(calendarOptions: CalendarOptions, eventsRepository: EventsRepository, usersRepository: UsersRepository) {
    super({
      ...calendarInitialState,
      calendarOptions: {},
      currentView: calendarOptions.getInitialView(),
    });
    this.calendarOptions = calendarOptions;
    this.calendar = {} as Calendar;
    this.eventsRepository = eventsRepository;
    this.usersRepository = usersRepository;
    this.filtersOperations = new FiltersOperationsUseCase(usersRepository, eventsRepository);
    CalendarEvent.getInstance().setMinExtremesRangeInMinutes(
      parseInt(this.calendarOptions.getSlotDuration().split(":")[1])
    );
  }

  private async onEventPreLoading() {
    this.changeState({ ...this.state, loading: true });
    await this.calendar.removeAllEvents();
  }

  private onEventPostLoading() {
    this.changeState({ ...this.state, loading: false });
  }

  public setCalendar(calendar: Calendar): void {
    this.calendar = calendar;
    if (this.calendarOptions) {
      this.calendarOptions.setEventPreLoadingCallback(this.onEventPreLoading.bind(this));
      this.calendarOptions.setEventPostLoadingCallback(this.onEventPostLoading.bind(this));
    }
  }

  public async loadCalendarOptions() {
    const optionsObj = {
      editable: this.calendarOptions.getEditable(),
      events: this.calendarOptions.eventsLoadingFn.bind(this.calendarOptions),
      firstDay: this.calendarOptions.getFirstDayOfWeek(),
      eventDidMount: this.calendarOptions.getEventMountFunction(),
      plugins: this.calendarOptions.getEnabledPlugins(),
      expandRows: false,
      initialView: this.calendarOptions.getInitialView(),
      allDayText: this.calendarOptions.getAllDayText(),
      headerToolbar: this.calendarOptions.getHeaderToolbar(),
      selectable: this.calendarOptions.getSelectable(),
      locale: this.calendarOptions.getLocale(),
      slotDuration: this.calendarOptions.getSlotDuration(),
      slotMinTime: this.calendarOptions.getSlotMinTime(),
      slotMaxTime: this.calendarOptions.getSlotMaxTime(),
      buttonText: { today: this.calendarOptions.getTodayButtonText() },
      dayHeaderContent: this.calendarOptions.getDayHeaderContentFunction(),
      navLinks: this.calendarOptions.getNavLinksEnabled(),
      navLinkDayClick: this.goToDay.bind(this),
      slotLabelFormat: this.calendarOptions.getSlotLabelFormat(),
      nowIndicator: this.calendarOptions.getNowIndicatorEnabled(),
      views: this.calendarOptions.getAvailableViews(),
      customButtons: this.calendarOptions.getCustomButtons(
        this.moveCalendarByDirection.bind(this),
        this.goToDay.bind(this)
      ),
      eventContent: this.calendarOptions.getEventContentFunction(),
      selectAllow: this.calendarOptions.applyDateSelectionRule,
      eventAllow: this.calendarOptions.applyDateSelectionRule,
      select: (selectionInfo: ISelectInfo) => {
        const newEvent = this.calendarOptions.onNewEvent(selectionInfo);
        this.changeState({
          ...this.state,
          event: newEvent,
          eventModalVisible: true,
        });
      },
      eventDrop: this.calendarOptions.getEventDropFunction(),
      eventMouseEnter: this.calendarOptions.getEventMouseEnterFunction(),
      eventMouseLeave: this.calendarOptions.getEventMouseLeaveFunction(),
      eventClick: (editEventInfo: IEditEventInfo) => {
        const eventToEdit = this.calendarOptions.onEditEvent(editEventInfo);
        this.changeState({
          ...this.state,
          event: eventToEdit,
          eventModalVisible: true,
        });
      },
    };

    this.changeState({
      ...this.state,
      calendarOptions: optionsObj,
      currentView: this.calendarOptions.getInitialView(),
    });

    return true;
  }

  private setFiltersLoading(value: boolean) {
    this.changeState({ ...this.state, filters: { ...this.state.filters, loading: value } });
  }

  public async setupCalendarFilters() {
    this.setFiltersLoading(true);
    await this.usersRepository.loadUsers();
    this.setFiltersLoading(false);

    this.changeState({
      ...this.state,
      filters: {
        ...this.state.filters,
        allUsersCount: this.usersRepository.getAllUsersIds().length,
        redrawList: Date.now(),
      },
    });
  }

  public loadSelectableViews() {
    const viewsObj = this.calendarOptions.getAvailableViews();
    this.changeState({
      ...this.state,
      selectableViews: Object.keys(viewsObj),
    });
  }

  public closeEventModal() {
    this.changeState({ ...this.state, eventModalVisible: false });
  }

  public getAvailableViewsObj(): AvailableViews {
    return this.calendarOptions.getAvailableViews();
  }

  public changeCalendarView(newViewId: string) {
    this.changeState({
      ...this.state,
      currentView: newViewId,
    });
    if (this.calendar) {
      const newDateRange = ChangeViewUseCase.changeView(this.state.currentView, this.calendar);
      this.changeState({
        ...this.state,
        dateRange: newDateRange,
        currentView: newViewId,
      });
    }
  }

  public goToDay(day: Date) {
    GoToDayUseCase.goToDay(day, this.calendar);
    this.changeState({
      ...this.state,
      dateRange: this.calendar.getDateRange(),
      currentView: this.calendar.getCurrentViewId(),
    });
  }

  public moveCalendarByDirection(direction: number) {
    MoveCalendarByDirectionUseCase.move(direction, this.calendar);
    this.changeState({
      ...this.state,
      dateRange: this.calendar.getDateRange(),
    });
  }

  public goToDateRange(dateRange: Array<Date>) {
    const newView: string = GoToDateRangeUseCase.goToDateRange(dateRange, this.calendar);
    this.changeState({
      ...this.state,
      currentView: newView,
    });
  }

  private stringToTime(timeStr: string) {
    const splitted = timeStr.split(":");
    return { hours: parseInt(splitted[0]), minutes: parseInt(splitted[1]) };
  }

  public getMinSelectableTime() {
    return this.stringToTime(this.calendarOptions.getSlotMinTime());
  }
  public getMaxSelectableTime() {
    return this.stringToTime(this.calendarOptions.getSlotMaxTime());
  }

  public validateEventExtremes() {
    this.changeState({
      ...this.state,
      event: EventValidationUseCase.validateEventExtremes(this.state.event),
    });
  }

  public validateEventCustomer() {
    this.changeState({
      ...this.state,
      event: EventValidationUseCase.validateEventCustomer(this.state.event),
    });
  }
  public validateEventPerformance() {
    this.changeState({
      ...this.state,
      event: EventValidationUseCase.validateEventPerformance(this.state.event),
    });
  }
  public validateEventEmployees() {
    this.changeState({
      ...this.state,
      event: EventValidationUseCase.validateEventEmployees(this.state.event),
    });
  }
  public validateEventCost() {
    this.changeState({
      ...this.state,
      event: EventValidationUseCase.validateEventCost(this.state.event),
    });
  }

  public async saveEvent() {
    let saveResult = null;

    if (this.state.event.isNew) {
      saveResult = await NewEventUseCase.tearDown(
        EventAction.Save,
        this.calendar,
        this.eventsRepository,
        this.usersRepository,
        this.state.event
      );
    } else {
      saveResult = await EditEventUseCase.tearDown(
        EventAction.Save,
        this.calendar,
        this.eventsRepository,
        this.usersRepository,
        this.state.event
      );
    }

    this.changeState({
      ...this.state,
      eventModalVisible: !saveResult.success,
      event: saveResult.event,
    });
  }

  public tryDeleteEvent() {
    if (this.state.event.isNew) this.closeEventModal();
    else this.showDeleteEventConfirm();
  }

  public async deleteEvent() {
    if (!this.state.event.isNew) {
      await EditEventUseCase.tearDown(
        EventAction.Delete,
        this.calendar,
        this.eventsRepository,
        this.usersRepository,
        this.state.event
      );
      this.closeEventModal();
    }
  }

  public showDeleteEventConfirm() {
    this.changeState({ ...this.state, deleteEventConfirmVisible: true });
  }

  public async closeDeleteEventConfirm(success: boolean) {
    this.changeState({ ...this.state, deleteEventConfirmVisible: false });
    if (success) await this.deleteEvent();
  }
  public getUserByIndex(index: number) {
    return this.filtersOperations.getUserByIndex(index);
  }

  public toggleFilterUserSelection(userId: string) {
    const selectedUsersCount = this.filtersOperations.toggleUserSelection(userId);
    this.changeState({ ...this.state, filters: { ...this.state.filters, selectedUsersCount } });
  }

  public selectAllFilterUsers() {
    const selectedUsersCount = this.filtersOperations.selectAllUsers();
    this.changeState({
      ...this.state,
      filters: {
        ...this.state.filters,
        redrawList: Date.now(),
        selectedUsersCount,
      },
    });
  }

  public deselectAllFilterUsers() {
    const selectedUsersCount = this.filtersOperations.deselectAllUsers();
    this.changeState({
      ...this.state,
      filters: {
        ...this.state.filters,
        redrawList: Date.now(),
        selectedUsersCount,
      },
    });
  }

  public selectOneFilterUser(userId: string) {
    this.filtersOperations.selectOne(userId);
    this.changeState({
      ...this.state,
      filters: {
        ...this.state.filters,
        redrawList: Date.now(),
        selectedUsersCount: 1,
      },
    });
  }

  refetchEvents() {
    this.calendar.refetchEvents();
    this.changeState({ ...this.state, filters: { ...this.state.filters, isFilterActive: true } });
  }

  removeFilters() {
    const selectedUsersCount = this.filtersOperations.deselectAllUsers();
    this.refetchEvents();
    this.changeState({
      ...this.state,
      filters: { ...this.state.filters, isFilterActive: false, redrawList: Date.now(), selectedUsersCount },
    });
  }
}
