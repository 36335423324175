<template>
  <div id="app_calendar" class="section-content" ref="calendarElement">
    <div class="calendar-loader-modal section-content" v-if="calendarState.loading"><loader></loader></div>
  </div>

  <event-modal v-show="calendarState.eventModalVisible"></event-modal>
  <modal-confirm
    v-show="calendarState.deleteEventConfirmVisible"
    :title="'Sei sicuro di voler eliminare l\'evento ?'"
    :body="'L\'evento non sara piu disponibile.'"
    :show-btn-deny="true"
    :showBtnOk="true"
    :btnClickFn="onDeleteEventConfirmClose"
  ></modal-confirm>
</template>
<script lang="ts">
import { defineComponent, onMounted, inject, ref } from "vue";
import { manageSubscription } from "../../common/SubscriptionManager";
import { FACTORY_KEY, CALENDAR_PLOC_KEY, PERFORMANCES_PLOC_KEY } from "../../common/GUIConsts";
import { Factory } from "@/core/Factory";
import Calendar from "@/core/calendar/domain/Calendar";
import { CalendarPLOC } from "@/core/calendar/presentation/CalendarPLOC";
import EventModal from "./EventModal.vue";
import PerformancesPLOC from "@/core/performances/presentation/PerformancesPLOC";
import ModalConfirm from "../controls/ModalConfirm.vue";
import NotificationsManager from "@/core/notifications/domain/NotificationsManager";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";
import Loader from "../controls/Loader.vue";

export default defineComponent({
  components: { EventModal, ModalConfirm, Loader },
  setup() {
    const factory = inject(FACTORY_KEY) as Factory;

    const calendarPLOC = inject(CALENDAR_PLOC_KEY) as CalendarPLOC;
    const calendarState = manageSubscription(calendarPLOC);
    const performancesPLOC = inject(PERFORMANCES_PLOC_KEY) as PerformancesPLOC;

    const calendarElement = ref<HTMLElement>();
    let calendar: Calendar | Error;

    onMounted(async () => {
      if (calendarElement.value) {
        const notificationsManager = NotificationsManager.getInstance();
        const performancesLoading = await performancesPLOC.loadPerformances();

        if (performancesLoading.success)
          notificationsManager.pushNewNotification("Caricamento prestazioni completato", "", NotificationType.Ok);
        else {
          notificationsManager.pushNewNotification(
            "Caricamento prestazioni fallito",
            performancesLoading.errorMsg,
            NotificationType.Ko
          );
          console.error("Calendar.vue/onMounted/loadPeformances error, detail: " + performancesLoading.errorMsg);
        }

        const optsLoadingOk = await calendarPLOC.loadCalendarOptions();

        if (optsLoadingOk) {
          calendar = factory.createCalendar(calendarElement.value, calendarState.value.calendarOptions);

          if (calendar instanceof Error) console.error(calendar.message);
          else {
            calendar.render();

            calendarPLOC.setCalendar(calendar);

            //fixing calendar size problem
            setTimeout(function () {
              window.dispatchEvent(new Event("resize"));
            }, 2);
          }
        } else console.error("Calendar.vue/onMounted/loadCalendarOptions error");
      } else console.error("Cannot load calendar, calendarElement.value is undefined.");
    });

    const onDeleteEventConfirmClose = async (success: boolean) => {
      await calendarPLOC.closeDeleteEventConfirm(success);
    };
    return { calendarState, calendarElement, onDeleteEventConfirmClose };
  },
});
</script>

<style lang="scss">
@import "../../../../public/styles/variables.scss";
$view-select-height: 49px;

//mixin for day headers: used by timegrid and list views
@mixin day-headers {
  .day-header-weekday {
    color: $gray-600;
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    @media only screen and (min-width: 600px) {
      font-size: 11px;
    }
  }

  .day-header-number {
    font-weight: 700;
    font-size: 0.95em;
    font-family: Helvetica, Arial, sans-serif;
    line-height: 1;
    @media only screen and (min-width: 600px) {
      font-size: 25px;
    }
  }
}

//event tooltip styles: common to all views

#app_calendar {
  position: relative;
  width: 100%;
  background: $gray-100;

  .fc-event-time,
  .fc-event-title-container,
  .event-description {
    color: $color-primary;
  }

  .tippy-content {
    color: $color-primary;
    font-weight: 500;
    padding: 0px;
    width: 250px;

    .event-tooltip-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gray-700;
      padding: 10px;
      align-items: center;
      background: $gray-200;

      .tooltip-title {
        font-weight: 700;
      }

      .tooltip-icons {
        display: flex;
        justify-content: space-between;
        font-size: 1.3em;
        cursor: pointer;

        .la-pencil-alt {
          margin-right: 10px;
        }
      }
    }

    .event-tooltip-body {
      padding: 10px;

      .user-avatar {
        cursor: pointer;
        &::after {
          display: none;
        }
      }
      .tooltip-first-field {
        margin-top: 0px;
      }
      .tooltip-field-header {
        font-weight: 700;
        margin-bottom: 5px;
        margin-top: 5px;
      }

      .tooltip-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .tooltip-cost {
          font-weight: 700;
        }
      }
      .tooltip-employee {
        .tooltip-name {
          color: $color-primary;
          cursor: pointer;
          &:hover {
            color: #5b47fb;
          }
        }
      }
      .tooltip-hours {
        font-size: 10x;
        margin-top: 10px;
      }

      .tooltip-customer {
        display: flex;
        flex-direction: row;

        .tooltip-customer-detail {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          justify-content: center;
          .tooltip-name {
            display: flex;
            color: $color-primary;
            font-weight: 700;
          }
          .tooltip-mail,
          .tooltip-no {
            font-size: 11px;
            color: $gray-500;
          }
        }
      }
    }
    .user-avatar {
      position: relative;
      width: 36px;
      height: 36px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      background-color: $gray-700;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 3px;
        width: 6px;
        height: 6px;
        background-color: $gray-500;
        box-shadow: 0 0 0 2px rgba(#fff, 0.95);
        border-radius: 100%;
      }
    }

    .avatar-md {
      width: 48px;
      height: 48px;
      font-size: 24px;

      &::after {
        width: 9px;
        height: 9px;
        right: 2px;
        bottom: 2px;
      }
    }
  }

  .fc-header-toolbar {
    margin: 0.6em 0 0.6em 0;
  }

  //month view styles
  .fc-dayGridMonth-view {
    .fc-event {
      border-top: none;
      overflow: hidden;
      font-size: 0.75em;

      .fc-daygrid-event-dot {
        width: 100%;
        @media only screen and (min-width: 550px) {
          width: 0px;
        }
      }
      .fc-event-title {
        font-weight: 500;
        display: none;
        @media only screen and (min-width: 550px) {
          display: block;
        }
      }
    }
    .event-description {
      display: none;
    }
    .day-header-number {
      display: none;
    }

    .fc-event-time {
      display: none;
    }

    @media only screen and (min-width: 992px) {
      .fc-event {
        font-weight: 500;
        .fc-event-title {
          font-weight: 600;
        }
        .fc-event-time {
          display: block;
        }
      }
    }
  }

  //list view styles
  .fc-listWeek-view {
    background: $gray-100;
    border-left: none;

    .fc-event {
      border-top: none;
      background: white;
      font-size: 0.85em;
      .fc-list-event-title,
      .fc-list-event-time {
        padding: 4px 7px;
      }
    }

    .fc-list-day {
      .fc-cell-shaded {
        background: $gray-200;
        padding: 4px 7px;
      }

      @include day-headers();

      .day-header-weekday {
        color: $color-primary;
      }
    }

    @media only screen and (min-width: 600px) {
      .fc-event {
        .fc-list-event-title,
        .fc-list-event-time {
          padding: 8px 14px;
        }
      }

      .fc-list-day {
        .fc-cell-shaded {
          padding: 8px 14px;
        }
      }
    }
  }

  //timegrid view styles (e.g. day, 2days, ..., Ndays)
  .fc-timegrid {
    .fc-event-title {
      font-size: 12px;
      font-weight: 700;
    }
    .fc-event-time {
      display: none;

      @media only screen and (min-width: 992px) {
        font-size: 10px;
        font-weight: 500;
        display: block;
      }
    }
    .event-description {
      display: none;
      font-size: 11px;
      font-weight: 500;
      @media only screen and (min-width: 750px) {
        display: block;
      }
    }
    .fc-timegrid-axis-cushion {
      text-align: center;
      font-weight: 500;
      font-size: 0.75em;
      @media only screen and (min-width: 992px) {
        font-size: 0.85em;
      }
    }
    .fc-timegrid-slot-label-cushion {
      font-weight: 500;
      font-size: 0.75em;
      @media only screen and (min-width: 992px) {
        font-size: 0.85em;
      }
    }
    .fc-timegrid-slot {
      height: 4vw;
      @media only screen and (min-width: 1300px) {
        height: 3vw;
      }
    }
    .fc-timegrid-slot-label-frame {
      text-align: center;
    }

    .fc-timegrid-now-indicator-line {
      border-color: $color-primary;
    }

    .fc-timegrid-now-indicator-arrow {
      border-left-color: $color-primary;
      border-right-color: $color-primary;
    }
    .fc-timegrid-cols {
      table {
        width: 100% !important;
      }
    }

    &.fc-agendaFourDays-view,
    &.fc-agendaFiveDays-view,
    &.fc-agendaSixDays-view,
    &.fc-agendaThreeDays-view,
    &.fc-timeGridWeek-view {
      .fc-event-main {
        display: none;
        @media only screen and (min-width: 992px) {
          display: block;
        }
      }
    }
  }

  //header toolbar styles: common to all views
  .fc-toolbar-chunk {
    div {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        background-color: #fff;
        background-image: none;
        border: 1px solid $gray-300;
        box-shadow: none;
        padding: 0 15px;
        display: flex;
        align-items: center;
        color: $gray-900;
        font-size: 13px;
        text-transform: capitalize;
        outline: none;
        text-shadow: none;
        border-radius: 0px;

        &:hover,
        &:active {
          background-color: $gray-200;
          color: $color-primary;
          border-color: $gray-300;
        }

        &.fc-prevCustomButton-button,
        &.fc-nextCustomButton-button {
          padding: 0;
          height: 25px;
          width: 25px;
          justify-content: center;

          span {
            margin: 0;
          }

          @media only screen and (min-width: 992px) {
            width: $input-height-base;
            height: $input-height-base;
          }
        }

        &.fc-todayCustomButton-button {
          font-size: 0.8rem;
          height: 25px;
          margin-left: 5px;
          font-weight: 500;
          @media only screen and (min-width: 992px) {
            height: $input-height-base;
          }
        }

        &:disabled {
          border-color: $gray-300;
          background-color: $gray-100;
          color: $gray-500;
          cursor: default;
        }
      }

      .fc-toolbar-title {
        font-size: 0.9em;
        margin: 0vw 1.5vw 0vw 1.5vw;
        color: $color-primary;
        text-transform: capitalize;
        font-weight: 500;
        @media only screen and (min-width: 992px) {
          font-size: 1.1em;
        }
      }
    }
  }

  //styles of day headers cells: common to timegrid and month view
  .fc-col-header-cell {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 0;
    color: $color-primary;

    @include day-headers();
  }

  //event base styles: common to all view
  .fc-event {
    border-top: 2px solid;
    border-radius: 0px;
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding-left: 5px;
    font-weight: 500;
    cursor: pointer;
  }

  //calendar main container styles: common to all views
  .fc-view-harness {
    background-color: white;

    .fc-scrollgrid {
      border-left: none;
    }
  }

  //today styles: common to all views
  .fc-day-today {
    background-color: $today-color;
  }

  //column header styles: common to timegrid and month view
  .fc-col-header {
    width: 100% !important;
  }

  //main grid styles: common to timegrid and month views
  .fc-daygrid-body {
    width: 100% !important;
  }

  //common to timegrid and month
  .fc-scrollgrid-sync-table,
  .fc-timegrid-body {
    width: 100% !important;
  }
}

.calendar-loader-modal {
  position: absolute; /* Stay in place */
  z-index: 999999999; /* Sit on top */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .loader {
    opacity: 0.6;
  }
}
</style>
