import { IPivotOptions } from "@/core/pivot/domain/PivotOptionsDS";

export interface IUserCoreDS {
  id: string;
  name: string;
  surname: string;
  type: string;
  color: string;
  phone: string;
  mail: string;
  deleted: boolean;
  password: string;
}
export type UserDS = IUserCoreDS & {
  pivotOptions: IPivotOptions;
};

export type TUserWithValidation = IUserCoreDS & {
  visible: boolean;
  title: string;
  nameOk: boolean;
  mailOk: boolean;
  surnameOk: boolean;
  colorOk: boolean;
  isNew: boolean;
  saving: boolean;
  pwdOk: boolean;
};

export const EMPLOYEE_TYPE_ID = "E";
export const CUSTOMER_TYPE_ID = "C";

export const userTypesIds: Array<string> = [EMPLOYEE_TYPE_ID, CUSTOMER_TYPE_ID];
