<template>
  <div class="users-list-container section-content" v-show="!usersState.error">
    <virtual-searchable-list
      :full-list-count="usersState.activeUsersCount"
      :get-list-item-fn="getActiveUserByIndex"
      :get-list-item-text-fn="getListItemTextFn"
      :key="usersState.redrawList"
      :item-height="itemHeight.heightValue"
      @fiteredValuesLength="onSearchChange"
    >
      <template #customButtons>
        <div class="list-section-macro-part">
          <div class="fixed-part">
            <div class="macro-btns">
              <button class="list-macro-btn" @click="selectAllUsers">Seleziona tutto</button>
              <button class="list-macro-btn" @click="deselectAllUsers">Deseleziona tutto</button>
            </div>
            <div class="fixed-right-part">
              <div class="list-len-container">
                <span class="list-len">{{ usersState.visibleUsersCount }} </span>
                elementi
              </div>
              <span class="separator">|</span>
              <i class="plus-icon" @click="createNewUser"><plus-outlined></plus-outlined> </i>
            </div>
          </div>
          <div class="selection-part" v-show="usersState.selectedUsersCount > 0">
            <div>
              <span class="selection-number">{{ usersState.selectedUsersCount }} </span>
              <span>elementi selezionati</span>
            </div>
            <span
              ><button class="list-macro-btn delete-selected" @click="tryDeleteUser">
                <delete-outlined></delete-outlined></button
            ></span>
          </div>
        </div>
      </template>
      <template #searchedItem="{ item }">
        <div class="user-container">
          <div class="left-part">
            <label class="custom-checkbox" :for="'input_' + item.id">
              <input
                type="checkbox"
                :id="'input_' + item.id"
                v-model="item.selected"
                @click="toggleUserSelection(item.id)"
              />
              <span class="checkmark"></span>
            </label>
            <div class="user">
              <UserAvatar
                :userName="getUserName(item.id)"
                :userSurname="getUserSurname(item.id)"
                :color="'#6ad1d9'"
                :clickable="true"
                :show="true"
                :dimension="'md'"
                :show-status="false"
                @click="editUser(item.id)"
              ></UserAvatar>
              <span class="user-desc" @click="editUser(item.id)"> {{ item.description }}</span>
            </div>
          </div>
          <div class="user-actions">
            <span class="user-action" @click="editUser(item.id)"> <edit-outlined></edit-outlined></span>
            <span class="user-action" v-show="canDeleteUser(item.id)" @click="deleteUser(item.id)">
              <delete-outlined></delete-outlined
            ></span>
          </div>
        </div> </template
    ></virtual-searchable-list>
  </div>

  <div v-show="usersState.error" class="status-icon-container">
    <div class="error-icon"><exclamation-circle-outlined></exclamation-circle-outlined></div>
    <span>Errore durante il caricamento degli utenti, consultare i log per dettagli</span>
  </div>

  <user-detail v-show="usersState.userDetail.visible"></user-detail>

  <modal-confirm
    v-show="usersState.userDeleteConfirmVisible"
    :title="'Sei sicuro di voler procedere con l\'eliminazione?'"
    :body="'L\'operazione è irreversibile.'"
    :show-btn-deny="true"
    :showBtnOk="true"
    :btnClickFn="onDeleteUserConfirmClose"
  ></modal-confirm>
</template>
<script lang="ts">
import { LOGIN_PLOC_KEY, USERS_PLOC_KEY } from "@/components/common/GUIConsts";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import UsersPLOC from "@/core/users/presentation/UsersPLOC";
import { defineComponent, inject, onMounted, ref } from "vue";
import VirtualSearchableList from "../controls/list/VirtualSearchableList.vue";
import UserAvatar from "../controls/UserAvatar.vue";
import { EditOutlined, DeleteOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import UserDetail from "./UserDetail.vue";
import ModalConfirm from "../controls/ModalConfirm.vue";
import { LoginPLOC } from "@/core/login/presentation/LoginPLOC";
import WindowResize from "@/core/common/utils/WindowResize";
export default defineComponent({
  components: {
    VirtualSearchableList,
    UserAvatar,
    EditOutlined,
    DeleteOutlined,
    UserDetail,
    PlusOutlined,
    ModalConfirm,
    ExclamationCircleOutlined,
  },

  setup() {
    const usersPLOC = inject(USERS_PLOC_KEY) as UsersPLOC;
    const usersState = manageSubscription(usersPLOC);
    const loginPLOC = inject(LOGIN_PLOC_KEY) as LoginPLOC;
    const loginState = manageSubscription(loginPLOC);
    const itemHeight = ref({ heightValue: 66 });

    const getActiveUserByIndex = (index: number) => {
      return usersPLOC.getActiveUserByIndex(index);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getListItemTextFn = (item: any) => {
      return item.description;
    };

    const getUserName = (id: string) => {
      return usersPLOC.getUserById(id).name;
    };

    const getUserSurname = (id: string) => {
      return usersPLOC.getUserById(id).surname;
    };

    const toggleUserSelection = (userId: string) => {
      usersPLOC.toggleUserSelection(userId);
    };

    onMounted(async () => {
      WindowResize.getInstance().addListener("userlist", onWindowResize.bind(this));
      onWindowResize(window.innerWidth);
      await usersPLOC.loadActiveUsers();
    });

    const onSearchChange = (usersLength: number) => {
      usersPLOC.visibleUsersChange(usersLength);
    };

    const createNewUser = () => {
      usersPLOC.createNewUser();
    };

    const editUser = (id: string) => {
      usersPLOC.editUser(id);
    };

    const onDeleteUserConfirmClose = async (confirm: boolean) => {
      if (confirm) await usersPLOC.deleteUsers();
      else usersPLOC.closeUserDeleteConfirm();
    };

    const canDeleteUser = (userId: string) => {
      return usersPLOC.canDeleteUser(userId, loginState.value.loggedUserId);
    };
    const tryDeleteUser = () => {
      usersPLOC.tryDeleteUser();
    };

    const selectAllUsers = () => {
      usersPLOC.selectAllUsers();
    };
    const deselectAllUsers = () => {
      usersPLOC.deselectAllUsers();
    };

    const deleteUser = (id: string) => {
      usersPLOC.setListUserClicked(id);
      tryDeleteUser();
    };

    const onWindowResize = (width: number) => {
      if (width <= 950) itemHeight.value.heightValue = 54;
      else itemHeight.value.heightValue = 66;
    };

    return {
      usersState,
      getActiveUserByIndex,
      getListItemTextFn,
      getUserName,
      getUserSurname,
      toggleUserSelection,
      onSearchChange,
      createNewUser,
      editUser,
      onDeleteUserConfirmClose,
      canDeleteUser,
      tryDeleteUser,
      selectAllUsers,
      deselectAllUsers,
      deleteUser,
      itemHeight,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

.users-list-container {
  .user-container {
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
    justify-content: space-between;

    .left-part {
      display: flex;
      align-items: center;
      padding-left: 10px;

      label {
        height: 25px;
        width: 25px;

        .checkmark {
          background: white;
          border: 1px solid $gray-400;
          &:hover {
            background: $gray-400;
          }
        }

        input {
          &:checked {
            ~ .checkmark {
              border: none;
            }
          }
        }
      }

      .user {
        display: flex;
        align-items: center;
        margin-left: 5px;

        .user-desc {
          cursor: pointer;
          margin-left: 10px;
          font-size: 16px;

          @media only screen and (max-width: 950px) {
            font-size: 12px;
          }
        }
      }
    }

    .user-actions {
      display: flex;
      justify-self: flex-end;
      font-size: 20px;
      margin-right: 15px;

      .user-action {
        cursor: pointer;
        color: $gray-500;

        &:first-child {
          margin-right: 5px;
        }

        &:hover {
          color: $gray-600;
        }
      }
    }

    &:hover {
      background: $gray-200;
    }
  }

  .list-item.even {
    /* Stili delle righe chiare */
    background-color: $gray-100;
  }
  /* Stili delle righe scure */
  .list-item.odd {
    background-color: white;
  }
}
</style>
