import { NotificationDS, NotificationType } from "../NotificationDS";
import NotificationsManager from "../NotificationsManager";

export default class CloseNotificationUseCase {
  public static onNotificationClose(
    id: string,
    notificationsManager: NotificationsManager,
    visibleNotifications: Array<NotificationDS>,
    maxVisibleNotifications: number
  ) {
    const result = {
      newVisibleNotifications: visibleNotifications,
      nextNotificationAdded: false,
      nextNotificationId: "",
      nextNotificationType: NotificationType.Ok,
    };
    const notificationExisted = notificationsManager.deleteNotification(id);
    //controllo che la notifica esiste: esempio vengo dalla scadenza del timer di chiusura ma l' utente l'ha gia chiusa
    if (notificationExisted) {
      //Eliminazione della notfica
      const position = visibleNotifications.findIndex((notification) => notification.id === id);
      if (position >= 0) {
        visibleNotifications.splice(position, 1);
        // this.changeState({ ...this.state, visibleNotifications: [...this.state.visibleNotifications] });
      }

      //Estrazione della prossima notifica da visualizzare(se c'e e se non c'e gia in quelle da visualizzare)
      const allNotifications = notificationsManager.getAllNotifications();
      const nextNotificationIdx = Math.min(maxVisibleNotifications, allNotifications.length) - 1;
      const nextNotification = allNotifications[nextNotificationIdx];
      if (nextNotificationIdx >= 0) {
        const notAlreadyVisible =
          visibleNotifications.findIndex((notification) => notification.id === nextNotification.id) < 0;
        if (notAlreadyVisible) {
          result.nextNotificationAdded = true;
          result.nextNotificationId = nextNotification.id;
          result.nextNotificationType = nextNotification.type;
          result.newVisibleNotifications = [nextNotification, ...visibleNotifications];
        }
      }
    }
    return result;
  }
}
