import { HTTPClient, HTTPMethod, HTTPResponse } from "@/core/common/communication/HTTPClient";
import PerformanceDS from "../domain/PerformanceDS";
import PerformancesRepository from "../domain/PerformancesRepository";
import { LoadPerformancesResponse, PerformancesOperarionsResponse } from "../domain/PerformancesOperationsResponse";
import {
  DELETE_PERFORMANCE_ERROR,
  LOADING_PERFORMANCES_ERROR,
  SAVE_PERFORMANCE_ERROR,
  UPDATE_PERFORMANCE_ERROR,
} from "@/core/common/utils/ResourceStrings";

interface APILoadPerformancesResponseSuccess {
  result: boolean;
  performances: Array<PerformanceDS>;
}

interface APILoadPerformancesResponseFailure {
  result: boolean;
  detail: string;
}

interface APIPerformancesOperationsResponse {
  result: boolean;
  detail: string;
}
type APILoadPerformancesResponse = APILoadPerformancesResponseSuccess | APILoadPerformancesResponseFailure;

export default class PerformancesRepositoryImpl extends PerformancesRepository {
  private httpClient: HTTPClient;
  private allPerformances: Map<string, PerformanceDS>;
  private performancesLoaded: boolean;
  private activePerformances: Array<string>;

  constructor(httpClient: HTTPClient) {
    super();
    this.httpClient = httpClient;
    this.performancesLoaded = false;
    this.allPerformances = new Map();
    this.activePerformances = new Array<string>();
  }

  public getPerformanceById(id: string): PerformanceDS {
    let result = {} as PerformanceDS;
    if (this.allPerformances.has(id)) result = this.allPerformances.get(id) as PerformanceDS;
    return result;
  }

  private doIndexPerformances(performances: Array<PerformanceDS>) {
    this.allPerformances = new Map();
    this.activePerformances = new Array<string>();
    performances.forEach((performance) => {
      this.allPerformances.set(performance.id, performance);
      if (!performance.deleted) this.activePerformances.push(performance.id);
    });
  }

  public async loadPerformances(): Promise<LoadPerformancesResponse> {
    const result = {
      performances: new Array<PerformanceDS>(),
      success: true,
      errorMsg: "",
    };
    if (!this.performancesLoaded) {
      this.performancesLoaded = false;
      const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/getAllPerformances", {});

      if (httpResponse.success && (<APILoadPerformancesResponse>httpResponse.data).result) {
        this.performancesLoaded = true;
        result.performances = (<APILoadPerformancesResponseSuccess>httpResponse.data).performances;
        this.doIndexPerformances(result.performances);
      } else {
        result.success = false;
        result.errorMsg = httpResponse.success
          ? `${LOADING_PERFORMANCES_ERROR}${(<APILoadPerformancesResponseFailure>httpResponse.data).detail}`
          : `${LOADING_PERFORMANCES_ERROR}${httpResponse.errorMsg}`;
        this.performancesLoaded = false;

        console.error("PerformancesRepositoryImpl/loadPerformances: " + result.errorMsg);
      }
    }

    return Promise.resolve(result);
  }

  public getAllPerformancesIds(): Array<string> {
    return Array.from(this.allPerformances.keys());
  }

  public getPefromancesMap(): Map<string, PerformanceDS> {
    return this.allPerformances;
  }

  public getActivePerformancesIds(): Array<string> {
    return this.activePerformances;
  }

  public async deletePerformances(ids: Array<string>): Promise<PerformancesOperarionsResponse> {
    const result = {
      success: true,
      errorMsg: "",
    };

    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/deletePerformances", {
      performancesIds: ids,
    });

    if (!httpResponse.success || !(<APIPerformancesOperationsResponse>httpResponse.data).result) {
      result.success = false;
      result.errorMsg = httpResponse.success
        ? `${DELETE_PERFORMANCE_ERROR}${(<APIPerformancesOperationsResponse>httpResponse.data).detail}`
        : `${DELETE_PERFORMANCE_ERROR}${httpResponse.errorMsg}`;
    } else {
      ids.forEach((deletedPerformanceId: string) => {
        const performance = this.allPerformances.get(deletedPerformanceId);
        if (performance) {
          performance.deleted = true;
          const toDeleteIndex = this.activePerformances.indexOf(performance.id);
          this.activePerformances.splice(toDeleteIndex, 1);
        }
      });
    }

    return Promise.resolve(result);
  }

  public async updatePerformance(performance: PerformanceDS): Promise<PerformancesOperarionsResponse> {
    const result = {
      success: true,
      errorMsg: "",
    };

    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/updatePerformance", {
      performance,
    });

    if (!httpResponse.success || !(<APIPerformancesOperationsResponse>httpResponse.data).result) {
      result.success = false;
      result.errorMsg = httpResponse.success
        ? `${UPDATE_PERFORMANCE_ERROR}${(<APIPerformancesOperationsResponse>httpResponse.data).detail}`
        : `${UPDATE_PERFORMANCE_ERROR}${httpResponse.errorMsg}`;
    } else this.allPerformances.set(performance.id, performance);

    return Promise.resolve(result);
  }
  public async savePerformance(performance: PerformanceDS): Promise<PerformancesOperarionsResponse> {
    const result = {
      success: true,
      errorMsg: "",
    };

    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/savePerformance", {
      performance,
    });

    if (!httpResponse.success || !(<APIPerformancesOperationsResponse>httpResponse.data).result) {
      result.success = false;
      result.errorMsg = httpResponse.success
        ? `${SAVE_PERFORMANCE_ERROR}${(<APIPerformancesOperationsResponse>httpResponse.data).detail}`
        : `${SAVE_PERFORMANCE_ERROR}${httpResponse.errorMsg}`;
    } else {
      this.allPerformances.set(performance.id, performance);
      this.activePerformances.push(performance.id);
    }

    return Promise.resolve(result);
  }
}
