import { IEntity, IFilter, IPivotOptions, defaultOptions } from "../domain/PivotOptionsDS";
import { IPivotFiltersEditing } from "../domain/usecases/PivotFiltersEditing";

export interface PivotState {
  loading: boolean;
  opts: IPivotOptions;
  modalVisible: boolean;
  optsWorkingCopy: IPivotOptions;
  modalId: string;
  availableDimensions: Array<IEntity>;
  availableMeasures: Array<IEntity>;
  entitiesOk: boolean;
  showPivotEntitiesMsg: boolean;
  filtersEditing: IPivotFiltersEditing;
  emptyData: boolean;
  error: boolean;
}

export const pivotInitialState = {
  loading: true,
  opts: defaultOptions,
  modalVisible: false,
  optsWorkingCopy: {} as IPivotOptions,
  modalId: "",
  availableDimensions: new Array<IEntity>(),
  availableMeasures: new Array<IEntity>(),
  entitiesOk: true,
  showPivotEntitiesMsg: false,
  filtersEditing: {
    editFilterMode: false,
    filterOnEdit: {} as IFilter,
    filterSubtitle: "",
    filterSaveErrorMsg: "",
  },
  emptyData: false,
  error: false,
};
