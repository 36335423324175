import PerformancesRepository from "@/core/performances/domain/PerformancesRepository";
import UsersRepository from "@/core/users/domain/UsersRepository";
import CalendarEventDS from "../domain/CalendarEventDS";
import tippy, { Instance, Props } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import FullCalendarEvent from "./FullCalendarEvent";
import FullCalendarController from "./FullCalendarController";

export default class EventTooltipRenderer {
  private static tipsDict: Map<string, Instance<Props>> = new Map();

  private static buildEmployeesNodeAsString(event: CalendarEventDS, usersRepository: UsersRepository): string {
    let result = "<div class='tooltip-employee'>con ";
    for (let i = 0; i < event.employees.length; i++) {
      let employeeEntry = "";
      const employee = usersRepository.getUserById(event.employees[i]);
      if (event.employees.length == 1) employeeEntry = employee.name + " " + employee.surname;
      else employeeEntry = employee.name;

      if (i > 0) employeeEntry = ", " + employeeEntry;
      result += "<span class=''>" + employeeEntry + "</span>";
    }
    result += "</div>";
    return result;
  }

  private static buildTipContent(
    event: CalendarEventDS,
    employeesNode: string,
    usersRepository: UsersRepository,
    performancesRepository: PerformancesRepository
  ): string {
    const customer = usersRepository.getUserById(event.customer);
    const performance = performancesRepository.getPerformanceById(event.performance);
    return (
      '<div><div class="event-tooltip-header"><span class="tooltip-title"> ' +
      performance.name +
      " </span>" +
      "</div>" +
      '<div class="event-tooltip-body">' +
      "<div class='tooltip-customer'><span class='user-avatar avatar-md'>" +
      customer.name[0] +
      customer.surname[0] +
      "</span><div class='tooltip-customer-detail'><span class='tooltip-name'>" +
      customer.name +
      " " +
      customer.surname +
      "</span><span class='tooltip-mail'>" +
      customer.mail +
      "</span><span class='tooltip-no'>" +
      customer.phone +
      "</span></div></div>" +
      "<div class='tooltip-hours'>" +
      event.start.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      }) +
      " - " +
      event.end.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      }) +
      "</div> <div class='tooltip-footer'>" +
      employeesNode +
      "<span class='tooltip-cost'>" +
      event.cost +
      "€</span></div></div></div>"
    );
  }

  public static renderEventTooltip(
    eventElement: HTMLElement,
    fcEvent: FullCalendarEvent,
    usersRepository: UsersRepository,
    performancesRepository: PerformancesRepository
  ): void {
    const appEvent = FullCalendarController.fullcalendarToAppEvent(fcEvent);
    const employeesNode = EventTooltipRenderer.buildEmployeesNodeAsString(appEvent, usersRepository);
    const tipContent = EventTooltipRenderer.buildTipContent(
      appEvent,
      employeesNode,
      usersRepository,
      performancesRepository
    );
    const parent = document.getElementById("app_calendar");
    EventTooltipRenderer.tipsDict.set(
      fcEvent.id,
      tippy(eventElement, {
        zIndex: 3,
        theme: "light-border",
        interactive: true,
        allowHTML: true,
        content: tipContent,
        appendTo: parent || "parent",
        trigger: "manual",
      })
    );
  }

  public static showTooltip(
    eventElement: HTMLElement,
    fcEvent: FullCalendarEvent,
    usersRepository: UsersRepository,
    performancesRepository: PerformancesRepository
  ) {
    if (EventTooltipRenderer.tipsDict.has(fcEvent.id)) {
      EventTooltipRenderer.tipsDict.get(fcEvent.id)?.destroy();
      EventTooltipRenderer.tipsDict.delete(fcEvent.id);
    }
    EventTooltipRenderer.renderEventTooltip(eventElement, fcEvent, usersRepository, performancesRepository);
    EventTooltipRenderer.tipsDict.get(fcEvent.id)?.show();
  }

  public static hideTooltip(id: string) {
    EventTooltipRenderer.tipsDict.get(id)?.hide();
  }
}
