import { State } from "@/core/common/presentation/State";
import { TCalendarEventWithValidation } from "../domain/CalendarEventDS";

export interface CalendarState extends State {
  calendarOptions: object;
  selectableViews: Array<string>;
  currentView: string;
  dateRange: Array<Date>;
  eventModalVisible: boolean;
  event: TCalendarEventWithValidation;
  deleteEventConfirmVisible: boolean;
  loading: boolean;
  filters: {
    allUsersCount: number;
    selectedUsersCount: number;
    redrawList: number;
    isFilterActive: boolean;
    loading: boolean;
  };
}

export const calendarInitialState: CalendarState = {
  calendarOptions: {},
  selectableViews: [],
  currentView: "",
  dateRange: [new Date(), new Date()],
  eventModalVisible: false,
  event: {} as TCalendarEventWithValidation,
  deleteEventConfirmVisible: false,
  loading: false,
  filters: {
    allUsersCount: 0,
    selectedUsersCount: 0,
    redrawList: Date.now(),
    isFilterActive: false,
    loading: false,
  },
};
