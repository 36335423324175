import { PageNavigator } from "./core/app-navigation/domain/PageNavigator";
import { PageNavigatorImpl } from "./core/app-navigation/domain/PageNavigatorImpl";
import { AxiosHTTPClient } from "./core/common/communication/AxiosHTTPClient";
import { HTTPClient } from "./core/common/communication/HTTPClient";
import { Factory } from "./core/Factory";
import { LoginImpl } from "./core/login/server/LoginImpl";
import { LoginPLOC } from "./core/login/presentation/LoginPLOC";
import SessionHeartBeatImpl from "./core/app-navigation/server/SessionHeartBeatImpl";
import { AppPLOC } from "./core/app-navigation/presentation/AppPLOC";
import { WebEnvGateway } from "./core/app-navigation/domain/WebEnvGateway";
import { CalendarPLOC } from "./core/calendar/presentation/CalendarPLOC";
import CalendarOptionsImpl from "./core/calendar/fullcalendar/CalendarOptionsImpl";
import PerformancesRepository from "./core/performances/domain/PerformancesRepository";
import UsersRepository from "./core/users/domain/UsersRepository";
import UsersRepositoryImpl from "./core/users/data/UsersRepositoryImpl";
import PerformancesRepostoryImpl from "./core/performances/data/PerformancesRepositoryImpl";
import CalendarOptions from "./core/calendar/domain/CalendarOptions";
import { Calendar as FullCalendar } from "@fullcalendar/core";
import Calendar from "./core/calendar/domain/Calendar";
import FullCalendarAdapter from "./core/calendar/fullcalendar/FullCalendarAdapter";
import EventsRepository from "./core/calendar/domain/EventsRepository";
import EventsRepositoryImpl from "./core/calendar/data/EventsRepositoryImpl";
import UsersPLOC from "./core/users/presentation/UsersPLOC";
import PerformancesPLOC from "./core/performances/presentation/PerformancesPLOC";
import PivotPLOC from "./core/pivot/presentation/PivotPLOC";
import PivotData from "./core/pivot/domain/PivotData";
import PivotDataImpl from "./core/pivot/data/PivotDataImpl";
import NotificationsPLOC from "./core/notifications/presentation/NotificationsPLOC";
import NotificationsManager from "./core/notifications/domain/NotificationsManager";

export default class FactoryImpl extends Factory {
  public createAppPLOC(httpClient: HTTPClient): AppPLOC {
    const sessionHeartBeat = new SessionHeartBeatImpl(httpClient);
    return new AppPLOC(window as WebEnvGateway, this, sessionHeartBeat);
  }
  public createHTTPClient(): HTTPClient {
    return new AxiosHTTPClient();
  }
  public createLoginPLOC(httpClient: HTTPClient): LoginPLOC {
    const login = new LoginImpl(httpClient);
    return new LoginPLOC(login);
  }
  public createPageNavigator(pageId: string, loggedIn: boolean): PageNavigator {
    return new PageNavigatorImpl(pageId, loggedIn);
  }

  public createCalendarPLOC(
    calendarOptions: CalendarOptions,
    eventsRepository: EventsRepository,
    usersRepository: UsersRepository
  ): CalendarPLOC {
    return new CalendarPLOC(calendarOptions, eventsRepository, usersRepository);
  }

  public createPerformancesRepostory(httpClient: HTTPClient): PerformancesRepository {
    return new PerformancesRepostoryImpl(httpClient);
  }
  public createUsersRepository(httpClient: HTTPClient): UsersRepository {
    return new UsersRepositoryImpl(httpClient);
  }

  public createCalendarOptions(
    usersRepository: UsersRepository,
    performancesRepository: PerformancesRepository,
    eventsRepository: EventsRepository
  ): CalendarOptions {
    return new CalendarOptionsImpl(usersRepository, performancesRepository, eventsRepository);
  }

  public createCalendar(mountPoint: HTMLElement, calendarOptions: object): Calendar | Error {
    let fullCalendar: FullCalendar = {} as FullCalendar;
    try {
      fullCalendar = new FullCalendar(mountPoint, calendarOptions);
    } catch (e) {
      return new Error("FactoryImpl/createCalendar:Error attempting to create new Fullcalendar instance");
    }
    return new FullCalendarAdapter(fullCalendar);
  }

  public createEventsRepository(httpClient: HTTPClient): EventsRepository {
    return new EventsRepositoryImpl(httpClient);
  }

  public createUsersPLOC(usersRepository: UsersRepository): UsersPLOC {
    return new UsersPLOC(usersRepository);
  }
  public createPerformancesPLOC(performancesRepository: PerformancesRepository): PerformancesPLOC {
    return new PerformancesPLOC(performancesRepository);
  }

  public createPivotData(httpClient: HTTPClient): PivotData {
    return new PivotDataImpl(httpClient);
  }

  public createPivotPLOC(performancesPLOC: PerformancesPLOC, indexer: PivotData, usersPLOC: UsersPLOC): PivotPLOC {
    return new PivotPLOC(performancesPLOC, indexer, usersPLOC);
  }

  public createNotificationsPLOC(): NotificationsPLOC {
    const result = new NotificationsPLOC();
    NotificationsManager.setListener(result);
    return result;
  }
}
