import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-bar" }
const _hoisted_2 = { class: "icon-part" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clear_outlined = _resolveComponent("clear-outlined")!
  const _component_search_outlined = _resolveComponent("search-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      placeholder: "Cerca ...",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.textToSearch) = $event)),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onSearchBarChange && _ctx.onSearchBarChange(...args)), ["enter"]))
    }, null, 544), [
      [_vModelText, _ctx.state.textToSearch]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("span", {
        class: "close-icon",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onDiscardSearchClick && _ctx.onDiscardSearchClick(...args)))
      }, [
        _createVNode(_component_clear_outlined)
      ], 512), [
        [_vShow, _ctx.state.textToSearch !== '']
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "separator" }, "|", -1)),
      _createElementVNode("span", {
        class: "search-icon",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onSearchBarChange && _ctx.onSearchBarChange(...args)))
      }, [
        _createVNode(_component_search_outlined)
      ])
    ])
  ]))
}