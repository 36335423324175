<template>
  <div class="modal user-modal">
    <div class="modal-content">
      <div class="modal-close">
        <close-outlined @click="discardChanges"></close-outlined>
      </div>
      <div class="modal-header">
        <div class="title-icon"><user-outlined></user-outlined></div>
        <h3>{{ usersState.userDetail.title }}</h3>
      </div>
      <div class="modal-body">
        <div class="modal-field">
          <label class="modal-field-label" for="name">Nome: </label>
          <div class="modal-field-value">
            <input
              placeholder="nome"
              class="txt-input"
              type="text"
              id="name"
              name="name"
              v-model="usersState.userDetail.name"
              @input="validateName"
            />
            <div class="error" v-show="usersState.userDetail.saving && !usersState.userDetail.nameOk">
              Campo obbligatorio e al massimo di 20 caratteri
            </div>
          </div>
        </div>

        <div class="modal-field">
          <label class="modal-field-label" for="surname">Cognome: </label>
          <div class="modal-field-value">
            <input
              placeholder="cognome"
              class="txt-input"
              type="text"
              id="surname"
              name="surname"
              v-model="usersState.userDetail.surname"
              @input="validateSurname"
            />
            <div class="error" v-show="usersState.userDetail.saving && !usersState.userDetail.surnameOk">
              Campo obbligatorio e al massimo di 20 caratteri
            </div>
          </div>
        </div>
        <div class="modal-field">
          <label class="modal-field-label" for="mail">Mail: </label>
          <div class="modal-field-value">
            <input
              placeholder="Es. pippo@gmail.com"
              class="txt-input"
              type="text"
              id="mail"
              name="mail"
              v-model="usersState.userDetail.mail"
              @input="validateMail"
            />
            <div
              class="error"
              v-show="
                usersState.userDetail.saving &&
                usersState.userDetail.type === getEmployeeTypeId() &&
                !usersState.userDetail.mailOk
              "
            >
              Inserire un indirizzo mail valido
            </div>
          </div>
        </div>
        <div class="modal-field">
          <label class="modal-field-label" for="tel">Telefono: </label>
          <div class="modal-field-value">
            <input
              placeholder="Es. 3317199550"
              class="txt-input"
              type="tel"
              id="tel"
              name="tel"
              v-model="usersState.userDetail.phone"
            />
          </div>
        </div>
        <div class="modal-field">
          <label class="modal-field-label">Tipologia: </label>
          <Select
            :placeholder="'Seleziona un valore'"
            :showLabels="false"
            :width="350"
            :options="getUserTypesIds()"
            :selectedSingle="usersState.userDetail.type"
            :customLabelFn="getUserTypeLabel"
            :searchable="false"
            :selectKey="'userTypeKey'"
            :changeFn="changeUserType"
            :disabled="!usersState.userDetail.isNew"
          >
          </Select>
        </div>
        <div class="modal-field" v-show="usersState.userDetail.type === getCustomerTypeId()">
          <label class="modal-field-label" for="cust-color">Colore: </label>
          <input
            placeholder="Es. 10ml 7cc +20mlclear 6 30ml +7.4 15ml 20vol"
            class="txt-input"
            type="text"
            name="cust-color"
            v-model="usersState.userDetail.color"
          />
        </div>
        <div class="modal-field" v-show="usersState.userDetail.type === getEmployeeTypeId()">
          <label class="modal-field-label" for="emp-color">Colore: </label>
          <div class="modal-field-value">
            <div class="color-picker">
              <input
                type="color"
                id="emp-color"
                name="emp-color"
                @change="validateColor"
                v-model="usersState.userDetail.color"
              />
              <div class="color-code">
                <span>{{ usersState.userDetail.color }}</span>
              </div>
            </div>
            <div
              class="error"
              v-show="
                usersState.userDetail.saving &&
                usersState.userDetail.type === getEmployeeTypeId() &&
                !usersState.userDetail.colorOk
              "
            >
              Il colore è in uso da un altro utente
            </div>
          </div>
        </div>
        <div class="modal-field" v-show="usersState.userDetail.type === getEmployeeTypeId()">
          <label class="modal-field-label" for="pwd">Password: </label>
          <div class="modal-field-value">
            <input
              @focus="onPwdFocus"
              @input="validatePwd"
              placeholder=""
              class="txt-input"
              type="password"
              name="pwd"
              v-model="usersState.userDetail.password"
            />
            <div
              class="error"
              v-show="
                usersState.userDetail.saving &&
                usersState.userDetail.type === getEmployeeTypeId() &&
                !usersState.userDetail.pwdOk
              "
            >
              Password non valida, deve avere:
              <ul>
                <li>almeno 8 caratteri</li>
                <li>almeno un numero</li>
                <li>almeno una maiuscola</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline btn-outline-confirm" @click="saveUser">Salva</button>
        <button v-show="canDeleteUser()" class="btn btn-outline btn-outline-remove" @click="tryDeleteUser">
          Elimina
        </button>
        <button class="btn btn-outline btn-outline-discard" @click="discardChanges">Annulla</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { CloseOutlined, UserOutlined } from "@ant-design/icons-vue";
import { LOGIN_PLOC_KEY, USERS_PLOC_KEY } from "@/components/common/GUIConsts";
import UsersPLOC from "@/core/users/presentation/UsersPLOC";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import Select from "../controls/Select.vue";
import { CUSTOMER_TYPE_ID, EMPLOYEE_TYPE_ID, userTypesIds } from "@/core/users/domain/UserDS";
import { LoginPLOC } from "@/core/login/presentation/LoginPLOC";
export default defineComponent({
  components: { CloseOutlined, UserOutlined, Select },
  props: {},
  setup() {
    const usersPLOC = inject(USERS_PLOC_KEY) as UsersPLOC;
    const loginPLOC = inject(LOGIN_PLOC_KEY) as LoginPLOC;
    const usersState = manageSubscription(usersPLOC);
    const loginState = manageSubscription(loginPLOC);

    const getUserTypesIds = () => {
      return userTypesIds;
    };
    const getUserTypeLabel = (typeId: string) => {
      return typeId === CUSTOMER_TYPE_ID ? "Cliente" : "Impiegato";
    };
    const getCustomerTypeId = () => {
      return CUSTOMER_TYPE_ID;
    };
    const getEmployeeTypeId = () => {
      return EMPLOYEE_TYPE_ID;
    };

    const saveUser = async () => {
      await usersPLOC.saveUser();
    };

    const changeUserType = (newTypeId: string) => {
      usersPLOC.changeUserType(newTypeId);
    };

    const validateName = () => {
      usersPLOC.validateName();
    };
    const validateSurname = () => {
      usersPLOC.validateSurname();
    };
    const validateMail = () => {
      usersPLOC.validateMail();
    };
    const validatePwd = () => {
      usersPLOC.validatePwd();
    };
    const validateColor = () => {
      usersPLOC.validateColor();
    };

    const discardChanges = () => {
      usersPLOC.discardChanges();
    };

    const onPwdFocus = () => {
      usersPLOC.resetPwd();
    };

    const tryDeleteUser = () => {
      usersPLOC.tryDeleteUser();
    };

    const canDeleteUser = () => {
      return usersPLOC.canDeleteCurrentUser(loginState.value.loggedUserId);
    };

    return {
      usersState,
      getUserTypesIds,
      getUserTypeLabel,
      getCustomerTypeId,
      getEmployeeTypeId,
      changeUserType,
      saveUser,
      validateColor,
      validateName,
      validateSurname,
      validateMail,
      validatePwd,
      discardChanges,
      onPwdFocus,
      tryDeleteUser,
      canDeleteUser,
    };
  },
});
</script>
<style lang="scss">
@import "./public/styles/variables.scss";

.user-modal {
  .modal-body {
    align-items: center;
  }
  .modal-field {
    .txt-input {
      height: 30px;
      border: 1px solid $gray-300;
      border-radius: 3px;
      width: 350px;
      padding: 10px;
      &:focus {
        outline: 0;
      }
    }

    .color-picker {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 350px;
      #emp-color {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      #emp-color::-webkit-color-swatch {
        border-radius: 3px;
        border: none;
      }
      #emp-color::-moz-color-swatch {
        border-radius: 3px;
        border: none;
      }
      .color-code {
        width: 100%;
        border-bottom: 1px solid $gray-300;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
