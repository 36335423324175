<template>
  <VueDatePicker
    class="app-date-picker"
    v-model="state.date"
    :inline="false"
    :range="false"
    :autoApply="true"
    :enable-time-picker="false"
    :locale="'it-IT'"
    @internal-model-change="handleInternal"
    calendar-cell-class-name="dp-custom-cell"
  ></VueDatePicker>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default defineComponent({
  components: {
    VueDatePicker,
  },
  props: {
    initialValue: Date,
  },
  emits: ["change"],
  setup(props, { emit }) {
    const state = ref({ date: props.initialValue });

    const handleInternal = (newDate: Array<Date>) => {
      if (newDate) {
        emit("change", newDate);
      }
    };
    return { handleInternal, state };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

.dp__main.app-date-picker {
  justify-content: center;

  .dp__outer_menu_wrap {
    .dp__menu {
      border: 1px solid $gray-300;
      border-radius: 0px;
      padding-right: 5px;
      padding-left: 5px;
      .dp__inner_nav {
        color: $color-primary;
      }

      .dp__button {
        background: $gray-100;
        .dp__icon {
          display: none;
        }
        &:after {
          content: "Torna indietro";
          text-align: center;
          color: $color-primary;
        }
      }

      .dp__month_year_select,
      .dp__overlay_cell,
      .dp__overlay_cell_active {
        color: $color-primary;
        text-transform: capitalize;
      }
      .dp__overlay_cell_active {
        background: $gray-300;
      }

      .dp__calendar_header_item {
        color: $gray-600;
        font-weight: 400;
        text-transform: capitalize;
      }
      .dp__calendar_header_separator {
        color: $gray-600;
        margin-bottom: 10px;
      }

      .dp__date_hover_end,
      .dp__date_hover_start {
        background: white;
        border: white;
      }
      .dp__today {
        border: $selected-color 2px solid;
        border-radius: 20%;
      }
      .dp__range_start,
      .dp__range_end {
        background: $gray-300;
        color: $color-primary;
        font-weight: 500;
        border-radius: 20%;
      }

      .dp__calendar_row {
        margin: 10px 0px;
      }
      .dp__month_year_row {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
