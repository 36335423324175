import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "notifications-container" }
const _hoisted_2 = { class: "notification-header" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "notification-hour" }
const _hoisted_5 = { class: "notification-title" }
const _hoisted_6 = { class: "title-string" }
const _hoisted_7 = { class: "notification-icon" }
const _hoisted_8 = {
  key: 0,
  class: "type-ok"
}
const _hoisted_9 = {
  key: 1,
  class: "type-ko"
}
const _hoisted_10 = { class: "notification-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_check_circle_outlined = _resolveComponent("check-circle-outlined")!
  const _component_close_circle_outlined = _resolveComponent("close-circle-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationsState.visibleNotifications, (notification) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "notification-item",
        key: notification.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            class: "notification-close",
            onClick: ($event: any) => (_ctx.onNotificationClose(notification.id))
          }, [
            _createVNode(_component_close_outlined)
          ], 8, _hoisted_3),
          _createElementVNode("span", _hoisted_4, _toDisplayString(notification.time), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(notification.title), 1),
            _createElementVNode("span", _hoisted_7, [
              (_ctx.notificationIsOk(notification.type))
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                    _createVNode(_component_check_circle_outlined)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                    _createVNode(_component_close_circle_outlined)
                  ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_10, _toDisplayString(notification.body), 1)
        ])
      ]))
    }), 128))
  ]))
}