export enum NotificationType {
  Ok,
  Ko,
}
export interface NotificationDS {
  id: string;
  title: string;
  body: string;
  type: NotificationType;
  time: string;
}

export abstract class INotificationListener {
  public abstract onNewNotificationArrival(newNotification: NotificationDS): void;
}
