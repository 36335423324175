import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_ctx.visible)
    ? (_openBlock(), _createBlock(_component_VueDatePicker, {
        key: 0,
        class: "app-date-picker",
        modelValue: _ctx.state.dateRange,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.dateRange) = $event)),
        inline: true,
        range: true,
        autoApply: true,
        "enable-time-picker": false,
        locale: 'it-IT',
        onInternalModelChange: _ctx.handleInternal,
        "calendar-cell-class-name": "dp-custom-cell",
        onRangeStart: _ctx.onUserInput,
        onRangeEnd: _ctx.onUserInput
      }, null, 8, ["modelValue", "onInternalModelChange", "onRangeStart", "onRangeEnd"]))
    : _createCommentVNode("", true)
}