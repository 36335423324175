<template>
  <div class="notifications-container">
    <div
      class="notification-item"
      v-for="notification in notificationsState.visibleNotifications"
      :key="notification.id"
    >
      <div class="notification-header">
        <span class="notification-close" @click="onNotificationClose(notification.id)">
          <close-outlined></close-outlined>
        </span>
        <span class="notification-hour"> {{ notification.time }}</span>
      </div>
      <div>
        <div class="notification-title">
          <span class="title-string">{{ notification.title }}</span>
          <span class="notification-icon">
            <span class="type-ok" v-if="notificationIsOk(notification.type)"
              ><check-circle-outlined></check-circle-outlined
            ></span>
            <span class="type-ko" v-else><close-circle-outlined></close-circle-outlined></span
          ></span>
        </div>
        <div class="notification-body">{{ notification.body }}</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { NOTIFICAITONS_PLOC_KEY } from "@/components/common/GUIConsts";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";
import NotificationsPLOC from "@/core/notifications/presentation/NotificationsPLOC";
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { defineComponent, inject, onMounted } from "vue";

export default defineComponent({
  components: { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined },
  props: {},
  setup() {
    const notificationsPLOC = inject(NOTIFICAITONS_PLOC_KEY) as NotificationsPLOC;
    const notificationsState = manageSubscription(notificationsPLOC);

    const notificationIsOk = (type: NotificationType) => {
      return type === NotificationType.Ok;
    };

    const onNotificationClose = (id: string) => {
      notificationsPLOC.onNotificationClose(id);
    };

    onMounted(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (entries[0].contentRect.width > 0 && entries[0].contentRect.height > 0) {
            const totalHeight = Math.floor(entries[0].contentRect.height);
            notificationsState.value.maxVisibleNotifications = Math.floor(totalHeight / 60);
          }
        });
      });

      if (document.body) resizeObserver.observe(document.body);
    });
    return { notificationsState, notificationIsOk, onNotificationClose };
  },
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
@import "./public/styles/variables.scss";
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.notifications-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  bottom: 0;
  z-index: 5;
  margin-bottom: calc($iconbar-navlink-height-mobile + 2px);

  @media only screen and (min-width: 550px) {
    margin-bottom: 0px;
  }

  .notification-item {
    margin-right: 2px;
    padding: 5px;
    border: 1px solid $gray-300;
    border-radius: 4px;
    opacity: 0;
    animation: fadeIn 1s ease-in both;
    font-size: 0.7rem;
    background: white;

    @media only screen and (min-width: 550px) {
      font-size: 0.9rem;
    }

    .notification-body {
      margin-left: 14px;
    }
    &:not(:last-child) {
      border-bottom: none;
    }

    .notification-header {
      display: flex;
      justify-content: space-between;

      .notification-hour {
        font-size: 0.7rem;
      }
      .notification-close {
        margin-right: 5px;
        font-size: 10px;
        cursor: pointer;
        &:hover {
          color: $color-secondary;
        }
      }
    }

    .notification-title {
      margin-top: 5px;
      font-weight: 500;
      display: grid;
      margin-left: 15px;
      grid-template:
        "a b" auto
        / auto auto;

      .title-string {
        margin-right: 5px;
      }

      .notification-icon {
        display: flex;
        justify-content: flex-end;
        font-size: 0.9rem;

        @media only screen and (min-width: 550px) {
          font-size: 1.3rem;
        }
        .type-ok {
          color: green;
        }

        .type-ko {
          color: red;
        }
      }
    }
  }
}
</style>
