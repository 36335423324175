import { HTTPClient, HTTPMethod, HTTPResponse } from "@/core/common/communication/HTTPClient";
import { UserDS } from "../domain/UserDS";
import UsersRepository from "../domain/UsersRepository";
import {
  LoadUsersResponse,
  UsersOperarionsResponse as UsersOperationsResponse,
} from "../domain/UsersOperationsResponse";
import {
  DELETE_USER_ERROR,
  LOADING_USERS_ERROR,
  SAVE_USER_ERROR,
  UPDATE_USER_ERROR,
} from "@/core/common/utils/ResourceStrings";

interface APILoadUsersResponseSuccess {
  result: boolean;
  users: Array<UserDS>;
}

interface APILoadUsersResponseFailure {
  result: boolean;
  detail: string;
}

interface APIUsersOperationsResponse {
  result: boolean;
  detail: string;
}

type APILoadUsersResponse = APILoadUsersResponseSuccess | APILoadUsersResponseFailure;

export default class UsersRepositoryImpl extends UsersRepository {
  private httpClient: HTTPClient;
  private allUsers: Map<string, UserDS>;
  private usersLoaded: boolean;
  private activeUsers: Array<string>;

  constructor(httpClient: HTTPClient) {
    super();
    this.httpClient = httpClient;
    this.allUsers = new Map();
    this.activeUsers = new Array<string>();
    this.usersLoaded = false;
  }

  public getUserById(id: string): UserDS {
    let result = {} as UserDS;

    if (this.allUsers.has(id)) result = this.allUsers.get(id) as UserDS;

    return result;
  }

  private doIndexUsers(users: Array<UserDS>) {
    this.allUsers = new Map();
    this.activeUsers = new Array<string>();
    users.forEach((user) => {
      this.allUsers.set(user.id, user);
      if (!user.deleted) this.activeUsers.push(user.id);
    });
  }

  public async loadUsers(): Promise<LoadUsersResponse> {
    const result = {
      users: new Array<UserDS>(),
      success: true,
      errorMsg: "",
    };

    if (!this.usersLoaded) {
      const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/getAllUsers", {});

      if (httpResponse.success && (<APILoadUsersResponse>httpResponse.data).result) {
        result.users = (<APILoadUsersResponseSuccess>httpResponse.data).users;
        this.doIndexUsers(result.users);
        this.usersLoaded = true;
      } else {
        result.success = false;
        result.errorMsg = httpResponse.success
          ? `${LOADING_USERS_ERROR}${(<APILoadUsersResponseFailure>httpResponse.data).detail}`
          : `${LOADING_USERS_ERROR}${httpResponse.errorMsg}`;
        this.usersLoaded = false;
      }
    }

    return Promise.resolve(result);
  }

  public getAllUsersIds(): Array<string> {
    return Array.from(this.allUsers.keys());
  }

  public getUsersMap(): Map<string, UserDS> {
    return this.allUsers;
  }

  public async updateUser(user: UserDS): Promise<UsersOperationsResponse> {
    const result = {
      success: true,
      errorMsg: "",
    };

    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/updateUser", { user });

    if (!httpResponse.success || !(<APIUsersOperationsResponse>httpResponse.data).result) {
      result.success = false;
      result.errorMsg = httpResponse.success
        ? `${UPDATE_USER_ERROR}${(<APIUsersOperationsResponse>httpResponse.data).detail}`
        : `${UPDATE_USER_ERROR}${httpResponse.errorMsg}`;
    } else this.allUsers.set(user.id, user);

    return Promise.resolve(result);
  }

  public async saveUser(user: UserDS): Promise<UsersOperationsResponse> {
    const result = {
      success: true,
      errorMsg: "",
    };

    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/saveUser", { user });

    if (!httpResponse.success || !(<APIUsersOperationsResponse>httpResponse.data).result) {
      result.success = false;
      result.errorMsg = httpResponse.success
        ? `${SAVE_USER_ERROR}${(<APIUsersOperationsResponse>httpResponse.data).detail}`
        : `${SAVE_USER_ERROR}${httpResponse.errorMsg}`;
    } else {
      this.allUsers.set(user.id, user);
      this.activeUsers.push(user.id);
    }

    return Promise.resolve(result);
  }

  public async deleteUsers(ids: Array<string>): Promise<UsersOperationsResponse> {
    const result = {
      success: true,
      errorMsg: "",
    };

    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/deleteUsers", {
      usersIds: ids,
    });

    if (!httpResponse.success || !(<APIUsersOperationsResponse>httpResponse.data).result) {
      result.success = false;
      result.errorMsg = httpResponse.success
        ? `${DELETE_USER_ERROR}${(<APIUsersOperationsResponse>httpResponse.data).detail}`
        : `${DELETE_USER_ERROR}${httpResponse.errorMsg}`;
    } else {
      ids.forEach((deletedUserId: string) => {
        const user = this.allUsers.get(deletedUserId);
        if (user) {
          user.deleted = true;
          const toDeleteIndex = this.activeUsers.indexOf(user.id);
          this.activeUsers.splice(toDeleteIndex, 1);
        }
      });
    }

    return Promise.resolve(result);
  }

  public getActiveUsersIds(): Array<string> {
    return this.activeUsers;
  }
}
