import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal performance-modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-close" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "title-icon" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "modal-field" }
const _hoisted_8 = { class: "modal-field-value" }
const _hoisted_9 = { class: "error" }
const _hoisted_10 = { class: "modal-field" }
const _hoisted_11 = { class: "modal-field-value" }
const _hoisted_12 = { class: "error" }
const _hoisted_13 = { class: "modal-field" }
const _hoisted_14 = { class: "modal-field-value" }
const _hoisted_15 = { class: "error" }
const _hoisted_16 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_scissor_outlined = _resolveComponent("scissor-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_close_outlined, { onClick: _ctx.discardChanges }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_scissor_outlined)
        ]),
        _createElementVNode("h3", null, _toDisplayString(_ctx.performancesState.performanceDetail.title), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[9] || (_cache[9] = _createElementVNode("label", {
            class: "modal-field-label",
            for: "namperformance_name"
          }, "Nome: ", -1)),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              placeholder: "nome",
              class: "txt-input",
              type: "text",
              id: "performance_name",
              name: "performance_name",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.performancesState.performanceDetail.name) = $event)),
              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateName && _ctx.validateName(...args)))
            }, null, 544), [
              [_vModelText, _ctx.performancesState.performanceDetail.name]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_9, " Campo obbligatorio e al massimo di 20 caratteri ", 512), [
              [_vShow, _ctx.performancesState.performanceDetail.saving && !_ctx.performancesState.performanceDetail.nameOk]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[10] || (_cache[10] = _createElementVNode("label", {
            class: "modal-field-label",
            for: "duration"
          }, "Durata: ", -1)),
          _createElementVNode("div", _hoisted_11, [
            _withDirectives(_createElementVNode("input", {
              type: "number",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.performancesState.performanceDetail.duration) = $event)),
              class: "form-control-thin txt-input",
              onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.validateDuration && _ctx.validateDuration(...args)))
            }, null, 544), [
              [_vModelText, _ctx.performancesState.performanceDetail.duration]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_12, " Campo obbligatorio e >= 0 ", 512), [
              [_vShow, _ctx.performancesState.performanceDetail.saving && !_ctx.performancesState.performanceDetail.durationOk]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[11] || (_cache[11] = _createElementVNode("label", {
            class: "modal-field-label",
            for: "cost"
          }, "Costo(€): ", -1)),
          _createElementVNode("div", _hoisted_14, [
            _withDirectives(_createElementVNode("input", {
              type: "number",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.performancesState.performanceDetail.cost) = $event)),
              class: "form-control-thin txt-input",
              onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.validateCost && _ctx.validateCost(...args)))
            }, null, 544), [
              [_vModelText, _ctx.performancesState.performanceDetail.cost]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_15, " Campo obbligatorio e >= 0 ", 512), [
              [_vShow, _ctx.performancesState.performanceDetail.saving && !_ctx.performancesState.performanceDetail.costOk]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-confirm",
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.savePerformance && _ctx.savePerformance(...args)))
        }, "Salva"),
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-remove",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.tryDeletePerformance && _ctx.tryDeletePerformance(...args)))
        }, "Elimina"),
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-discard",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.discardChanges && _ctx.discardChanges(...args)))
        }, "Annulla")
      ])
    ])
  ]))
}