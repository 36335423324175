<template>
  <div>
    <vue-recaptcha
      sitekey="6LfmiOUdAAAAAMqWP7ZrJIbpi_5DsXufuD41Abxr"
      size="invisible"
      theme="light"
      @verify="recaptchaVerified"
      @expire="recaptchaExpired"
      ref="vueRecaptcha"
    >
    </vue-recaptcha>
  </div>
</template>

<script lang="ts">
import vueRecaptcha from "vue3-recaptcha2";
import { INVALID_RECAPTCHA } from "../../core/common/utils/ResourceStrings";
import { manageSubscription } from "../common/SubscriptionManager";
import { ComponentPublicInstance, defineComponent, inject, Ref, ref } from "vue";
import { APP_PLOC_KEY, LOGIN_PLOC_KEY } from "../common/GUIConsts";
import { AppPLOC } from "../../core/app-navigation/presentation/AppPLOC";
import { LoginPLOC } from "@/core/login/presentation/LoginPLOC";
import { LoginState } from "../../core/login/presentation/LoginState";

// Definisci il tipo del riferimento
type VueRecaptchaInstance = ComponentPublicInstance & {
  execute: () => void;
  reset: () => void;
};

export default defineComponent({
  name: "Recaptcha",
  components: {
    vueRecaptcha,
  },

  setup() {
    const loginPLOC = inject(LOGIN_PLOC_KEY) as LoginPLOC;
    const loginState = manageSubscription(loginPLOC) as Ref<LoginState>;
    const appPLOC = inject(APP_PLOC_KEY) as AppPLOC;
    const vueRecaptcha = ref<VueRecaptchaInstance | null>(null);

    const getRecaptchaErrorMessage = () => {
      return INVALID_RECAPTCHA;
    };

    const execute = () => {
      if (vueRecaptcha.value) vueRecaptcha.value.execute();
    };

    const recaptchaVerified = async (captchaToken: string) => {
      await loginPLOC.doLogin(captchaToken);
      await appPLOC.onLogin(loginState.value.loginOk);
      if (vueRecaptcha.value) vueRecaptcha.value.reset();
    };

    const recaptchaExpired = () => {
      if (vueRecaptcha.value) vueRecaptcha.value.reset();
    };

    return {
      loginState,
      getRecaptchaErrorMessage,
      recaptchaVerified,
      recaptchaExpired,
      execute,
      vueRecaptcha,
    };
  },
});
</script>
