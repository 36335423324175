import { INotificationListener, NotificationDS, NotificationType } from "./NotificationDS";
import { v4 as uuidv4 } from "uuid";

export default class NotificationsManager {
  private static instance: NotificationsManager;
  private static listener: INotificationListener;
  private notifications: Array<NotificationDS>;

  public static setListener(listener: INotificationListener) {
    NotificationsManager.listener = listener;
  }

  public static getInstance(): NotificationsManager {
    if (!NotificationsManager.instance) {
      NotificationsManager.instance = new NotificationsManager();
    }

    return NotificationsManager.instance;
  }

  private constructor() {
    this.notifications = new Array<NotificationDS>();
  }

  public pushNewNotification(title: string, body: string, type: NotificationType) {
    const newNotification = { id: uuidv4(), title, body, type, time: new Date().toLocaleTimeString() };
    this.notifications.push(newNotification);
    if (NotificationsManager.listener) NotificationsManager.listener.onNewNotificationArrival(newNotification);
  }

  public deleteNotification(id: string) {
    const position = this.notifications.findIndex((notification) => notification.id === id);
    if (position >= 0) this.notifications.splice(position, 1);
    return position >= 0;
  }

  public getNotificationAt(position: number) {
    return this.notifications[position];
  }

  public getAllNotifications() {
    return this.notifications;
  }
}
