<template>
  <VueDatePicker
    class="app-date-picker"
    v-model="state.dateRange"
    :inline="true"
    :range="true"
    :autoApply="true"
    :enable-time-picker="false"
    :locale="'it-IT'"
    @internal-model-change="handleInternal"
    calendar-cell-class-name="dp-custom-cell"
    @range-start="onUserInput"
    @range-end="onUserInput"
    v-if="visible"
  ></VueDatePicker>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import DateUtils from "@/core/common/utils/DateUtils";
import { PLOC } from "@/core/common/presentation/PLOC";
import { manageSubscription } from "@/components/common/SubscriptionManager";

interface DatePickerState {
  dateRange: Array<Date>;
}

export default defineComponent({
  components: {
    VueDatePicker,
  },
  props: {
    visible: Boolean,
    weekFilter: Boolean,
    maxRangeFilter: Boolean,
    maxRange: Number,
    plocKey: String,
  },
  emits: ["change"],
  setup(props, { emit }) {
    if (props.plocKey) {
      const datePickerPLOC = inject(props.plocKey) as PLOC<DatePickerState>;
      const state = manageSubscription(datePickerPLOC);
      let previousState = state.value.dateRange;
      let explicitUserInput = false;

      const handleInternal = (newDateArray: Array<Date>) => {
        if (newDateArray) {
          const daysDiff = newDateArray.length > 1 ? DateUtils.diffDays(newDateArray[0], newDateArray[1]) : 0;
          const sevenDaysSelection = daysDiff === 6;
          const firstDayIsMonday = newDateArray[0].getDay() === 1;
          const overMaxRange = props.maxRange && daysDiff > props.maxRange;
          let rangeModified = false;
          if ((props.weekFilter && sevenDaysSelection && !firstDayIsMonday) || (props.maxRangeFilter && overMaxRange)) {
            //Oss: setTime necessario assolutamente, e' l'unico modo per cambiare il valore mantenendo il riferimento!,
            //altrimenti se si cambiasse il riferimento Vue continuerebbe a ragionare sulla data vecchia e ad ogni repaint
            //riaggiornerebbe con l'ultima data
            if (newDateArray[0].getTime() < previousState[0].getTime())
              newDateArray[1].setTime(newDateArray[0].getTime());
            else newDateArray[0].setTime(newDateArray[1].getTime());
            rangeModified = true;
          }

          previousState = newDateArray;

          //Mando il change solo se sono su un input esplicito dell'utente, se sto recependo un cambio data dal calendario non rimando
          //il change per evitare loop o comportamenti indesiderati
          if (explicitUserInput) emit("change", newDateArray);

          //Se 'e stato modificato il range da parte della logica di questa procedura a fronte di un input utente, allora tutti gli aggiornamenti successivi
          //del range conseguenti a questa modifica verrano comunque considerati come effettuati da parte dell'utente
          if (!rangeModified) explicitUserInput = false;
        }
      };
      const onUserInput = () => {
        //Scaturito al click su una data da parte dell'utente
        explicitUserInput = true;
      };
      return { handleInternal, state, onUserInput };
    }
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";
:root {
  /*General*/
  --dp-font-family: "Montserrat", sans-serif;

  --dp-cell-size: 25px; /*Width and height of calendar cell*/
  --dp-cell-padding: 0px; /*Padding in the cell*/
}

//quando c'e un range selezionato
.dp__main.app-date-picker:has(.dp__range_end) {
  .dp__calendar_item {
    .dp__range_between {
      border-radius: 5px;
    }
  }
}

.dp__main.app-date-picker {
  margin-top: 5px;
  justify-content: center;

  .dp__outer_menu_wrap {
    .dp__menu {
      border: 1px solid $gray-300;
      border-radius: 0px;

      .dp__inner_nav {
        color: $color-primary;
      }

      .dp__button {
        background: $gray-100;
        .dp__icon {
          display: none;
        }
        &:after {
          content: "Torna indietro";
          text-align: center;
          color: $color-primary;
        }
      }

      .dp__month_year_select,
      .dp__overlay_cell,
      .dp__overlay_cell_active {
        color: $color-primary;
        text-transform: capitalize;
      }
      .dp__overlay_cell_active {
        background: $gray-300;
      }

      .dp__calendar_header_item {
        color: $gray-600;
        font-weight: 400;
        text-transform: capitalize;
      }
      .dp__calendar_header_separator {
        color: $gray-600;
        margin-bottom: 10px;
      }

      .dp__date_hover_end,
      .dp__date_hover_start {
        background: white;
        border: white;
      }
      .dp__today {
        border: $selected-color 2px solid;
        border-radius: 20%;
      }

      .dp__range_start,
      .dp__range_end {
        background: $gray-300;
        color: $color-primary;
        font-weight: 500;
        border-radius: 20%;
      }

      .dp__calendar_row {
        margin: 10px 0px;
      }
      .dp__month_year_row {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
