import { HTTPClient, HTTPMethod, HTTPResponse } from "@/core/common/communication/HTTPClient";
import CalendarEventDS, { IEventExtremesInfos, SerializedCalendarEventDS } from "../domain/CalendarEventDS";
import EventsRepository from "../domain/EventsRepository";
import { LoadEventsResponse, GenericEventOperationResponse } from "../domain/EventOperationsResponse";
import {
  DELETE_EVENT_ERROR,
  LOADING_EVENTS_ERROR,
  SAVE_EVENT_ERROR,
  UPDATE_EVENT_ERROR,
  UPDATE_EVENT_EXTREMES_ERROR,
} from "@/core/common/utils/ResourceStrings";
import CalendarEvent from "../domain/CalendarEvent";

interface APILoadEventsResponseSuccess {
  result: boolean;
  events: Array<SerializedCalendarEventDS>;
}

interface APIGenericResponse {
  result: boolean;
  detail: string;
}

interface EventsByRangeRequest {
  startDate: Date;
  endDate: Date;
  filters: Array<string>;
}

type APILoadEventsResponse = APILoadEventsResponseSuccess | APIGenericResponse;

export default class EventsRepositoryImpl extends EventsRepository {
  private httpClient: HTTPClient;
  private selectedUsers: Set<string>;

  constructor(httpClient: HTTPClient) {
    super();
    this.httpClient = httpClient;
    this.selectedUsers = new Set();
  }

  public async loadEvents(): Promise<LoadEventsResponse> {
    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/getAllEvents", {});

    const result = {
      events: new Array<SerializedCalendarEventDS>(),
      success: true,
      errorMsg: "",
    };
    if (httpResponse.success && (<APILoadEventsResponse>httpResponse.data).result) {
      result.events = (<APILoadEventsResponseSuccess>httpResponse.data).events;
    } else {
      result.success = false;
      result.errorMsg = httpResponse.success
        ? `${LOADING_EVENTS_ERROR}${(<APIGenericResponse>httpResponse.data).detail}`
        : `${LOADING_EVENTS_ERROR}${httpResponse.errorMsg}`;
    }

    return Promise.resolve(result);
  }

  private injectFiltersInRequest(request: EventsByRangeRequest) {
    if (this.selectedUsers.size > 0) {
      request.filters = Array.from(this.selectedUsers);
    } else request.filters = new Array<string>();
  }

  public async loadEventsRange(startDate: Date, endDate: Date): Promise<LoadEventsResponse> {
    const eventsByRangeRequest = { startDate: startDate, endDate: endDate, filters: new Array<string>() };
    this.injectFiltersInRequest(eventsByRangeRequest);
    const httpResponse: HTTPResponse = await this.httpClient.send(
      HTTPMethod.POST,
      "/api/getEventsRange",
      eventsByRangeRequest
    );

    const result = {
      events: new Array<SerializedCalendarEventDS>(),
      success: true,
      errorMsg: "",
    };
    if (httpResponse.success && (<APILoadEventsResponse>httpResponse.data).result) {
      result.events = (<APILoadEventsResponseSuccess>httpResponse.data).events;
    } else {
      result.success = false;
      result.errorMsg = httpResponse.success
        ? `${LOADING_EVENTS_ERROR}${(<APIGenericResponse>httpResponse.data).detail}`
        : `${LOADING_EVENTS_ERROR}${httpResponse.errorMsg}`;
    }

    return Promise.resolve(result);
  }

  public async saveEvent(event: CalendarEventDS): Promise<GenericEventOperationResponse> {
    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/saveEvent", {
      event: CalendarEvent.getInstance().serialize(event),
    });

    const result = { success: false, errorMsg: "" };
    if (httpResponse.success && (<APIGenericResponse>httpResponse.data).result) result.success = true;
    else result.errorMsg = `${SAVE_EVENT_ERROR} :   ${(<APIGenericResponse>httpResponse.data).detail}`;
    return Promise.resolve(result);
  }

  public async updateEventExtremes(event: IEventExtremesInfos): Promise<GenericEventOperationResponse> {
    const serializedEvent = CalendarEvent.getInstance().serializeEventExtremes(event);
    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/updateEventExtremes", {
      ...serializedEvent,
    });

    const result = { success: false, errorMsg: "" };
    if (httpResponse.success && (<APIGenericResponse>httpResponse.data).result) result.success = true;
    else result.errorMsg = `${UPDATE_EVENT_EXTREMES_ERROR} :   ${(<APIGenericResponse>httpResponse.data).detail}`;
    return Promise.resolve(result);
  }

  public async update(event: CalendarEventDS): Promise<GenericEventOperationResponse> {
    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/updateEvent", {
      event: CalendarEvent.getInstance().serialize(event),
    });

    const result = { success: false, errorMsg: "" };
    if (httpResponse.success && (<APIGenericResponse>httpResponse.data).result) result.success = true;
    else result.errorMsg = `${UPDATE_EVENT_ERROR} :   ${(<APIGenericResponse>httpResponse.data).detail}`;
    return Promise.resolve(result);
  }

  public async deleteEvent(eventId: string): Promise<GenericEventOperationResponse> {
    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/deleteEvent", { id: eventId });

    const result = { success: false, errorMsg: "" };
    if (httpResponse.success && (<APIGenericResponse>httpResponse.data).result) result.success = true;
    else result.errorMsg = `${DELETE_EVENT_ERROR} :   ${(<APIGenericResponse>httpResponse.data).detail}`;
    return Promise.resolve(result);
  }

  public getSelectedUsers(): Set<string> {
    return this.selectedUsers;
  }

  public setSelectedUsers(value: Set<string>): void {
    this.selectedUsers = value;
  }
}
