import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "calendar-filters" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "title-icon" }
const _hoisted_4 = { class: "filter-values-container" }
const _hoisted_5 = { class: "filter-value-container" }
const _hoisted_6 = ["for"]
const _hoisted_7 = ["id", "onUpdate:modelValue", "onClick"]
const _hoisted_8 = ["id", "onClick"]
const _hoisted_9 = { class: "footer" }
const _hoisted_10 = { class: "selected-info" }
const _hoisted_11 = { class: "selected-number" }
const _hoisted_12 = { class: "footer-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_outlined = _resolveComponent("filter-outlined")!
  const _component_loader = _resolveComponent("loader")!
  const _component_virtual_searchable_list = _resolveComponent("virtual-searchable-list")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_right_outlined = _resolveComponent("right-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_filter_outlined)
        ]),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _cache[5] || (_cache[5] = _createElementVNode("span", null, "Utenti:", -1))
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_loader)
      ], 512), [
        [_vShow, _ctx.calendarState.filters.loading]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        (_openBlock(), _createBlock(_component_virtual_searchable_list, {
          "full-list-count": _ctx.calendarState.filters.allUsersCount,
          "get-list-item-fn": _ctx.getListFilterValue,
          "get-list-item-text-fn": _ctx.getListItemTextFn,
          key: _ctx.calendarState.filters.redrawList,
          "item-height": 27.2
        }, {
          customButtons: _withCtx(() => [
            _createElementVNode("button", {
              class: "list-macro-btn",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectAllFilterUsers && _ctx.selectAllFilterUsers(...args)))
            }, "Seleziona tutto"),
            _createElementVNode("button", {
              class: "list-macro-btn",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectNoneFilterUsers && _ctx.selectNoneFilterUsers(...args)))
            }, "Deseleziona tutto")
          ]),
          searchedItem: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", {
                class: "filter-value-item custom-checkbox",
                for: 'input_' + item.id
              }, [
                _createTextVNode(_toDisplayString(item.description) + " ", 1),
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  id: 'input_' + item.id,
                  "onUpdate:modelValue": ($event: any) => ((item.selected) = $event),
                  onClick: ($event: any) => (_ctx.toggleUserSelection(item.id))
                }, null, 8, _hoisted_7), [
                  [_vModelCheckbox, item.selected]
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "checkmark" }, null, -1))
              ], 8, _hoisted_6),
              _createElementVNode("span", {
                class: "only",
                id: 'only_' + item.id,
                onClick: ($event: any) => (_ctx.selectOneFilterUser(item.id))
              }, "only", 8, _hoisted_8)
            ])
          ]),
          _: 1
        }, 8, ["full-list-count", "get-list-item-fn", "get-list-item-text-fn"]))
      ], 512), [
        [_vShow, !_ctx.calendarState.filters.loading]
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.calendarState.filters.selectedUsersCount), 1),
          _cache[7] || (_cache[7] = _createTextVNode(" Elementi selezionati "))
        ]),
        _createElementVNode("div", _hoisted_12, [
          (_ctx.calendarState.filters.isFilterActive)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-outline btn-outline-remove",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeFilters && _ctx.removeFilters(...args)))
              }, [
                _createVNode(_component_close_outlined)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: "btn btn-outline btn-outline-filter",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.refetchEvents && _ctx.refetchEvents(...args)))
          }, [
            _createVNode(_component_filter_outlined),
            _createVNode(_component_right_outlined)
          ])
        ])
      ])
    ])
  ]))
}