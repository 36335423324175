export default class CellWidthCalculator {
  private cellWidthCalculatorDOM: HTMLElement;
  private font: string;
  private context: CanvasRenderingContext2D;
  static TOLERANCE = 20;

  constructor(cellWidthCalculatorDOM: HTMLElement, font: string) {
    this.cellWidthCalculatorDOM = cellWidthCalculatorDOM;
    this.font = font;
    const contextOrNull = (this.cellWidthCalculatorDOM as HTMLCanvasElement).getContext("2d");
    if (contextOrNull) {
      this.context = contextOrNull;
      this.context.font = this.font;
    } else this.context = {} as CanvasRenderingContext2D;
  }

  public calculateCellWidth(cellValue: string) {
    const width = this.context.measureText(cellValue).width;
    return width;
  }
}
