import { PLOC } from "@/core/common/presentation/PLOC";
import { PerformancesState, performancesInitialState } from "./PerformancesState";
import PerformancesRepository from "../domain/PerformancesRepository";
import PerformancesListOperationsUseCase from "../domain/usecases/PerformancesListOpeationsUseCase";
import PerformanceDetailUseCase from "../domain/usecases/PerformanceDetailUseCase";
import DeletePerformancesUseCase from "../domain/usecases/DeletePerformancesUseCase";
import FormatPerformanceUseCase from "../domain/usecases/FormatPerformanceUseCase";

export default class PerformancesPLOC extends PLOC<PerformancesState> {
  private performancesRepository: PerformancesRepository;

  constructor(performancesRepository: PerformancesRepository) {
    super(performancesInitialState);
    this.performancesRepository = performancesRepository;
  }

  public async loadPerformances() {
    const result = await this.performancesRepository.loadPerformances();
    return result;
  }

  public getAllPerformancesIds() {
    return this.performancesRepository.getAllPerformancesIds();
  }

  public getPerformanceById(performanceId: string) {
    return this.performancesRepository.getPerformanceById(performanceId);
  }

  public getPerformanceLabel(performanceId: string) {
    return FormatPerformanceUseCase.doFormat(this.performancesRepository, performanceId);
  }

  public getPerformancesMap() {
    return this.performancesRepository.getPefromancesMap();
  }

  public async loadActivePefromances() {
    const result = await this.performancesRepository.loadPerformances();

    if (result.success) {
      const activePefromances = this.performancesRepository.getActivePerformancesIds();
      this.changeState({
        ...this.state,
        activePerformancesCount: activePefromances.length,
        redrawList: Date.now(),
        visiblePerformancesCount: activePefromances.length,
      });
    } else {
      this.changeState({ ...this.state, error: true });
    }
  }

  public visiblePerformancesChange(newValue: number) {
    this.changeState({
      ...this.state,
      visiblePerformancesCount: newValue >= 0 ? newValue : this.state.activePerformancesCount,
    });
  }

  public getActivePerformanceByIndex(index: number) {
    return PerformancesListOperationsUseCase.getActivePerformanceByIndex(this.performancesRepository, index);
  }

  public togglePerformanceSelection(performanceId: string) {
    const selectedPerformancesCount = PerformancesListOperationsUseCase.togglePerformanceSelection(performanceId);
    this.changeState({ ...this.state, selectedPerformancesCount });
  }

  public createNewPerformance() {
    this.changeState({
      ...this.state,
      performanceDeleteConfirmVisible: false,

      performanceDetail: {
        ...this.state.performanceDetail,
        visible: true,
        title: "Nuova prestazione",
        saving: false,
        ...PerformanceDetailUseCase.createNewPerformance(),
      },
    });
  }

  public async savePerformance() {
    const performanceValidation = PerformanceDetailUseCase.validatePerformance(this.state.performanceDetail);
    let visible = true;
    let redrawList = this.state.redrawList;
    let activePerformancesCount = this.state.activePerformancesCount;
    if (performanceValidation.canSave) {
      const saveResult = await PerformanceDetailUseCase.savePerformance(
        this.state.performanceDetail,
        this.performancesRepository
      );

      if (saveResult.success) {
        redrawList = Date.now();
        if (this.state.performanceDetail.isNew) activePerformancesCount++;
        visible = false;
      } else {
        visible = true;
      }
    }
    this.changeState({
      ...this.state,
      redrawList,
      activePerformancesCount,
      visiblePerformancesCount: activePerformancesCount,
      performanceDetail: {
        ...this.state.performanceDetail,
        ...performanceValidation,
        visible,
      },
    });
  }

  public validateName() {
    this.changeState({
      ...this.state,
      performanceDetail: {
        ...this.state.performanceDetail,
        nameOk: PerformanceDetailUseCase.validateName(this.state.performanceDetail.name),
      },
    });
  }

  public validateDuration() {
    this.changeState({
      ...this.state,
      performanceDetail: {
        ...this.state.performanceDetail,
        durationOk: PerformanceDetailUseCase.validateDuration(this.state.performanceDetail.duration),
      },
    });
  }

  public validateCost() {
    this.changeState({
      ...this.state,
      performanceDetail: {
        ...this.state.performanceDetail,
        costOk: PerformanceDetailUseCase.validateCost(this.state.performanceDetail.cost),
      },
    });
  }

  public discardChanges() {
    this.changeState({
      ...this.state,
      performanceDetail: {
        ...this.state.performanceDetail,
        visible: false,
        ...PerformanceDetailUseCase.discardChanges(),
      },
    });
  }

  public editPerformance(performanceId: string) {
    this.changeState({
      ...this.state,
      performanceDeleteConfirmVisible: false,
      performanceDetail: {
        ...PerformanceDetailUseCase.editPerformance(performanceId, this.performancesRepository),
        title: "Modifica prestazione",
      },
    });
  }

  public tryDeletePerformance() {
    this.changeState({ ...this.state, performanceDeleteConfirmVisible: true });
  }

  public async deletePerformances() {
    let deleteResult;
    let selectedPerformancesCount = this.state.selectedPerformancesCount;
    if (this.state.performanceDetail.visible)
      deleteResult = await DeletePerformancesUseCase.deleteSinglePeformance(
        this.state.performanceDetail.id,
        this.performancesRepository
      );
    else if (this.state.listPerformanceClicked) {
      deleteResult = await PerformancesListOperationsUseCase.deleteSinglePerformance(
        this.state.listPerformanceClicked,
        this.performancesRepository
      );
      selectedPerformancesCount = deleteResult.newSelectedPerformanceCount;
    } else {
      deleteResult = await PerformancesListOperationsUseCase.deletePerformances(this.performancesRepository);
      selectedPerformancesCount = 0;
    }

    if (deleteResult.success)
      this.changeState({
        ...this.state,
        selectedPerformancesCount,
        listPerformanceClicked: "",
        performanceDeleteConfirmVisible: false,
        activePerformancesCount: deleteResult.newActivePerformancesCount,
        visiblePerformancesCount: deleteResult.newActivePerformancesCount,
        redrawList: Date.now(),
        performanceDetail: { ...this.state.performanceDetail, visible: false },
      });
  }

  public closePerformanceDeleteConfirm() {
    this.changeState({ ...this.state, performanceDeleteConfirmVisible: false });
  }

  public selectAllPerformances() {
    const selectedPerformancesCount = PerformancesListOperationsUseCase.selectAllPerformances(
      this.performancesRepository
    );
    this.changeState({
      ...this.state,
      redrawList: Date.now(),
      selectedPerformancesCount,
    });
  }

  public deselectAllPerformances() {
    const selectedPerformancesCount = PerformancesListOperationsUseCase.deselectAllPerformances();
    this.changeState({
      ...this.state,
      redrawList: Date.now(),
      selectedPerformancesCount,
    });
  }

  public setListPerformanceClicked(id: string) {
    this.changeState({ ...this.state, listPerformanceClicked: id });
  }
}
