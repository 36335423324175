import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list-container" }
const _hoisted_2 = {
  class: "list-header",
  ref: "listHeader"
}
const _hoisted_3 = { class: "search-bar-container" }
const _hoisted_4 = { class: "macro-part" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_bar = _resolveComponent("search-bar")!
  const _component_virtual_scrolling_list = _resolveComponent("virtual-scrolling-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_search_bar, { onChangeFn: _ctx.onSearchBarChange }, null, 8, ["onChangeFn"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "customButtons")
      ])
    ], 512),
    (_openBlock(), _createBlock(_component_virtual_scrolling_list, {
      viewportHeightStyle: _ctx.viewportHeightStyle,
      allItemsCount: _ctx.state.filteredValuesCount,
      itemHeight: _ctx.itemHeight,
      key: _ctx.state.redrawList
    }, {
      item: _withCtx(({ itemIndex }) => [
        _renderSlot(_ctx.$slots, "searchedItem", _normalizeProps(_guardReactiveProps({ item: _ctx.getListItem(itemIndex) })))
      ]),
      _: 3
    }, 8, ["viewportHeightStyle", "allItemsCount", "itemHeight"]))
  ]))
}