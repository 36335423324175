import { LoadPerformancesResponse, PerformancesOperarionsResponse } from "./PerformancesOperationsResponse";
import PerformanceDS from "./PerformanceDS";

export default abstract class PerformancesRepository {
  public abstract getPerformanceById(id: string): PerformanceDS;
  public abstract loadPerformances(): Promise<LoadPerformancesResponse>;
  public abstract getAllPerformancesIds(): Array<string>;
  public abstract getPefromancesMap(): Map<string, PerformanceDS>;
  public abstract getActivePerformancesIds(): Array<string>;
  public abstract deletePerformances(ids: Array<string>): Promise<PerformancesOperarionsResponse>;
  public abstract updatePerformance(performance: PerformanceDS): Promise<PerformancesOperarionsResponse>;
  public abstract savePerformance(performance: PerformanceDS): Promise<PerformancesOperarionsResponse>;
}
