import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelRadio as _vModelRadio, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "modal pivot-modal pivot-ordering-modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-close" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "title-icon" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "entity-name" }
const _hoisted_8 = { class: "order-by" }
const _hoisted_9 = { class: "order-type" }
const _hoisted_10 = { class: "switch-field" }
const _hoisted_11 = ["id", "name", "onUpdate:modelValue"]
const _hoisted_12 = ["for"]
const _hoisted_13 = ["id", "name", "onUpdate:modelValue"]
const _hoisted_14 = ["for"]
const _hoisted_15 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_swap_outlined = _resolveComponent("swap-outlined")!
  const _component_Select = _resolveComponent("Select")!
  const _component_sort_ascending_outlined = _resolveComponent("sort-ascending-outlined")!
  const _component_sort_descending_outlined = _resolveComponent("sort-descending-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_close_outlined, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_swap_outlined, { rotate: 90 })
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Ordinamento", -1))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "entity-ordering" }, [
          _createElementVNode("h5", null, "Dimensione"),
          _createElementVNode("h5", null, "Ordina per"),
          _createElementVNode("h5", null, "Verso di ordinamento")
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSelectedDimensions(), (selectedDim) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "entity-ordering",
            key: selectedDim.alias
          }, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(selectedDim.desc), 1),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Select, {
                placeholder: _ctx.getSelectPh(),
                showLabels: false,
                width: 110,
                options: [..._ctx.getSelectedMeasures(), selectedDim.alias],
                selectedSingle: selectedDim.orderBy,
                customLabelFn: _ctx.formatOrderBy,
                searchable: false,
                selectKey: selectedDim.alias,
                changeFn: _ctx.setEntityOrderBy
              }, null, 8, ["placeholder", "options", "selectedSingle", "customLabelFn", "selectKey", "changeFn"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  id: 'radio-asc-' + selectedDim.alias,
                  name: 'radio-' + selectedDim.alias,
                  value: "asc",
                  "onUpdate:modelValue": ($event: any) => ((selectedDim.orderType) = $event)
                }, null, 8, _hoisted_11), [
                  [_vModelRadio, selectedDim.orderType]
                ]),
                _createElementVNode("label", {
                  for: 'radio-asc-' + selectedDim.alias
                }, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "order-label-text" }, "asc", -1)),
                  _createVNode(_component_sort_ascending_outlined)
                ], 8, _hoisted_12),
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  id: 'radio-desc-' + selectedDim.alias,
                  name: 'radio-' + selectedDim.alias,
                  value: "desc",
                  "onUpdate:modelValue": ($event: any) => ((selectedDim.orderType) = $event)
                }, null, 8, _hoisted_13), [
                  [_vModelRadio, selectedDim.orderType]
                ]),
                _createElementVNode("label", {
                  for: 'radio-desc-' + selectedDim.alias
                }, [
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "order-label-text" }, "disc", -1)),
                  _createVNode(_component_sort_descending_outlined)
                ], 8, _hoisted_14)
              ])
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-confirm",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.saveOptions && _ctx.saveOptions(...args)))
        }, _toDisplayString(_ctx.getSaveString()), 1),
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-discard",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, _toDisplayString(_ctx.getDiscardString()), 1)
      ])
    ])
  ]))
}