<template>
  <div :class="outerClassObj">
    <div v-bind:style="userAvatarStyleObj" v-bind:class="userAvatarClassObj" class="user-avatar">
      {{ initials }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
export default defineComponent({
  props: {
    userName: String,
    userSurname: String,
    color: String,
    clickable: Boolean,
    dimension: String,
    show: Boolean,
    showStatus: { type: Boolean, default: true },
  },
  setup(props) {
    const outerClassObj = computed(() => {
      return {
        "avatar-outer": props.clickable,
      };
    });
    const userAvatarStyleObj = computed(() => {
      return {
        background: props.color,
        cursor: props.clickable ? "pointer" : "auto",
      };
    });

    const userAvatarClassObj = computed(() => {
      return {
        "user-avatar-md": props.dimension == "md",
        "user-avatar-lg": props.dimension == "lg",
        "user-avatar-show": props.show,
        "with-status": props.showStatus,
      };
    });

    const initials = computed(() => {
      if (props.userName && props.userSurname) return props.userName[0] + props.userSurname[0];
      else return "";
    });

    return {
      userAvatarStyleObj,
      userAvatarClassObj,
      outerClassObj,
      initials,
    };
  },
});
</script>
<style lang="scss">
@import "./public/styles/variables.scss";

.avatar-outer {
  padding: 4px;
  &:hover {
    background: $gray-300;
    border-radius: 50%;
  }
}

.user-avatar {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  background-color: $gray-700;
}

.with-status {
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 3px;
    width: 6px;
    height: 6px;
    background-color: $gray-500;
    box-shadow: 0 0 0 2px rgba(#fff, 0.95);
    border-radius: 100%;
    background-color: $green;
  }
}

@media only screen and (min-width: 992px) {
  .user-avatar-md {
    width: 48px;
    height: 48px;
    font-size: 24px;

    &::after {
      width: 9px;
      height: 9px;
      right: 2px;
      bottom: 2px;
    }
  }
}
.user-avatar-lg {
  width: 64px;
  height: 64px;
  font-size: 28px;

  &::after {
    width: 10px;
    height: 10px;
    bottom: 3px;
    right: 4px;
  }
}
</style>
