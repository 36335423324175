import { ERROR_MSG, SESSION_KO_MSG, START_SESSION_HEARTBEAT_MSG } from "../common/AppConsts";
import ISessionListener from "./SessionListerner";

export default class WorkerManager {
  private worker: Worker;
  private listener: ISessionListener;
  private workerInitialized: boolean;

  constructor(listener: ISessionListener) {
    this.worker = {} as Worker;
    this.listener = listener;
    this.workerInitialized = false;
  }

  private initWorker() {
    this.worker = new Worker(new URL("./session-worker.js", import.meta.url));
    this.worker.onmessage = ({ data }) => {
      switch (data.type) {
        case SESSION_KO_MSG:
          this.listener.onSessionKo();
          break;

        case ERROR_MSG:
          this.listener.onError();
          break;
        default:
      }
    };
    this.workerInitialized = true;
  }

  public startSessionHeartBeat() {
    if (!this.workerInitialized) {
      this.initWorker();
    }
    this.worker.postMessage({ data: { type: START_SESSION_HEARTBEAT_MSG } });
  }

  public terminate() {
    if (this.workerInitialized && this.worker) this.worker.terminate();
    this.workerInitialized = false;
  }
}
