import { TUserWithValidation } from "../domain/UserDS";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UsersState {
  activeUsersCount: number;
  redrawList: number;
  selectedUsersCount: number;
  userDeleteConfirmVisible: boolean;
  userDetail: TUserWithValidation;
  visibleUsersCount: number;
  listUserClicked: string;
  error: boolean;
  loggedUserDetailVisible: boolean;
}

export const usersInitialState = {
  activeUsersCount: 0,
  visibleUsersCount: 0,
  redrawList: Date.now(),
  selectedUsersCount: 0,
  userDeleteConfirmVisible: false,
  listUserClicked: "",
  error: false,
  userDetail: {
    id: "",
    name: "",
    surname: "",
    type: "",
    color: "",
    phone: "",
    mail: "",
    password: "",
    deleted: false,
    visible: false,
    title: "",
    nameOk: false,
    mailOk: false,
    surnameOk: false,
    colorOk: false,
    isNew: false,
    saving: false,
    pwdOk: false,
    userDeleteConfirmVisible: false,
  },
  loggedUserDetailVisible: false,
};
