<template>
  <!--List viewport: elemento container che detta l'altezza della viewport, quindi della parte visibile-->
  <div class="list-viewport" :style="{ height: '100%' }">
    <!--Scroller: elemento che da l'overflow e quindi la possibilita' di scrollare, contiene gli elementi visibili della lista e in ::after la lista con l' altezza totale -->
    <div
      class="scroller"
      @scroll="updateScrollingList"
      ref="scroller"
      :style="{ '--full-height': state.totalHeight + 'px' }"
    >
      <!--Alignment box: 'e ad altezza zero e serve per far partire il ::after e il list-items-container dalla stessa altezza. Inoltre 'e in sticky e quindi permette al list-items-container di rimanere sempre "davanti" ad ::after-->
      <div class="alignment-box">
        <!--List items container: contiene gli elementi veri e propri della lista, fissi nel numero, e fa il fake dello scroll andando a traslarsi da min -itemsHeight a max 0-->
        <div
          class="list-items-container"
          :style="{
            'will-change': 'transform',
            transform: 'translateY(' + state.offsetY + 'px)',
          }"
        >
          <div
            v-for="n in state.visibleNodeCount"
            :class="{
              'list-item': true,
              even: isListItem(n) && absoluteItemIndex(n) % 2 === 0,
              odd: isListItem(n) && absoluteItemIndex(n) % 2 !== 0,
            }"
            :key="absoluteItemIndex(n)"
          >
            <slot v-if="isListItem(n)" name="item" v-bind="{ itemIndex: absoluteItemIndex(n) }" />
            <div v-else :style="{ height: itemHeight + 'px' }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  components: {},
  props: {
    viewportHeightStyle: String,
    allItemsCount: Number,
    itemHeight: Number,
  },
  setup(props) {
    const scroller = ref<HTMLElement>();
    const fullContent = ref<HTMLElement>();
    const NODE_PADDING = 5;
    let state = ref({ totalHeight: 0, offsetY: 0, visibleNodeCount: 0, startNode: 0 });
    let viewportHeight = 0;

    const initResizeObserver = () => {
      const resizeObserver = new ResizeObserver((entries) => {
        if (entries[0].contentRect.width > 0 && entries[0].contentRect.height > 0) {
          viewportHeight = Math.floor(entries[0].contentRect.height);
          updateScrollingList();
        }
      });

      if (scroller.value) resizeObserver.observe(scroller.value);
    };

    const updateScrollingList = () => {
      window.requestAnimationFrame(() => {
        if (scroller.value && props.allItemsCount && props.itemHeight) {
          const scrollTop = scroller.value.scrollTop;
          state.value.totalHeight = props.allItemsCount * props.itemHeight;

          let startNode = Math.floor(scrollTop / props.itemHeight);

          state.value.visibleNodeCount = Math.ceil(viewportHeight / props.itemHeight) + NODE_PADDING;

          if (startNode + Math.ceil(viewportHeight / props.itemHeight) - 1 >= props.allItemsCount) return;

          const offsetY = -scrollTop % props.itemHeight;

          state.value.startNode = startNode;
          state.value.offsetY = offsetY;
        }
      });
    };

    const absoluteItemIndex = (relativeItemIndex: number) => {
      return state.value.startNode + relativeItemIndex - 1;
    };

    const isListItem = (relativeItemIndex: number) => {
      if (props.allItemsCount) return absoluteItemIndex(relativeItemIndex) < props.allItemsCount;
      else return false;
    };

    onMounted(() => {
      updateScrollingList();
      initResizeObserver();
    });

    return {
      state,
      updateScrollingList,
      scroller,
      fullContent,
      absoluteItemIndex,
      isListItem,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

.list-viewport {
  display: block;
  overflow: hidden;
  overscroll-behavior: none;
  position: relative;
  width: 100%;

  .scroller {
    display: inline-block;
    overflow: auto;
    overscroll-behavior: none;
    position: relative;
    width: 100%;
    height: 100%;

    .alignment-box {
      position: sticky;
      top: 0;
      left: 0;
      overflow: visible;

      .list-items-container {
        width: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        box-shadow: 0 1px 0 #dbdbdb;
      }
    }

    &:after {
      display: block;
      content: "";
      height: var(--full-height);
    }
  }
}
</style>
