import UsersRepository from "@/core/users/domain/UsersRepository";
import FormatUserUseCase from "@/core/users/domain/usecases/FormatUserUseCase";
import EventsRepository from "../EventsRepository";

export default class FiltersOperationsUseCase {
  private eventsRepository: EventsRepository;
  private usersRepository: UsersRepository;

  constructor(usersRepository: UsersRepository, eventsRepository: EventsRepository) {
    this.eventsRepository = eventsRepository;
    this.usersRepository = usersRepository;
  }

  public getUserByIndex(index: number) {
    const usersIds = this.usersRepository.getAllUsersIds();
    if (index < usersIds.length) {
      const user = this.usersRepository.getUserById(usersIds[index]);
      return {
        id: user.id,
        description: FormatUserUseCase.doFormat(this.usersRepository, user.id),
        selected: this.eventsRepository.getSelectedUsers().has(user.id),
      };
    } else {
      console.error("FiltersOperationsUseCase/getUserByIndex: trying to access non existing user index");
      return {
        id: "not_found_" + index,
        descriprion: "Utente " + index + " non trovato",
        selected: false,
      };
    }
  }

  public toggleUserSelection(userId: string) {
    if (this.eventsRepository.getSelectedUsers().has(userId)) this.eventsRepository.getSelectedUsers().delete(userId);
    else this.eventsRepository.getSelectedUsers().add(userId);
    return this.eventsRepository.getSelectedUsers().size;
  }

  public selectAllUsers() {
    const allUsers = this.usersRepository.getAllUsersIds();
    this.eventsRepository.setSelectedUsers(new Set(allUsers));
    return allUsers.length;
  }

  public deselectAllUsers() {
    this.eventsRepository.getSelectedUsers().clear();
    return 0;
  }

  public selectOne(value: string) {
    const newSelected = new Set<string>();
    newSelected.add(value);
    this.eventsRepository.setSelectedUsers(newSelected);
  }
}
