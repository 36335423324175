import NotificationsManager from "@/core/notifications/domain/NotificationsManager";
import UsersRepository from "../UsersRepository";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";

export default class DeleteUsersUseCase {
  public static async deleteUsers(users: Array<string>, usersRepository: UsersRepository) {
    const notificationsManager = NotificationsManager.getInstance();

    const result = await usersRepository.deleteUsers(users);
    const newActiveUsersCount = usersRepository.getActiveUsersIds().length;

    if (result.success)
      notificationsManager.pushNewNotification("Rimozione avvenuta con successo", "", NotificationType.Ok);
    else notificationsManager.pushNewNotification("Rimozione fallita", result.errorMsg, NotificationType.Ko);

    return { ...result, newActiveUsersCount };
  }

  public static async deleteSingleUser(userId: string, usersRepository: UsersRepository) {
    const users = new Array<string>();
    users.push(userId);
    return await DeleteUsersUseCase.deleteUsers(users, usersRepository);
  }
}
