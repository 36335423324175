import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal pivot-modal pivot-settings-modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-close" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "title-icon" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "opts-fieldset" }
const _hoisted_8 = { class: "opts-group" }
const _hoisted_9 = { class: "opts-fieldset" }
const _hoisted_10 = { class: "opts-group" }
const _hoisted_11 = { class: "opts-fieldset" }
const _hoisted_12 = { class: "opts-group" }
const _hoisted_13 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_setting_outlined = _resolveComponent("setting-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_close_outlined, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_setting_outlined)
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Impostazioni", -1))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("fieldset", _hoisted_7, [
          _cache[12] || (_cache[12] = _createElementVNode("legend", null, "Misure:", -1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                id: "measuresOnColumn",
                checked: "",
                name: "measuresOnColumn",
                value: true,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pivotState.optsWorkingCopy.measuresOnColumn) = $event))
              }, null, 512), [
                [_vModelRadio, _ctx.pivotState.optsWorkingCopy.measuresOnColumn]
              ]),
              _cache[10] || (_cache[10] = _createElementVNode("label", { for: "measuresOnColumn" }, "Misure in colonna", -1))
            ]),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                id: "measuresOnRow",
                name: "measuresOnColumn",
                value: false,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pivotState.optsWorkingCopy.measuresOnColumn) = $event))
              }, null, 512), [
                [_vModelRadio, _ctx.pivotState.optsWorkingCopy.measuresOnColumn]
              ]),
              _cache[11] || (_cache[11] = _createElementVNode("label", { for: "measuresOnRow" }, "Misure in riga", -1))
            ])
          ])
        ]),
        _createElementVNode("fieldset", _hoisted_9, [
          _cache[15] || (_cache[15] = _createElementVNode("legend", null, "Totali", -1)),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: "bigTotalOnColumn",
                name: "bigTotalOnColumn",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pivotState.optsWorkingCopy.bigTotalOnColumn) = $event))
              }, null, 512), [
                [_vModelCheckbox, _ctx.pivotState.optsWorkingCopy.bigTotalOnColumn]
              ]),
              _cache[13] || (_cache[13] = _createElementVNode("label", { for: "bigTotalOnColumn" }, "Totale di colonna", -1))
            ]),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: "bigTotalOnRow",
                name: "bigTotalOnRow",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pivotState.optsWorkingCopy.bigTotalOnRow) = $event))
              }, null, 512), [
                [_vModelCheckbox, _ctx.pivotState.optsWorkingCopy.bigTotalOnRow]
              ]),
              _cache[14] || (_cache[14] = _createElementVNode("label", { for: "bigTotalOnRow" }, "Totale di riga", -1))
            ])
          ])
        ]),
        _createElementVNode("fieldset", _hoisted_11, [
          _cache[18] || (_cache[18] = _createElementVNode("legend", null, "Subtotali", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: "subTotalsOnColumn",
                name: "subTotalsOnColumn",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pivotState.optsWorkingCopy.subTotalsOnColumn) = $event))
              }, null, 512), [
                [_vModelCheckbox, _ctx.pivotState.optsWorkingCopy.subTotalsOnColumn]
              ]),
              _cache[16] || (_cache[16] = _createElementVNode("label", { for: "subTotalsOnColumn" }, "Subtotali di colonna", -1))
            ]),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: "subTotalsOnRow",
                name: "subTotalsOnRow",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pivotState.optsWorkingCopy.subTotalsOnRow) = $event))
              }, null, 512), [
                [_vModelCheckbox, _ctx.pivotState.optsWorkingCopy.subTotalsOnRow]
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("label", { for: "subTotalsOnRow" }, "Subtotali di riga", -1))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-confirm",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.saveOptions && _ctx.saveOptions(...args)))
        }, _toDisplayString(_ctx.getSaveString()), 1),
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-discard",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, _toDisplayString(_ctx.getDiscardString()), 1)
      ])
    ])
  ]))
}