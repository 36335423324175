import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "internal-app" }
const _hoisted_2 = { class: "internal-app-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Iconbar = _resolveComponent("Iconbar")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_calendar = _resolveComponent("calendar")!
  const _component_analysis = _resolveComponent("analysis")!
  const _component_users_list = _resolveComponent("users-list")!
  const _component_performances_list = _resolveComponent("performances-list")!
  const _component_notifications_column = _resolveComponent("notifications-column")!
  const _component_user_detail = _resolveComponent("user-detail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Iconbar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PageHeader),
      (_ctx.appState.currentPage === _ctx.getCalendarPageId())
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_calendar)
          ]))
        : _createCommentVNode("", true),
      (_ctx.appState.currentPage === _ctx.getAnalysisPageId())
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_analysis)
          ]))
        : _createCommentVNode("", true),
      (_ctx.appState.currentPage === _ctx.getUsersPageId())
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_users_list)
          ]))
        : _createCommentVNode("", true),
      (_ctx.appState.currentPage === _ctx.getPerformancesPageId())
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_performances_list)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_notifications_column),
      _withDirectives(_createVNode(_component_user_detail, null, null, 512), [
        [_vShow, _ctx.usersSate.loggedUserDetailVisible]
      ])
    ])
  ]))
}