<template>
  <div class="modal pivot-modal pivot-filters-modal" id="pivotFilters">
    <div class="modal-content">
      <div class="modal-close">
        <close-outlined @click="closeModal()"></close-outlined>
      </div>
      <div class="modal-header">
        <div class="title-icon"><filter-outlined></filter-outlined></div>
        <h3>Filtri</h3>
      </div>
      <div class="modal-body filters-modal-body">
        <div v-show="subtitleVisible()" class="filter-subtitle">
          <div>
            <span v-show="pivotState.filtersEditing.editFilterMode">
              <span @click="exitEditMode" class="breadcrumb">Elenco filtri attivi</span>
              <span> | </span>
            </span>
            <h3>{{ pivotState.filtersEditing.filterSubtitle }}</h3>
          </div>

          <button
            v-show="!pivotState.filtersEditing.editFilterMode"
            @click="createNewFilter()"
            class="btn btn-outline btn-new-filter btn-outline-filter"
          >
            <plus-outlined></plus-outlined>
          </button>
        </div>
        <div v-show="!pivotState.filtersEditing.editFilterMode" class="filter-list">
          <div v-for="filter in pivotState.optsWorkingCopy.filters" v-bind:key="filter.entityAlias" class="filter">
            <div class="filter-left">
              <div class="filter-icon"></div>
              <div class="filter-text">{{ getFilterDescription(filter) }}</div>
            </div>
            <div class="filter-right">
              <span class="filter-action" @click="editFilter(filter.entityAlias)"><edit-outlined></edit-outlined></span>
              <span class="filter-action" @click="removeFilter(filter.entityAlias)"
                ><delete-outlined></delete-outlined
              ></span>
            </div>
          </div>
        </div>
        <div class="edit-filter" v-show="pivotState.filtersEditing.editFilterMode">
          <div class="filter-on">
            <span>Filtro su: </span>
            <Select
              :disabled="!isNewFilter()"
              :placeholder="'Seleziona un valore'"
              :showLabels="false"
              :width="200"
              :options="[...getFilterableDims()]"
              :selectedSingle="pivotState.filtersEditing.filterOnEdit.entityAlias"
              :customLabelFn="getDimensionDescription"
              :searchable="false"
              :selectKey="pivotState.filtersEditing.filterOnEdit.entityAlias"
              :changeFn="setFilterEntity"
            >
            </Select>
          </div>
          <div v-if="isValuesListFilter()" class="filter-values-container">
            <virtual-searchable-list
              :full-list-count="pivotState.filtersEditing.filterOnEdit.allValuesCount"
              :get-list-item-fn="getListFilterValue"
              :get-list-item-text-fn="getListItemTextFn"
              :key="pivotState.filtersEditing.filterOnEdit.entityAlias + internalState.redrawList"
              :item-height="28"
            >
              <template #customButtons>
                <button class="list-macro-btn" @click="selectAllFilterValues">Seleziona tutto</button>
                <button class="list-macro-btn" @click="selectNoneFilterValues">Deseleziona tutto</button>
              </template>
              <template #searchedItem="{ item }">
                <div class="filter-value-container">
                  <label class="filter-value-item custom-checkbox" :for="'input_' + item.id">
                    {{ item.description }}
                    <input
                      type="checkbox"
                      :id="'input_' + item.id"
                      v-model="item.selected"
                      @click="toggleFilterSelectedValue($event)"
                    />

                    <span class="checkmark"></span>
                  </label>
                  <span class="only" :id="'only_' + item.id" @click="selectOneFilterValue">only</span>
                </div>
              </template></virtual-searchable-list
            >
          </div>
          <div v-else class="date-filter-values">
            <div class="date-filter-value">
              <span>Data di inizio:</span>
              <date-picker
                :initialValue="getTemporalFilterValue().start"
                @change="onStartFilterValueChange"
              ></date-picker>
            </div>
            <div class="date-filter-value">
              <span>Data di fine:</span>
              <date-picker :initialValue="getTemporalFilterValue().end" @change="onEndFilterValueChange"></date-picker>
            </div>
          </div>
          <div class="error">{{ pivotState.filtersEditing.filterSaveErrorMsg }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline btn-outline-confirm" @click="saveOptions">
          {{ getSaveString() }}
        </button>

        <button class="btn btn-outline btn-outline-discard" @click="closeModal">
          {{ getDiscardString() }}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject, ref } from "vue";
import { CloseOutlined, FilterOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { DISCARD, SAVE } from "@/core/common/utils/ResourceStrings";
import { PIVOT_PLOC_KEY } from "@/components/common/GUIConsts";
import PivotPLOC from "@/core/pivot/presentation/PivotPLOC";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import { IFilter } from "@/core/pivot/domain/PivotOptionsDS";
import Select from "../../controls/Select.vue";
import { DATE_DIM_CODE } from "@/core/pivot/common/PivotConsts";
import DatePicker from "../../controls/DatePicker.vue";
import VirtualSearchableList from "../../controls/list/VirtualSearchableList.vue";

export default defineComponent({
  components: {
    CloseOutlined,
    FilterOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    Select,
    DatePicker,
    VirtualSearchableList,
  },
  setup() {
    const pivotPLOC = inject(PIVOT_PLOC_KEY) as PivotPLOC;
    let pivotState = manageSubscription(pivotPLOC);
    let internalState = ref({ redrawList: Date.now() + "" });

    const closeModal = () => {
      pivotPLOC.onFiltersClose();
    };

    const getSaveString = () => {
      return SAVE;
    };
    const getDiscardString = () => {
      return DISCARD;
    };

    const saveOptions = async () => {
      pivotPLOC.onFiltersSave();
    };

    const getSelectPh = () => {
      return "Seleziona un valore";
    };

    const getFilterDescription = (filter: IFilter) => {
      return pivotPLOC.getFilterDescription(filter);
    };

    const createNewFilter = () => {
      pivotPLOC.createNewFilter();
    };

    const getFilterableDims = () => {
      return pivotPLOC.getFilterableDims();
    };

    const getDimensionDescription = (dimAlias: string) => {
      return pivotPLOC.getDimensionDescription(dimAlias);
    };

    const setFilterEntity = (entity: string) => {
      pivotPLOC.changeFilterOnEditEntity(entity);
    };

    const exitEditMode = () => {
      pivotPLOC.exitEditFilterMode();
    };

    const toggleFilterSelectedValue = (event: Event) => {
      const checkboxEl = event.target;
      const valueId = (checkboxEl as HTMLElement).id.split("_")[1];
      pivotPLOC.toggleFilterSelectedValue(valueId);
    };

    const getListFilterValue = (index: number) => {
      return pivotPLOC.getListFilterValue(index);
    };

    const subtitleVisible = () => {
      return getFilterableDims().length > 0 || pivotState.value.filtersEditing.editFilterMode;
    };

    const isValuesListFilter = () => {
      return pivotState.value.filtersEditing.filterOnEdit.entityAlias !== DATE_DIM_CODE;
    };

    const getTemporalFilterValue = () => {
      return pivotPLOC.getTemporalFilterValue() || { start: new Date(), end: new Date() };
    };

    const onStartFilterValueChange = (date: Date) => {
      pivotPLOC.setTemporalFilterValue(date, true);
    };

    const onEndFilterValueChange = (date: Date) => {
      pivotPLOC.setTemporalFilterValue(date, false);
    };

    const editFilter = (entity: string) => {
      pivotPLOC.editFilter(entity);
    };

    const removeFilter = (entity: string) => {
      pivotPLOC.removeFilter(entity);
    };

    const isNewFilter = () => {
      return pivotPLOC.isNewFilter();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getListItemTextFn = (item: any) => {
      return item.description;
    };

    const selectAllFilterValues = () => {
      pivotPLOC.selectAllFilterValues();
      internalState.value.redrawList = Date.now() + "";
    };

    const selectNoneFilterValues = () => {
      pivotPLOC.selectNoneFilterValues();
      internalState.value.redrawList = Date.now() + "";
    };

    const selectOneFilterValue = (event: Event) => {
      const onlyEl = event.target as HTMLElement;
      const valueId = (onlyEl as HTMLElement).id.split("_")[1];
      pivotPLOC.selectOneFilterValue(valueId);
      internalState.value.redrawList = Date.now() + "";
    };

    return {
      getSaveString,
      getDiscardString,
      closeModal,
      saveOptions,
      pivotState,
      getSelectPh,
      getFilterDescription,
      createNewFilter,
      getFilterableDims,
      getDimensionDescription,
      setFilterEntity,
      exitEditMode,
      getListFilterValue,
      toggleFilterSelectedValue,
      subtitleVisible,
      isValuesListFilter,
      getTemporalFilterValue,
      onEndFilterValueChange,
      onStartFilterValueChange,
      editFilter,
      isNewFilter,
      removeFilter,
      getListItemTextFn,
      selectAllFilterValues,
      selectNoneFilterValues,
      internalState,
      selectOneFilterValue,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

/* Modal Content */

#pivotFilters {
  .modal-content {
    @media only screen and (min-width: 550px) {
      height: 600px;
    }
  }
}

.pivot-filters-modal {
  .modal-content {
    h3 {
      font-weight: 500;
    }

    .modal-body.filters-modal-body {
      justify-content: flex-start;

      .filter-subtitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h3 {
          display: inline;
          font-size: 1rem;
        }

        .btn-new-filter {
          padding: 0rem 0.6rem;
          border-color: #ffc70e;
        }

        .breadcrumb {
          cursor: pointer;
          &:hover {
            color: $selected-color;
          }
        }
      }

      .filter-list {
        display: flex;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        flex-direction: column;

        .filter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 35px;
          padding: 5px;

          &:hover {
            background: $gray-100;
          }

          .filter-left {
            display: flex;
            align-items: center;
            .filter-icon {
              border-radius: 2px;
              margin-right: 5px;
              height: 10px;
              width: 10px;
              background: rgb(255, 199, 14);
            }
          }

          .filter-right {
            span {
              margin-right: 5px;
              font-size: 16px;
              cursor: pointer;
              color: $gray-600;
              &:hover {
                color: $color-primary;
              }
            }
          }
        }
      }

      .edit-filter {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        .filter-on {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
        }

        .filter-values-container {
          border: 1px solid $gray-300;
          display: flex;
          height: 400px;

          @media only screen and (min-width: 550px) {
            height: 300px;
          }

          .macro-part {
            padding: 0px 5px 5px 5px;
          }
        }

        .date-filter-values {
          margin-top: 20px;
          .date-filter-value {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            .app-date-picker {
              width: 200px;
            }
          }
        }
      }
    }
  }
}
</style>
