<template>
  <div class="modal-confirm">
    <!-- Modal content -->
    <div class="modal-content">
      <div class="modal-close" @click="onBtnDenyClick">
        <close-outlined></close-outlined>
      </div>
      <div class="modal-header">
        <h4 class="modal-title">{{ title }}</h4>
      </div>
      <div class="modal-body">
        <span>{{ body }}</span>
      </div>
      <div class="modal-footer">
        <button v-show="showBtnOk" @click="onBtnOkClick" class="btn btn-outline btn-outline-confirm">Si</button>
        <button v-show="showBtnDeny" @click="onBtnDenyClick" class="btn btn-outline btn-outline-remove">No</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { CloseOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { CloseOutlined },
  props: {
    title: String,
    body: String,
    showBtnOk: Boolean,
    showBtnDeny: Boolean,
    btnClickFn: Function,
  },
  setup(props) {
    const onBtnOkClick = () => {
      if (props && props.btnClickFn) props.btnClickFn(true);
    };
    const onBtnDenyClick = () => {
      if (props && props.btnClickFn) props.btnClickFn(false);
    };
    return { onBtnOkClick, onBtnDenyClick };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

.modal-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-size: 0.8rem;

  @media only screen and (min-width: 550px) {
    background-color: rgba(0, 0, 0, 0.4);
    font-size: 0.9rem;
  }

  .modal-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    background: white;

    @media only screen and (min-width: 550px) {
      border-radius: 7px;
      height: auto;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      .modal-title {
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .modal-close {
      font-size: 1rem;
      cursor: pointer;
    }
    .modal-body {
      border: none;
    }

    .modal-footer {
      border-top: 0px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
