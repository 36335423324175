type ResizeListener = (windowSize: number) => void;

export default class WindowResize {
  private static instance: WindowResize;
  private listeners: Map<string, ResizeListener>;

  private constructor() {
    this.listeners = new Map<string, ResizeListener>();
  }

  public static getInstance() {
    if (!WindowResize.instance) {
      WindowResize.instance = new WindowResize();
    }

    return WindowResize.instance;
  }

  public addListener(id: string, listener: ResizeListener) {
    this.listeners.set(id, listener);
  }

  private onWindowResize() {
    const size = window.innerWidth;
    this.listeners.forEach((listener) => {
      window.requestAnimationFrame(() => {
        listener(size);
      });
    });
  }

  public setup() {
    window.addEventListener("resize", this.onWindowResize.bind(this));
  }
}
