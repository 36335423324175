import UsersRepository from "../UsersRepository";
import DeleteUsersUseCase from "./DeleteUsersUseCase";
import FormatUserUseCase from "./FormatUserUseCase";

export default class UsersListOperationsUseCase {
  private static selectedUsers: Set<string> = new Set<string>();

  public static getActiveUserByIndex(usersRepository: UsersRepository, index: number) {
    const usersIds = usersRepository.getActiveUsersIds();
    if (index < usersIds.length) {
      const user = usersRepository.getUserById(usersIds[index]);
      return {
        id: user.id,
        description: FormatUserUseCase.doFormat(usersRepository, user.id),
        selected: UsersListOperationsUseCase.selectedUsers.has(user.id),
      };
    } else {
      console.error("UsersListOperationsUseCase/getActiveUserByIndex: trying to access non existing user index");
      return {
        id: "not_found_" + index,
        descriprion: "Utente " + index + " non trovato",
        selected: false,
      };
    }
  }

  public static toggleUserSelection(userId: string) {
    if (UsersListOperationsUseCase.selectedUsers.has(userId)) UsersListOperationsUseCase.selectedUsers.delete(userId);
    else UsersListOperationsUseCase.selectedUsers.add(userId);

    return UsersListOperationsUseCase.selectedUsers.size;
  }

  public static async deleteUsers(usersRepository: UsersRepository) {
    const users = Array.from(UsersListOperationsUseCase.selectedUsers);

    const result = await DeleteUsersUseCase.deleteUsers(users, usersRepository);
    if (result.success) {
      UsersListOperationsUseCase.selectedUsers.clear();
    }
    return { ...result };
  }

  public static async deleteSingleUser(userId: string, usersRepository: UsersRepository) {
    let newSelectedUsersCount = UsersListOperationsUseCase.selectedUsers.size;
    const result = await DeleteUsersUseCase.deleteSingleUser(userId, usersRepository);
    if (result.success) {
      if (UsersListOperationsUseCase.selectedUsers.has(userId)) {
        UsersListOperationsUseCase.selectedUsers.delete(userId);
        newSelectedUsersCount--;
      }
    }
    return { ...result, newSelectedUsersCount };
  }

  public static selectAllUsers(usersRepository: UsersRepository) {
    const allUsers = usersRepository.getActiveUsersIds();
    UsersListOperationsUseCase.selectedUsers = new Set(allUsers);
    return allUsers.length;
  }

  public static deselectAllUsers() {
    UsersListOperationsUseCase.selectedUsers.clear();
    return 0;
  }
}
