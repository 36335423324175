import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "signin-wrapper" }
const _hoisted_2 = { class: "card-signin" }
const _hoisted_3 = { class: "signin-header" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo = _resolveComponent("logo")!
  const _component_Recaptcha = _resolveComponent("Recaptcha")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_logo),
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("h2", null, "Benvenuto!", -1)),
        _cache[6] || (_cache[6] = _createElementVNode("h4", null, "Esegui il login per continuare", -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("label", null, "Email", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control-large",
            placeholder: "Inserisci la tua mail",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginState.mail) = $event))
          }, null, 512), [
            [_vModelText, _ctx.loginState.mail]
          ]),
          _withDirectives(_createElementVNode("div", { class: "error" }, _toDisplayString(_ctx.getInvalidMailMessage()), 513), [
            [_vShow, _ctx.loginState.loginTried && !_ctx.mailOk]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[4] || (_cache[4] = _createElementVNode("label", null, "Password", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            class: "form-control-large",
            placeholder: "Inserisci la tua password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginState.password) = $event))
          }, null, 512), [
            [_vModelText, _ctx.loginState.password]
          ])
        ]),
        _withDirectives(_createElementVNode("div", { class: "error" }, _toDisplayString(_ctx.loginState.loginErrorMsg), 513), [
          [_vShow, _ctx.loginState.loginTried && !_ctx.loginState.loginOk]
        ]),
        _createElementVNode("input", {
          type: "button",
          value: "Login",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.doLogin())),
          class: "btn btn-secondary"
        }),
        _createVNode(_component_Recaptcha, { ref: "recaptcha" }, null, 512)
      ])
    ])
  ]))
}