import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "users-list-container section-content" }
const _hoisted_2 = { class: "list-section-macro-part" }
const _hoisted_3 = { class: "fixed-part" }
const _hoisted_4 = { class: "macro-btns" }
const _hoisted_5 = { class: "fixed-right-part" }
const _hoisted_6 = { class: "list-len-container" }
const _hoisted_7 = { class: "list-len" }
const _hoisted_8 = { class: "selection-part" }
const _hoisted_9 = { class: "selection-number" }
const _hoisted_10 = { class: "user-container" }
const _hoisted_11 = { class: "left-part" }
const _hoisted_12 = ["for"]
const _hoisted_13 = ["id", "onUpdate:modelValue", "onClick"]
const _hoisted_14 = { class: "user" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "user-actions" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "status-icon-container" }
const _hoisted_20 = { class: "error-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_virtual_searchable_list = _resolveComponent("virtual-searchable-list")!
  const _component_exclamation_circle_outlined = _resolveComponent("exclamation-circle-outlined")!
  const _component_user_detail = _resolveComponent("user-detail")!
  const _component_modal_confirm = _resolveComponent("modal-confirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_component_virtual_searchable_list, {
        "full-list-count": _ctx.usersState.activeUsersCount,
        "get-list-item-fn": _ctx.getActiveUserByIndex,
        "get-list-item-text-fn": _ctx.getListItemTextFn,
        key: _ctx.usersState.redrawList,
        "item-height": _ctx.itemHeight.heightValue,
        onFiteredValuesLength: _ctx.onSearchChange
      }, {
        customButtons: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "list-macro-btn",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectAllUsers && _ctx.selectAllUsers(...args)))
                }, "Seleziona tutto"),
                _createElementVNode("button", {
                  class: "list-macro-btn",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.deselectAllUsers && _ctx.deselectAllUsers(...args)))
                }, "Deseleziona tutto")
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.usersState.visibleUsersCount), 1),
                  _cache[4] || (_cache[4] = _createTextVNode(" elementi "))
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "separator" }, "|", -1)),
                _createElementVNode("i", {
                  class: "plus-icon",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.createNewUser && _ctx.createNewUser(...args)))
                }, [
                  _createVNode(_component_plus_outlined)
                ])
              ])
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.usersState.selectedUsersCount), 1),
                _cache[6] || (_cache[6] = _createElementVNode("span", null, "elementi selezionati", -1))
              ]),
              _createElementVNode("span", null, [
                _createElementVNode("button", {
                  class: "list-macro-btn delete-selected",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.tryDeleteUser && _ctx.tryDeleteUser(...args)))
                }, [
                  _createVNode(_component_delete_outlined)
                ])
              ])
            ], 512), [
              [_vShow, _ctx.usersState.selectedUsersCount > 0]
            ])
          ])
        ]),
        searchedItem: _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", {
                class: "custom-checkbox",
                for: 'input_' + item.id
              }, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  id: 'input_' + item.id,
                  "onUpdate:modelValue": ($event: any) => ((item.selected) = $event),
                  onClick: ($event: any) => (_ctx.toggleUserSelection(item.id))
                }, null, 8, _hoisted_13), [
                  [_vModelCheckbox, item.selected]
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("span", { class: "checkmark" }, null, -1))
              ], 8, _hoisted_12),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_UserAvatar, {
                  userName: _ctx.getUserName(item.id),
                  userSurname: _ctx.getUserSurname(item.id),
                  color: '#6ad1d9',
                  clickable: true,
                  show: true,
                  dimension: 'md',
                  "show-status": false,
                  onClick: ($event: any) => (_ctx.editUser(item.id))
                }, null, 8, ["userName", "userSurname", "onClick"]),
                _createElementVNode("span", {
                  class: "user-desc",
                  onClick: ($event: any) => (_ctx.editUser(item.id))
                }, _toDisplayString(item.description), 9, _hoisted_15)
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("span", {
                class: "user-action",
                onClick: ($event: any) => (_ctx.editUser(item.id))
              }, [
                _createVNode(_component_edit_outlined)
              ], 8, _hoisted_17),
              _withDirectives(_createElementVNode("span", {
                class: "user-action",
                onClick: ($event: any) => (_ctx.deleteUser(item.id))
              }, [
                _createVNode(_component_delete_outlined)
              ], 8, _hoisted_18), [
                [_vShow, _ctx.canDeleteUser(item.id)]
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["full-list-count", "get-list-item-fn", "get-list-item-text-fn", "item-height", "onFiteredValuesLength"]))
    ], 512), [
      [_vShow, !_ctx.usersState.error]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_exclamation_circle_outlined)
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("span", null, "Errore durante il caricamento degli utenti, consultare i log per dettagli", -1))
    ], 512), [
      [_vShow, _ctx.usersState.error]
    ]),
    _withDirectives(_createVNode(_component_user_detail, null, null, 512), [
      [_vShow, _ctx.usersState.userDetail.visible]
    ]),
    _withDirectives(_createVNode(_component_modal_confirm, {
      title: 'Sei sicuro di voler procedere con l\'eliminazione?',
      body: 'L\'operazione è irreversibile.',
      "show-btn-deny": true,
      showBtnOk: true,
      btnClickFn: _ctx.onDeleteUserConfirmClose
    }, null, 8, ["btnClickFn"]), [
      [_vShow, _ctx.usersState.userDeleteConfirmVisible]
    ])
  ], 64))
}