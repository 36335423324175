import { PLOC } from "@/core/common/presentation/PLOC";
import { NotificationsState, notificationsInitialState } from "./NotificationsState";
import NotificationsManager from "../domain/NotificationsManager";
import { NotificationDS, NotificationType } from "../domain/NotificationDS";
import NewNotificationArrivalUseCase from "../domain/usecases/NewNotificationArrivalUseCase";
import CloseNotificationUseCase from "../domain/usecases/CloseNotificationUseCase";

export default class NotificationsPLOC extends PLOC<NotificationsState> {
  private notificationsManager: NotificationsManager = NotificationsManager.getInstance();
  constructor() {
    super(notificationsInitialState);
  }

  public onNewNotificationArrival(newNotification: NotificationDS): void {
    const result = NewNotificationArrivalUseCase.onNewNotificationArrival(
      newNotification,
      this.notificationsManager,
      this.state.visibleNotifications,
      this.state.maxVisibleNotifications
    );
    if (result.visibleNotificationsChanged) {
      if (newNotification.type === NotificationType.Ok)
        setTimeout(
          () => this.onNotificationClose(newNotification.id),
          6000 + 1000 * (result.newVisibleNotifications.length - 1)
        );
      this.changeState({
        ...this.state,
        visibleNotifications: result.newVisibleNotifications,
      });
    }
  }

  public onNotificationClose(id: string) {
    const result = CloseNotificationUseCase.onNotificationClose(
      id,
      this.notificationsManager,
      this.state.visibleNotifications,
      this.state.maxVisibleNotifications
    );
    if (result.nextNotificationAdded)
      if (result.nextNotificationType === NotificationType.Ok)
        setTimeout(
          () => this.onNotificationClose(result.nextNotificationId),
          6000 + 1000 * (result.newVisibleNotifications.length - 1)
        );
    this.changeState({ ...this.state, visibleNotifications: result.newVisibleNotifications });
  }
}
