<template>
  <div class="modal performance-modal">
    <div class="modal-content">
      <div class="modal-close">
        <close-outlined @click="discardChanges"></close-outlined>
      </div>
      <div class="modal-header">
        <div class="title-icon"><scissor-outlined></scissor-outlined></div>
        <h3>{{ performancesState.performanceDetail.title }}</h3>
      </div>
      <div class="modal-body">
        <div class="modal-field">
          <label class="modal-field-label" for="namperformance_name">Nome: </label>
          <div class="modal-field-value">
            <input
              placeholder="nome"
              class="txt-input"
              type="text"
              id="performance_name"
              name="performance_name"
              v-model="performancesState.performanceDetail.name"
              @input="validateName"
            />
            <div
              class="error"
              v-show="performancesState.performanceDetail.saving && !performancesState.performanceDetail.nameOk"
            >
              Campo obbligatorio e al massimo di 20 caratteri
            </div>
          </div>
        </div>

        <div class="modal-field">
          <label class="modal-field-label" for="duration">Durata: </label>
          <div class="modal-field-value">
            <input
              type="number"
              v-model="performancesState.performanceDetail.duration"
              class="form-control-thin txt-input"
              @change="validateDuration"
            />
            <div
              class="error"
              v-show="performancesState.performanceDetail.saving && !performancesState.performanceDetail.durationOk"
            >
              Campo obbligatorio e >= 0
            </div>
          </div>
        </div>

        <div class="modal-field">
          <label class="modal-field-label" for="cost">Costo(€): </label>
          <div class="modal-field-value">
            <input
              type="number"
              v-model="performancesState.performanceDetail.cost"
              class="form-control-thin txt-input"
              @change="validateCost"
            />
            <div
              class="error"
              v-show="performancesState.performanceDetail.saving && !performancesState.performanceDetail.costOk"
            >
              Campo obbligatorio e >= 0
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline btn-outline-confirm" @click="savePerformance">Salva</button>
        <button class="btn btn-outline btn-outline-remove" @click="tryDeletePerformance">Elimina</button>
        <button class="btn btn-outline btn-outline-discard" @click="discardChanges">Annulla</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { CloseOutlined, ScissorOutlined } from "@ant-design/icons-vue";
import { PERFORMANCES_PLOC_KEY } from "@/components/common/GUIConsts";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import PerformancesPLOC from "@/core/performances/presentation/PerformancesPLOC";
export default defineComponent({
  components: { CloseOutlined, ScissorOutlined },
  props: {},
  setup() {
    const performancesPLOC = inject(PERFORMANCES_PLOC_KEY) as PerformancesPLOC;
    const performancesState = manageSubscription(performancesPLOC);

    const savePerformance = async () => {
      await performancesPLOC.savePerformance();
    };

    const validateName = () => {
      performancesPLOC.validateName();
    };
    const validateDuration = () => {
      performancesPLOC.validateDuration();
    };

    const validateCost = () => {
      performancesPLOC.validateCost();
    };

    const discardChanges = () => {
      performancesPLOC.discardChanges();
    };

    const tryDeletePerformance = () => {
      performancesPLOC.tryDeletePerformance();
    };

    return {
      performancesState,
      savePerformance,
      validateName,
      discardChanges,
      tryDeletePerformance,
      validateDuration,
      validateCost,
    };
  },
});
</script>
<style lang="scss">
@import "./public/styles/variables.scss";

.performance-modal {
  .modal-body {
    align-items: center;
  }
}
</style>
