<template>
  <div class="search-bar">
    <input type="text" placeholder="Cerca ..." v-model="state.textToSearch" @keyup.enter="onSearchBarChange" />
    <div class="icon-part">
      <span class="close-icon" @click="onDiscardSearchClick" v-show="state.textToSearch !== ''"
        ><clear-outlined></clear-outlined
      ></span>
      <span class="separator">|</span>
      <span class="search-icon" @click="onSearchBarChange"><search-outlined></search-outlined></span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: { SearchOutlined, ClearOutlined },
  props: { onChangeFn: Function },
  setup(props) {
    let state = ref({ textToSearch: "" });

    const onSearchBarChange = () => {
      if (props.onChangeFn) props.onChangeFn(state.value.textToSearch);
    };
    const onDiscardSearchClick = () => {
      state.value.textToSearch = "";
      onSearchBarChange();
    };

    return { state, onSearchBarChange, onDiscardSearchClick };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

.search-bar {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid $gray-300;
  border-radius: 24px;
  margin: 5px;

  input {
    border: none;
    width: 100%;
    justify-self: flex-start;

    &:focus {
      border: none;
      outline: none;
    }
  }
  .icon-part {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    font-size: 18px;

    .separator {
      margin-right: 5px;
      margin-left: 5px;
      color: $gray-400;
    }
    .search-icon {
      color: $selected-color;
      cursor: pointer;
    }
    .close-icon {
      color: $gray-700;
      cursor: pointer;
    }
  }
}
</style>
