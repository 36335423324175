import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "modal pivot-modal pivot-entities-modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-close" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "title-icon" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "entity-panels" }
const _hoisted_8 = { class: "panels-group" }
const _hoisted_9 = { class: "entity-panel" }
const _hoisted_10 = ["id", "list-id", "onDragstart"]
const _hoisted_11 = { class: "entity-panel" }
const _hoisted_12 = ["id", "list-id", "onDragstart"]
const _hoisted_13 = { class: "drag-icons" }
const _hoisted_14 = { class: "panels-group" }
const _hoisted_15 = { class: "entity-panel" }
const _hoisted_16 = ["id", "list-id", "onDragstart"]
const _hoisted_17 = { class: "entity-panel" }
const _hoisted_18 = ["id", "list-id", "onDragstart"]
const _hoisted_19 = { class: "entity-panel" }
const _hoisted_20 = ["id", "list-id", "onDragstart"]
const _hoisted_21 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_deployment_unit_outlined = _resolveComponent("deployment-unit-outlined")!
  const _component_drag_outlined = _resolveComponent("drag-outlined")!
  const _component_swap_outlined = _resolveComponent("swap-outlined")!
  const _component_modal_confirm = _resolveComponent("modal-confirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_close_outlined, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_deployment_unit_outlined)
          ]),
          _cache[28] || (_cache[28] = _createElementVNode("h3", null, "Gestione Entitá", -1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "panel-header" }, "Dimensioni disponibili", -1)),
                _createElementVNode("div", {
                  class: "panel-body available-dims",
                  onDrop: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDropEntity($event, _ctx.getAvailableDimensionsListId()))),
                  onDragenter: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
                  onDragover: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.onDragOver($event, _ctx.getAvailableDimensionsListId())), ["prevent"]))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pivotState.availableDimensions, (dimension) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: dimension.alias,
                      id: dimension.alias,
                      "list-id": _ctx.getAvailableDimensionsListId(),
                      class: "panel-item",
                      draggable: "true",
                      onDragstart: ($event: any) => (_ctx.startDragEntity($event, dimension.alias, _ctx.getAvailableDimensionsListId())),
                      onTouchend: _cache[1] || (_cache[1] = ($event: any) => (_ctx.doubleTap.tap($event, _ctx.onEntityDoubleClick))),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.doubleTap.tap($event, _ctx.onEntityDoubleClick)))
                    }, [
                      _cache[29] || (_cache[29] = _createElementVNode("div", { class: "entity-icon dimension" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(dimension.desc), 1)
                    ], 40, _hoisted_10))
                  }), 128))
                ], 32)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[32] || (_cache[32] = _createElementVNode("div", { class: "panel-header" }, "Misure disponibili", -1)),
                _createElementVNode("div", {
                  class: "panel-body",
                  onDrop: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onDropEntity($event, _ctx.getAvailableMeasuresListId()))),
                  onDragover: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.onDragOver($event, _ctx.getAvailableMeasuresListId())), ["prevent"])),
                  onDragenter: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["prevent"]))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pivotState.availableMeasures, (measure) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: measure.alias,
                      id: measure.alias,
                      "list-id": _ctx.getAvailableMeasuresListId(),
                      draggable: "true",
                      class: "panel-item",
                      onDragstart: ($event: any) => (_ctx.startDragEntity($event, measure.alias, _ctx.getAvailableMeasuresListId())),
                      onTouchend: _cache[6] || (_cache[6] = ($event: any) => (_ctx.doubleTap.tap($event, _ctx.onEntityDoubleClick))),
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.doubleTap.tap($event, _ctx.onEntityDoubleClick)))
                    }, [
                      _cache[31] || (_cache[31] = _createElementVNode("div", { class: "entity-icon measure" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(measure.desc), 1)
                    ], 40, _hoisted_12))
                  }), 128))
                ], 32)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_drag_outlined),
              _createVNode(_component_swap_outlined)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _cache[34] || (_cache[34] = _createElementVNode("div", { class: "panel-header" }, "Dimensioni selezionate in riga", -1)),
                _createElementVNode("div", {
                  class: "panel-body",
                  onDrop: _cache[13] || (_cache[13] = ($event: any) => (_ctx.onDropEntity($event, _ctx.getRowsListId()))),
                  onDragover: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.onDragOver($event, _ctx.getRowsListId())), ["prevent"])),
                  onDragenter: _cache[15] || (_cache[15] = _withModifiers(() => {}, ["prevent"]))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pivotState.optsWorkingCopy.rows, (selectedDim) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: selectedDim.alias,
                      id: selectedDim.alias,
                      "list-id": _ctx.getRowsListId(),
                      class: "panel-item selected",
                      draggable: "true",
                      onDragstart: ($event: any) => (_ctx.startDragEntity($event, selectedDim.alias, _ctx.getRowsListId())),
                      onTouchend: _cache[11] || (_cache[11] = ($event: any) => (_ctx.doubleTap.tap($event, _ctx.onEntityDoubleClick))),
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.doubleTap.tap($event, _ctx.onEntityDoubleClick)))
                    }, [
                      _cache[33] || (_cache[33] = _createElementVNode("div", { class: "entity-icon dimension" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(selectedDim.desc), 1)
                    ], 40, _hoisted_16))
                  }), 128))
                ], 32)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _cache[36] || (_cache[36] = _createElementVNode("div", { class: "panel-header" }, "Dimensioni selezionate in colonna", -1)),
                _createElementVNode("div", {
                  class: "panel-body",
                  onDrop: _cache[16] || (_cache[16] = ($event: any) => (_ctx.onDropEntity($event, _ctx.getColumnsListId()))),
                  onDragover: _cache[17] || (_cache[17] = _withModifiers(($event: any) => (_ctx.onDragOver($event, _ctx.getColumnsListId())), ["prevent"])),
                  onDragenter: _cache[18] || (_cache[18] = _withModifiers(() => {}, ["prevent"])),
                  onTouchend: _cache[19] || (_cache[19] = ($event: any) => (_ctx.doubleTap.tap($event, _ctx.onEntityDoubleClick))),
                  onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.doubleTap.tap($event, _ctx.onEntityDoubleClick)))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pivotState.optsWorkingCopy.columns, (selectedDim) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: selectedDim.alias,
                      id: selectedDim.alias,
                      "list-id": _ctx.getColumnsListId(),
                      class: "panel-item selected",
                      draggable: "true",
                      onDragstart: ($event: any) => (_ctx.startDragEntity($event, selectedDim.alias, _ctx.getColumnsListId()))
                    }, [
                      _cache[35] || (_cache[35] = _createElementVNode("div", { class: "entity-icon dimension" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(selectedDim.desc), 1)
                    ], 40, _hoisted_18))
                  }), 128))
                ], 32)
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[38] || (_cache[38] = _createElementVNode("div", { class: "panel-header" }, "Misure selezionate", -1)),
                _createElementVNode("div", {
                  class: "panel-body",
                  onDrop: _cache[21] || (_cache[21] = ($event: any) => (_ctx.onDropEntity($event, _ctx.getSelectedMeasuresListId()))),
                  onDragover: _cache[22] || (_cache[22] = _withModifiers(($event: any) => (_ctx.onDragOver($event, _ctx.getSelectedMeasuresListId())), ["prevent"])),
                  onDragenter: _cache[23] || (_cache[23] = _withModifiers(() => {}, ["prevent"])),
                  onTouchend: _cache[24] || (_cache[24] = ($event: any) => (_ctx.doubleTap.tap($event, _ctx.onEntityDoubleClick))),
                  onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.doubleTap.tap($event, _ctx.onEntityDoubleClick)))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pivotState.optsWorkingCopy.measures, (selectedMeas) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: selectedMeas.alias,
                      id: selectedMeas.alias,
                      "list-id": _ctx.getSelectedMeasuresListId(),
                      class: "panel-item selected",
                      draggable: "true",
                      onDragstart: ($event: any) => (_ctx.startDragEntity($event, selectedMeas.alias, _ctx.getSelectedMeasuresListId()))
                    }, [
                      _cache[37] || (_cache[37] = _createElementVNode("div", { class: "entity-icon measure" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(selectedMeas.desc), 1)
                    ], 40, _hoisted_20))
                  }), 128))
                ], 32)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("button", {
            class: "btn btn-outline btn-outline-confirm",
            onClick: _cache[26] || (_cache[26] = 
//@ts-ignore
(...args) => (_ctx.saveOptions && _ctx.saveOptions(...args)))
          }, _toDisplayString(_ctx.getSaveString()), 1),
          _createElementVNode("button", {
            class: "btn btn-outline btn-outline-discard",
            onClick: _cache[27] || (_cache[27] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, _toDisplayString(_ctx.getDiscardString()), 1)
        ])
      ])
    ]),
    _withDirectives(_createVNode(_component_modal_confirm, {
      title: 'Configurazione errata',
      body: 'Selezionare almeno una dimensione in riga e almeno una misura',
      showBtnOk: true,
      btnClickFn: _ctx.closePivotEntitiesMsg
    }, null, 8, ["btnClickFn"]), [
      [_vShow, !_ctx.pivotState.entitiesOk && _ctx.pivotState.showPivotEntitiesMsg]
    ])
  ], 64))
}