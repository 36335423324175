import { LoadUsersResponse, UsersOperarionsResponse } from "./UsersOperationsResponse";
import { UserDS } from "./UserDS";

export default abstract class UsersRepository {
  public abstract getUserById(id: string): UserDS;
  public abstract loadUsers(): Promise<LoadUsersResponse>;
  public abstract getAllUsersIds(): Array<string>;
  public abstract getUsersMap(): Map<string, UserDS>;
  public abstract updateUser(user: UserDS): Promise<UsersOperarionsResponse>;
  public abstract saveUser(user: UserDS): Promise<UsersOperarionsResponse>;
  public abstract deleteUsers(ids: Array<string>): Promise<UsersOperarionsResponse>;
  public abstract getActiveUsersIds(): Array<string>;
}
