/* eslint-disable */
import {
  ALL_DAY_TEXT,
  DAY_VIEW_TITLE,
  FIVE_DAYS_VIEW_TITLE,
  FOUR_DAYS_VIEW_TITLE,
  LIST_WEEK_TITLE,
  MONTH_VIEW_TITLE,
  SIX_DAYS_VIEW_TITLE,
  THREE_DAYS_VIEW_TITLE,
  TODAY_BUTTON_TEXT,
  TWO_DAYS_VIEW_TITLE,
  WEEEK_VIEW_TITLE,
} from "@/core/common/utils/ResourceStrings";
import PerformancesRepository from "@/core/performances/domain/PerformancesRepository";
import UsersRepository from "@/core/users/domain/UsersRepository";
import CalendarOptions, {
  AvailableViews,
  IEditEventInfo,
  IEventDropInfo,
  ISelectInfo,
} from "../domain/CalendarOptions";
import { DAYGRID_TYPE, DAY_VIEW_ID, LIST_WEEK_TYPE, TIMEGRID_TYPE } from "./CalendarConsts";
import EventTooltipRenderer from "./EventTooltipRenderer";
import { formatDate, PluginDef } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import FullCalendarEvent from "./FullCalendarEvent";
import EventsRepository from "../domain/EventsRepository";
import { EventsLoadingResponse, TCalendarEventWithValidation } from "../domain/CalendarEventDS";
import FullCalendarController from "./FullCalendarController";
import EventContentRenderer from "./EventContentRenderer";
import { DateSelectionRule } from "../domain/DateSelectionRule";
import NewEventUseCase from "../domain/usecases/NewEventUseCase";
import UpdateEventExtremesUseCase from "../domain/usecases/UpdateEventExtremesUseCase";
import EditEventUseCase from "../domain/usecases/EditEventUseCase";
import NotificationsManager from "@/core/notifications/domain/NotificationsManager";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";
import CalendarEvent from "../domain/CalendarEvent";
import EventColorsApplier from "../domain/EventColorsApplier";

interface FullcalendarEventInfo {
  event: FullCalendarEvent;
  el: HTMLElement;
}

interface FullcalendarHookArg {
  timeText: string;
  event: FullCalendarEvent;
  view: { type: string };
}

interface DayHeaderContentFnArg {
  date: Date;
}

export default class CalendarOptionsImpl extends CalendarOptions {
  private usersRepository: UsersRepository;
  private performancesRepository: PerformancesRepository;
  private eventsRepository: EventsRepository;
  private eventPreLoadingCb: any;
  private eventPostLoadingCb: any;

  constructor(
    usersRepository: UsersRepository,
    performancesRepository: PerformancesRepository,
    eventsRepository: EventsRepository
  ) {
    super();
    this.usersRepository = usersRepository;
    this.performancesRepository = performancesRepository;
    this.eventsRepository = eventsRepository;
  }

  public getEditable(): boolean {
    return true;
  }
  public getEventMountFunction(): any {
    return (info: FullcalendarEventInfo) =>
      EventTooltipRenderer.renderEventTooltip(info.el, info.event, this.usersRepository, this.performancesRepository);
  }
  public getInitialView(): string {
    return DAY_VIEW_ID;
  }
  public getAllDayText(): string {
    return ALL_DAY_TEXT;
  }
  public getSelectable(): boolean {
    return true;
  }
  public getLocale(): string {
    return "it";
  }
  public getSlotDuration(): string {
    return "00:15:00";
  }
  public getSlotMinTime(): string {
    return "08:00:00";
  }
  public getSlotMaxTime(): string {
    return "19:00:00";
  }
  public getTodayButtonText(): string {
    return TODAY_BUTTON_TEXT;
  }
  public getDayHeaderContentFunction(): any {
    const result = (arg: DayHeaderContentFnArg) => {
      return {
        html:
          '<div class="day-header-weekday">' +
          formatDate(arg.date, { weekday: "short", locale: "it" }) +
          "</div>" +
          '<div class="day-header-number">' +
          formatDate(arg.date, { day: "numeric" }) +
          "</div>",
      };
    };
    return result;
  }
  public getHeaderToolbar(): object {
    return {
      left: "",
      center: "prevCustomButton,title,nextCustomButton,todayCustomButton",
      right: "",
    };
  }
  public getNavLinksEnabled(): boolean {
    return true;
  }
  public getNavLinkDayClickView(): string {
    return DAY_VIEW_ID;
  }
  public getSlotLabelFormat(): object {
    return {
      hour: "numeric",
      minute: "numeric",
      omitZeroMinute: true,
      meridiem: "short",
    };
  }
  public getNowIndicatorEnabled(): boolean {
    return true;
  }
  public getEnabledPlugins(): Array<any> {
    return [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin];
  }
  public getAvailableViews(): AvailableViews {
    return {
      agendaTwoDays: {
        title: TWO_DAYS_VIEW_TITLE,
        type: TIMEGRID_TYPE,
        duration: { days: 2 },
      },
      agendaThreeDays: {
        title: THREE_DAYS_VIEW_TITLE,
        type: TIMEGRID_TYPE,
        duration: { days: 3 },
      },
      agendaFourDays: {
        title: FOUR_DAYS_VIEW_TITLE,
        type: TIMEGRID_TYPE,
        duration: { days: 4 },
      },
      agendaFiveDays: {
        title: FIVE_DAYS_VIEW_TITLE,
        type: TIMEGRID_TYPE,
        duration: { days: 5 },
      },
      agendaSixDays: {
        title: SIX_DAYS_VIEW_TITLE,
        type: TIMEGRID_TYPE,
        duration: { days: 6 },
      },
      timeGridDay: {
        dayHeaders: false,
        title: DAY_VIEW_TITLE,
      },
      timeGridWeek: {
        title: WEEEK_VIEW_TITLE,
      },
      dayGridMonth: {
        title: MONTH_VIEW_TITLE,
        type: DAYGRID_TYPE,
      },
      listWeek: {
        title: LIST_WEEK_TITLE,
        type: LIST_WEEK_TYPE,
      },
    };
  }

  public getFirstDayOfWeek(): number {
    return 1;
  }

  public getDayNames(): Array<String> {
    return ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"];
  }

  public getCustomButtons(moveCallback: any, goToDayCallback: any): object {
    return {
      nextCustomButton: {
        class: "pippus",
        icon: "chevron-right",
        click: () => {
          moveCallback(1);
        },
      },
      prevCustomButton: {
        icon: "chevron-left",
        click: () => {
          moveCallback(-1);
        },
      },
      todayCustomButton: {
        text: "Torna a oggi",
        click: () => {
          goToDayCallback(new Date());
        },
      },
    };
  }

  public async loadEvents(): Promise<EventsLoadingResponse> {
    const notificationsManager = NotificationsManager.getInstance();
    let result = {
      events: new Array<FullCalendarEvent>(),
      success: false,
    };

    const loadEventsResponse = await this.eventsRepository.loadEvents();

    if (loadEventsResponse.success) {
      result.events = loadEventsResponse.events.map((serializedEvent) => {
        const appEvent = CalendarEvent.getInstance().deserialize(serializedEvent);
        EventColorsApplier.applyEventColors(appEvent, this.usersRepository);
        return FullCalendarController.appToFullcalendarEvent(appEvent);
      });
      notificationsManager.pushNewNotification("Caricamento degli eventi completato", "", NotificationType.Ok);
      result.success = true;
    } else {
      notificationsManager.pushNewNotification(
        "Caricamento degli eventi fallito",
        loadEventsResponse.errorMsg,
        NotificationType.Ko
      );
      console.error(loadEventsResponse.errorMsg);
    }

    return Promise.resolve(result);
  }

  public async loadEventsRange(startDate: Date, endDate: Date): Promise<EventsLoadingResponse> {
    const notificationsManager = NotificationsManager.getInstance();
    let result = {
      events: new Array<FullCalendarEvent>(),
      success: false,
    };
    const loadEventsResponse = await this.eventsRepository.loadEventsRange(startDate, endDate);

    if (loadEventsResponse.success) {
      result.events = loadEventsResponse.events.map((serializedEvent) => {
        const appEvent = CalendarEvent.getInstance().deserialize(serializedEvent);
        return FullCalendarController.appToFullcalendarEvent(appEvent);
      });
      result.success = true;
    } else {
      notificationsManager.pushNewNotification(
        "Caricamento degli eventi fallito",
        loadEventsResponse.errorMsg,
        NotificationType.Ko
      );
      console.error(loadEventsResponse.errorMsg);
    }

    return Promise.resolve(result);
  }

  public async eventsLoadingFn(fetchInfo: ISelectInfo, successCallback: any, failureCallback: any): Promise<any> {
    if (this.eventPreLoadingCb) await this.eventPreLoadingCb();

    const startDate = fetchInfo.start;
    const endDate = fetchInfo.end;
    const eventsInThisRange = await this.loadEventsRange(startDate, endDate);
    if (eventsInThisRange.success) {
      successCallback(eventsInThisRange.events);
    } else {
      failureCallback("Errore nel caricamento degli eventi");
    }

    if (this.eventPostLoadingCb) this.eventPostLoadingCb();
  }

  public getEventContentFunction(): any {
    return (arg: FullcalendarHookArg) => {
      return EventContentRenderer.renderEventContent(
        arg.timeText,
        arg.event,
        arg.view.type,
        this.usersRepository,
        this.performancesRepository
      );
    };
  }

  public applyDateSelectionRule(selectInfo: ISelectInfo): boolean {
    return DateSelectionRule.doEvaluate(selectInfo.start, selectInfo.end);
  }
  public onNewEvent(selectInfo: ISelectInfo): TCalendarEventWithValidation {
    return NewEventUseCase.setup(selectInfo.start, selectInfo.end);
  }
  public getEventDropFunction(): any {
    return async (dropInfo: IEventDropInfo) => {
      return await UpdateEventExtremesUseCase.doUpdateEventExtremes(dropInfo.event, this.eventsRepository);
    };
  }
  public onEditEvent(editEventInfo: IEditEventInfo): TCalendarEventWithValidation {
    const calendarEvent = FullCalendarController.fullcalendarToAppEvent(editEventInfo.event);
    return EditEventUseCase.setup(calendarEvent);
  }

  public getEventMouseEnterFunction(): any {
    return (info: FullcalendarEventInfo) => {
      return EventTooltipRenderer.showTooltip(info.el, info.event, this.usersRepository, this.performancesRepository);
    };
  }
  public getEventMouseLeaveFunction(): any {
    return (info: FullcalendarEventInfo) => {
      return EventTooltipRenderer.hideTooltip(info.event.id);
    };
  }

  public setEventPreLoadingCallback(preLoadingCb: any): void {
    this.eventPreLoadingCb = preLoadingCb;
  }
  public setEventPostLoadingCallback(postLoadingCb: any): void {
    this.eventPostLoadingCb = postLoadingCb;
  }
}
