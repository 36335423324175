import { NotificationDS } from "../NotificationDS";
import NotificationsManager from "../NotificationsManager";

export default class NewNotificationArrivalUseCase {
  public static onNewNotificationArrival(
    newNotification: NotificationDS,
    notificationsManager: NotificationsManager,
    visibleNotifications: Array<NotificationDS>,
    maxVisibleNotifications: number
  ) {
    const result = { newVisibleNotifications: new Array<NotificationDS>(), visibleNotificationsChanged: false };
    const allNotifications = notificationsManager.getAllNotifications();
    const thereIsSpaceForNewNotification = allNotifications.length <= maxVisibleNotifications;
    if (thereIsSpaceForNewNotification) {
      result.visibleNotificationsChanged = true;
      //aggiungo la nuova notifica in testa perche dopo nell' html viene montata come prima figlia dell' elemento lista di notifiche,
      // e quindi appare in cima alla lista di notifiche
      result.newVisibleNotifications = [newNotification, ...visibleNotifications];
    } else result.newVisibleNotifications = visibleNotifications;

    return result;
  }
}
