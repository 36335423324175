import UsersRepository from "@/core/users/domain/UsersRepository";
import FullCalendarEvent from "./FullCalendarEvent";
import { MONTH_VIEW_ID } from "./CalendarConsts";
import PerformancesRepository from "@/core/performances/domain/PerformancesRepository";

export default class EventContentRenderer {
  public static renderEventContent(
    timeText: string,
    fcEvent: FullCalendarEvent,
    viewType: string,
    usersRepository: UsersRepository,
    performancesRepository: PerformancesRepository
  ): object {
    const elements = [];
    if (viewType === MONTH_VIEW_ID) {
      const dotEl = document.createElement("div");
      dotEl.classList.add("fc-daygrid-event-dot");
      dotEl.style.borderColor = fcEvent.borderColor;
      elements.push(dotEl);
    }
    const timeTextEl = document.createElement("div");
    timeTextEl.classList.add("fc-event-time");
    timeTextEl.innerHTML = timeText;
    elements.push(timeTextEl);

    const titleContainerEl = document.createElement("div");
    titleContainerEl.classList.add("fc-event-title-container");
    const titleEl = document.createElement("div");
    titleEl.classList.add("fc-event-title");
    titleEl.classList.add("fc-sticky");
    titleEl.innerHTML = performancesRepository.getPerformanceById(fcEvent.extendedProps.performance).name;
    titleContainerEl.appendChild(titleEl);
    elements.push(titleContainerEl);

    const descriptionEl = document.createElement("div");
    descriptionEl.classList.add("event-description");
    const customer = usersRepository.getUserById(fcEvent.extendedProps.customer);
    descriptionEl.innerHTML = `${customer.name} ${customer.surname}`;
    elements.push(descriptionEl);

    return { domNodes: elements };
  }
}
