<template>
  <div class="analysis-container">
    <pivot></pivot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Pivot from "./pivot/Pivot.vue";

export default defineComponent({
  components: { Pivot },
  setup() {
    return {};
  },
});
</script>

<style lang="scss">
@import "../../../../public/styles/variables.scss";

.pivot-modal {
}
</style>
