export default class DoubleTap {
  private static doubletapDeltaTime = 700;
  private tapTimerResetId: number;

  constructor() {
    this.tapTimerResetId = -1;
  }

  public tap(event: Event, callbackFn: (a: Event) => void) {
    if (this.tapTimerResetId === -1) {
      // First tap, we wait X ms to the second tap
      this.tapTimerResetId = setTimeout(this.tapTimerReset.bind(this), DoubleTap.doubletapDeltaTime);
    } else {
      // Second tap
      clearTimeout(this.tapTimerResetId);
      this.tapTimerResetId = -1;
      callbackFn(event);
    }
  }

  tapTimerReset() {
    // Wait for second tap timeout
    this.tapTimerResetId = -1;
  }
}
