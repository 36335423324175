import { NotificationType } from "@/core/notifications/domain/NotificationDS";
import PerformancesRepository from "../PerformancesRepository";
import NotificationsManager from "@/core/notifications/domain/NotificationsManager";

export default class DeletePerformancesUseCase {
  public static async deletePerformances(users: Array<string>, performancesRepository: PerformancesRepository) {
    const notificationsManager = NotificationsManager.getInstance();

    const result = await performancesRepository.deletePerformances(users);
    const newActivePerformancesCount = performancesRepository.getActivePerformancesIds().length;

    if (result.success)
      notificationsManager.pushNewNotification("Rimozione avvenuta con successo", "", NotificationType.Ok);
    else notificationsManager.pushNewNotification("Rimozione fallita", result.errorMsg, NotificationType.Ko);

    return { ...result, newActivePerformancesCount };
  }

  public static async deleteSinglePeformance(performanceId: string, performancesRepository: PerformancesRepository) {
    const performances = new Array<string>();
    performances.push(performanceId);
    return await DeletePerformancesUseCase.deletePerformances(performances, performancesRepository);
  }
}
