import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.outerClassObj)
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.userAvatarStyleObj),
      class: _normalizeClass([_ctx.userAvatarClassObj, "user-avatar"])
    }, _toDisplayString(_ctx.initials), 7)
  ], 2))
}