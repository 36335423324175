<template>
  <div class="modal pivot-modal pivot-ordering-modal">
    <div class="modal-content">
      <div class="modal-close">
        <close-outlined @click="closeModal()"></close-outlined>
      </div>
      <div class="modal-header">
        <div class="title-icon"><swap-outlined :rotate="90"></swap-outlined></div>
        <h3>Ordinamento</h3>
      </div>
      <div class="modal-body">
        <div class="entity-ordering">
          <h5>Dimensione</h5>
          <h5>Ordina per</h5>
          <h5>Verso di ordinamento</h5>
        </div>
        <div class="entity-ordering" v-for="selectedDim in getSelectedDimensions()" :key="selectedDim.alias">
          <div class="entity-name">{{ selectedDim.desc }}</div>
          <div class="order-by">
            <Select
              :placeholder="getSelectPh()"
              :showLabels="false"
              :width="110"
              :options="[...getSelectedMeasures(), selectedDim.alias]"
              :selectedSingle="selectedDim.orderBy"
              :customLabelFn="formatOrderBy"
              :searchable="false"
              :selectKey="selectedDim.alias"
              :changeFn="setEntityOrderBy"
            >
            </Select>
          </div>
          <div class="order-type">
            <div class="switch-field">
              <input
                type="radio"
                :id="'radio-asc-' + selectedDim.alias"
                :name="'radio-' + selectedDim.alias"
                value="asc"
                v-model="selectedDim.orderType"
              />
              <label :for="'radio-asc-' + selectedDim.alias"
                ><span class="order-label-text">asc</span><sort-ascending-outlined
              /></label>
              <input
                type="radio"
                :id="'radio-desc-' + selectedDim.alias"
                :name="'radio-' + selectedDim.alias"
                value="desc"
                v-model="selectedDim.orderType"
              />
              <label :for="'radio-desc-' + selectedDim.alias"
                ><span class="order-label-text">disc</span><sort-descending-outlined
              /></label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline btn-outline-confirm" @click="saveOptions">
          {{ getSaveString() }}
        </button>

        <button class="btn btn-outline btn-outline-discard" @click="closeModal">
          {{ getDiscardString() }}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { CloseOutlined, SwapOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons-vue";
import { DISCARD, SAVE } from "@/core/common/utils/ResourceStrings";
import { PIVOT_PLOC_KEY } from "@/components/common/GUIConsts";
import PivotPLOC from "@/core/pivot/presentation/PivotPLOC";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import Select from "../../controls/Select.vue";
import { ORDERING_MODAL_ID } from "@/core/pivot/common/PivotConsts";

export default defineComponent({
  components: { CloseOutlined, SwapOutlined, SortAscendingOutlined, SortDescendingOutlined, Select },
  setup() {
    const pivotPLOC = inject(PIVOT_PLOC_KEY) as PivotPLOC;
    let pivotState = manageSubscription(pivotPLOC);

    const closeModal = () => {
      pivotPLOC.closeOptionsModal();
    };

    const getSaveString = () => {
      return SAVE;
    };
    const getDiscardString = () => {
      return DISCARD;
    };

    const saveOptions = async () => {
      pivotPLOC.saveOptions(ORDERING_MODAL_ID);
    };

    const formatOrderBy = (orderById: string) => {
      return pivotPLOC.formatOrderBy(orderById);
    };

    const setEntityOrderBy = (value: string, alias: string) => {
      pivotPLOC.setEntityOrderBy(value, alias);
    };

    const getSelectPh = () => {
      return "Seleziona un valore";
    };

    const getSelectedDimensions = () => {
      return pivotPLOC.getWorkingSelectedDimensions();
    };

    const getSelectedMeasures = () => {
      return pivotPLOC.getSelectedMeasuresIds();
    };

    return {
      getSaveString,
      getDiscardString,
      closeModal,
      saveOptions,
      pivotState,
      formatOrderBy,
      setEntityOrderBy,
      getSelectPh,
      getSelectedDimensions,
      getSelectedMeasures,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

/* Modal Content */

.pivot-ordering-modal {
  .modal-content {
    .modal-body {
      .entity-ordering {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-bottom: 25px;

        .order-by,
        .order-type,
        h5 {
          display: flex;
          width: 100%;
          justify-content: center;

          .order-label-text {
            display: none;

            @media only screen and (min-width: 550px) {
              display: inline;
            }
          }
        }

        .entity-name {
          display: flex;
          width: 100%;
          justify-content: flex-start;
        }
      }
    }
  }
}
</style>
