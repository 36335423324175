import { NEW_EVENT } from "@/core/common/utils/ResourceStrings";
import { EventAction, TCalendarEventWithValidation } from "../CalendarEventDS";
import { v4 as uuidv4 } from "uuid";
import Calendar from "../Calendar";
import EventsRepository from "../EventsRepository";
import EventValidationUseCase from "./EventValidationUseCase";
import UsersRepository from "@/core/users/domain/UsersRepository";
import EventColorsApplier from "../EventColorsApplier";
import NotificationsManager from "@/core/notifications/domain/NotificationsManager";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";

export default class NewEventUseCase {
  public static setup(start: Date, end: Date): TCalendarEventWithValidation {
    const newEvent = {
      id: uuidv4(),
      start,
      end,
      allDay: false,
      employees: [],
      customer: "",
      cost: 0,
      title: NEW_EVENT,
      visible: true,
      borderColor: "",
      backgroundColor: "",
      performance: "",
      timeExtremesOk: true,
      performanceOk: true,
      customerOk: true,
      employeesOk: true,
      costOk: true,
      toSave: false,
      isNew: true,
    };

    return newEvent;
  }

  public static async tearDown(
    action: EventAction,
    calendar: Calendar,
    eventsRepo: EventsRepository,
    usersRepo: UsersRepository,
    event: TCalendarEventWithValidation
  ) {
    const notificationsManager = NotificationsManager.getInstance();
    const result = {
      success: false,
      event: {} as TCalendarEventWithValidation,
      errorMsg: "",
    };
    switch (action) {
      case EventAction.Save:
        // eslint-disable-next-line no-case-declarations
        const validationResult = EventValidationUseCase.validateEvent(event);
        if (validationResult.canSave) {
          EventColorsApplier.applyEventColors(event, usersRepo);

          const repoSaveOk = await eventsRepo.saveEvent(event);

          if (repoSaveOk.success) calendar.addEvent(event);
          else result.errorMsg = repoSaveOk.errorMsg;

          result.success = repoSaveOk.success;

          if (result.success)
            notificationsManager.pushNewNotification("Evento creato con successo", "", NotificationType.Ok);
          else
            notificationsManager.pushNewNotification(
              "Creazione dell'evento fallita",
              result.errorMsg,
              NotificationType.Ko
            );
        } else result.event = validationResult.validatedEvent;

        break;
      case EventAction.Delete:
        break;
      case EventAction.DiscardChanges:
        break;
    }

    return result;
  }
}
