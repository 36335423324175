import { IGetIndexedDataResponse, ILoadPivotOptionsResponse, ISaveOptionsResponse } from "./PivotDataDS";
import { IPivotOptions, TFilterValues } from "./PivotOptionsDS";

export default abstract class PivotData {
  public abstract getIndexedPivotData(opts: IPivotOptions): Promise<IGetIndexedDataResponse>;
  public abstract loadOptions(): Promise<ILoadPivotOptionsResponse>;
  public abstract saveOptions(opts: IPivotOptions): Promise<ISaveOptionsResponse>;
  public abstract getFilterSelectedValues(dimAlias: string): TFilterValues;
  public abstract saveFiltersValues(filterDimToSelectedValues: Map<string, TFilterValues>): void;
}
