/* eslint-disable */

import { EventsLoadingResponse, TCalendarEventWithValidation } from "./CalendarEventDS";

export interface ISelectInfo {
  start: Date;
  end: Date;
}

export interface IEventDropInfo {
  event: {
    id: string;
    start: Date;
    end: Date;
  };
}

export interface IEditEventInfo {
  event: any;
}

export interface AvailableViews {
  [id: string]:
    | {
        title: string;
        type: string;
        duration: { days: number };
      }
    | { dayHeaders: boolean; title: string }
    | { title: string };
}

export default abstract class CalendarOptions {
  public abstract getEditable(): boolean;
  public abstract getEventMountFunction(): any;
  public abstract getInitialView(): string;
  public abstract getAllDayText(): string;
  public abstract getSelectable(): boolean;
  public abstract getLocale(): string;
  public abstract getSlotDuration(): string;
  public abstract getSlotMinTime(): string;
  public abstract getSlotMaxTime(): string;
  public abstract getTodayButtonText(): string;
  public abstract getDayHeaderContentFunction(): any;
  public abstract getHeaderToolbar(): object;
  public abstract getNavLinksEnabled(): boolean;
  public abstract getNavLinkDayClickView(): string;
  public abstract getSlotLabelFormat(): object;
  public abstract getNowIndicatorEnabled(): boolean;
  public abstract getEnabledPlugins(): Array<any>;
  public abstract getAvailableViews(): AvailableViews;
  public abstract getFirstDayOfWeek(): number;
  public abstract getDayNames(): Array<String>;
  public abstract getCustomButtons(moveCallback: any, goToDayCallback: any): object;
  public abstract loadEventsRange(startDate: Date, endDate: Date): Promise<EventsLoadingResponse>;
  public abstract loadEvents(): Promise<EventsLoadingResponse>;
  public abstract getEventContentFunction(): any;
  public abstract applyDateSelectionRule(selectInfo: ISelectInfo): boolean;
  public abstract onNewEvent(selectInfo: ISelectInfo): TCalendarEventWithValidation;
  public abstract getEventDropFunction(): any;
  public abstract onEditEvent(editEventInfo: IEditEventInfo): TCalendarEventWithValidation;
  public abstract getEventMouseEnterFunction(): any;
  public abstract getEventMouseLeaveFunction(): any;
  public abstract eventsLoadingFn(fetchInfo: ISelectInfo, successCallback: any, failureCallback: any): Promise<any>;
  public abstract setEventPreLoadingCallback(preLoadingCb: any): void;
  public abstract setEventPostLoadingCallback(postLoadingCb: any): void;
}
