<template>
  <VueDatePicker
    class="app-time-picker"
    v-model="state"
    time-picker
    :max-time="maxTime"
    :min-time="minTime"
    :auto-apply="true"
    :clearable="false"
    :minutes-increment="15"
    :minutes-grid-increment="15"
    :hide-input-icon="false"
    :input-class-name="'form-control-thin'"
    @update:model-value="onChange"
  >
    <template #input-icon>
      <img class="input-slot-image" src="icons/clock-icon-ant-design.png" />
    </template>
  </VueDatePicker>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
export interface ITimePickerModel {
  hours: number;
  minutes: number;
}
export default defineComponent({
  components: { VueDatePicker },
  props: {
    minTime: Object,
    maxTime: Object,
    date: Date,
    changeFn: Function,
  },
  setup(props) {
    let state = ref({
      hours: new Date().getHours(),
      minutes: new Date().getMinutes(),
    });

    if (props.date) {
      state = ref({
        hours: props.date.getHours(),
        minutes: props.date.getMinutes(),
      });
    }

    const onChange = (newModel: ITimePickerModel) => {
      if (props.changeFn) props.changeFn(newModel);
    };

    watch(
      () => props.date,
      (newDate) => {
        if (newDate) {
          state.value.hours = newDate.getHours();
          state.value.minutes = newDate.getMinutes();
        }
      }
    );
    return {
      state,
      onChange,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

.dp__main.app-time-picker {
  height: 100%;
  display: flex;
  align-items: center;

  .input-slot-image {
    height: 16px;
    width: 16px;
    margin-left: 5px;
    margin-top: 3px;
  }

  .dp__input_wrap {
    input {
      padding-right: 0px;
      padding-left: 25px;
    }
  }
  .dp__button {
    background: $gray-100;
    .dp__icon {
      display: none;
    }
    &:after {
      content: "Torna indietro";
      text-align: center;
      color: $color-primary;
    }
  }

  .form-control-thin {
    width: 70px;
  }
}
</style>
