import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "performances-list-container section-content" }
const _hoisted_2 = { class: "list-section-macro-part" }
const _hoisted_3 = { class: "fixed-part" }
const _hoisted_4 = { class: "macro-btns" }
const _hoisted_5 = { class: "fixed-right-part" }
const _hoisted_6 = { class: "list-len-container" }
const _hoisted_7 = { class: "list-len" }
const _hoisted_8 = { class: "selection-part" }
const _hoisted_9 = { class: "selection-number" }
const _hoisted_10 = { class: "performance-container" }
const _hoisted_11 = { class: "left-part" }
const _hoisted_12 = ["for"]
const _hoisted_13 = ["id", "onUpdate:modelValue", "onClick"]
const _hoisted_14 = { class: "performance" }
const _hoisted_15 = { class: "performance-icon" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "performance-actions" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "status-icon-container" }
const _hoisted_21 = { class: "error-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_scissor_outlined = _resolveComponent("scissor-outlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_virtual_searchable_list = _resolveComponent("virtual-searchable-list")!
  const _component_exclamation_circle_outlined = _resolveComponent("exclamation-circle-outlined")!
  const _component_performance_detail = _resolveComponent("performance-detail")!
  const _component_modal_confirm = _resolveComponent("modal-confirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_component_virtual_searchable_list, {
        "full-list-count": _ctx.performancesState.activePerformancesCount,
        "get-list-item-fn": _ctx.getActivePerformanceByIndex,
        "get-list-item-text-fn": _ctx.getListItemTextFn,
        key: _ctx.performancesState.redrawList,
        "item-height": 42,
        onFiteredValuesLength: _ctx.onSearchChange
      }, {
        customButtons: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "list-macro-btn",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectAllPerformances && _ctx.selectAllPerformances(...args)))
                }, "Seleziona tutto"),
                _createElementVNode("button", {
                  class: "list-macro-btn",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.deselectAllPerformances && _ctx.deselectAllPerformances(...args)))
                }, "Deseleziona tutto")
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.performancesState.visiblePerformancesCount), 1),
                  _cache[4] || (_cache[4] = _createTextVNode(" elementi "))
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "separator" }, "|", -1)),
                _createElementVNode("i", {
                  class: "plus-icon",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.createNewPerformance && _ctx.createNewPerformance(...args)))
                }, [
                  _createVNode(_component_plus_outlined)
                ])
              ])
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.performancesState.selectedPerformancesCount), 1),
                _cache[6] || (_cache[6] = _createElementVNode("span", null, "elementi selezionati", -1))
              ]),
              _createElementVNode("span", null, [
                _createElementVNode("button", {
                  class: "list-macro-btn delete-selected",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.tryDeletePerformance && _ctx.tryDeletePerformance(...args)))
                }, [
                  _createVNode(_component_delete_outlined)
                ])
              ])
            ], 512), [
              [_vShow, _ctx.performancesState.selectedPerformancesCount > 0]
            ])
          ])
        ]),
        searchedItem: _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", {
                class: "custom-checkbox",
                for: 'input_' + item.id
              }, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  id: 'input_' + item.id,
                  "onUpdate:modelValue": ($event: any) => ((item.selected) = $event),
                  onClick: ($event: any) => (_ctx.togglePerformanceSelection(item.id))
                }, null, 8, _hoisted_13), [
                  [_vModelCheckbox, item.selected]
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("span", { class: "checkmark" }, null, -1))
              ], 8, _hoisted_12),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("span", _hoisted_15, [
                  _createVNode(_component_scissor_outlined)
                ]),
                _createElementVNode("span", {
                  class: "performance-desc",
                  onClick: ($event: any) => (_ctx.editPerformance(item.id))
                }, _toDisplayString(item.description), 9, _hoisted_16)
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("span", {
                class: "performance-action",
                onClick: ($event: any) => (_ctx.editPerformance(item.id))
              }, [
                _createVNode(_component_edit_outlined)
              ], 8, _hoisted_18),
              _createElementVNode("span", {
                class: "performance-action",
                onClick: ($event: any) => (_ctx.deletePerformance(item.id))
              }, [
                _createVNode(_component_delete_outlined)
              ], 8, _hoisted_19)
            ])
          ])
        ]),
        _: 1
      }, 8, ["full-list-count", "get-list-item-fn", "get-list-item-text-fn", "onFiteredValuesLength"]))
    ], 512), [
      [_vShow, !_ctx.performancesState.error]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_20, [
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_exclamation_circle_outlined)
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("span", null, "Errore durante il caricamento delle prestazioni, consultare i log per dettagli", -1))
    ], 512), [
      [_vShow, _ctx.performancesState.error]
    ]),
    _withDirectives(_createVNode(_component_performance_detail, null, null, 512), [
      [_vShow, _ctx.performancesState.performanceDetail.visible]
    ]),
    _withDirectives(_createVNode(_component_modal_confirm, {
      title: 'Sei sicuro di voler procedere con l\'eliminazione?',
      body: 'L\'operazione è irreversibile.',
      "show-btn-deny": true,
      showBtnOk: true,
      btnClickFn: _ctx.onDeletePerformanceConfirmClose
    }, null, 8, ["btnClickFn"]), [
      [_vShow, _ctx.performancesState.performanceDeleteConfirmVisible]
    ])
  ], 64))
}