import { TPerformanceWithValidation } from "../domain/PerformanceDS";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PerformancesState {
  activePerformancesCount: number;
  redrawList: number;
  selectedPerformancesCount: number;
  performanceDetail: TPerformanceWithValidation;
  visiblePerformancesCount: number;
  performanceDeleteConfirmVisible: boolean;
  listPerformanceClicked: string;
  error: boolean;
}

export const performancesInitialState = {
  activePerformancesCount: 0,
  visiblePerformancesCount: 0,
  redrawList: Date.now(),
  selectedPerformancesCount: 0,
  performanceDeleteConfirmVisible: false,
  listPerformanceClicked: "",
  error: false,
  performanceDetail: {
    id: "",
    name: "",
    duration: 0,
    cost: 0,
    deleted: false,
    visible: false,
    title: "",
    nameOk: false,
    durationOk: false,
    isNew: false,
    costOk: false,
    saving: false,
  },
};
