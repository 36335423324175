<template>
  <div class="page-header" @click="closeLoggedUserMenu()">
    <h3
      :class="{
        'page-header-title': true,
        'calendar-filtered': appState.currentPage === getCalendarPageId() && calendarState.filters.isFilterActive,
      }"
    >
      {{ getCurrentPageTitle(appState.currentPage) }}
    </h3>
    <div class="page-header-right">
      <div v-if="appState.currentPage === getCalendarPageId()" class="app-select-container">
        <Select
          :showLabels="false"
          :width="120"
          :multiple="false"
          :options="calendarState.selectableViews"
          :customLabelFn="getViewTitleFromId"
          :searchable="false"
          :changeFn="changeCalendarView"
          :initialSelected="calendarState.currrentView"
          :selectedSingle="calendarState.currentView"
        />
      </div>
      <div class="page-header-account">
        <UserAvatar
          :userName="getLoggedUser(loginState.loggedUserId).name"
          :userSurname="getLoggedUser(loginState.loggedUserId).surname"
          :color="getLoggedUser(loginState.loggedUserId).color"
          :clickable="true"
          :show="true"
          :dimension="'md'"
          @click.stop="toggleLoggedUserMenuActive()"
        ></UserAvatar>
        <div class="dropdown-menu" v-show="appState.loggedUserMenuActive" v-on:click.stop>
          <close-outlined class="user-menu-exit" @click.stop="closeLoggedUserMenu()" />
          <div class="user-header-profile">
            <UserAvatar
              :userName="getLoggedUser(loginState.loggedUserId).name"
              :userSurname="getLoggedUser(loginState.loggedUserId).surname"
              :color="getLoggedUser(loginState.loggedUserId).color"
              :clickable="false"
              :dimension="'lg'"
            ></UserAvatar>
            <h6>
              {{ getLoggedUser(loginState.loggedUserId).name + " " + getLoggedUser(loginState.loggedUserId).surname }}
            </h6>
            <span>Impiegato</span>
          </div>

          <a class="dropdown-item" @click="editLoggedUser"
            ><profile-outlined class="user-menu-item-icon" /> Impostazioni profilo</a
          >
          <a class="dropdown-item" v-on:click="doLogout"><logout-outlined class="user-menu-item-icon" /> Logout</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ProfileOutlined, LogoutOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { APP_PLOC_KEY, CALENDAR_PLOC_KEY, LOGIN_PLOC_KEY, USERS_PLOC_KEY } from "@/components/common/GUIConsts";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import { AppPLOC } from "@/core/app-navigation/presentation/AppPLOC";
import { UserDS } from "@/core/users/domain/UserDS";
import { defineComponent, inject, onMounted } from "vue";
import UserAvatar from "../controls/UserAvatar.vue";
import { LoginPLOC } from "@/core/login/presentation/LoginPLOC";
import { CalendarPLOC } from "@/core/calendar/presentation/CalendarPLOC";
import { CALENDAR_PAGE_ID } from "@/core/app-navigation/domain/PagesConsts";
import Select from "../controls/Select.vue";
import UsersPLOC from "@/core/users/presentation/UsersPLOC";
export default defineComponent({
  components: {
    UserAvatar,
    ProfileOutlined,
    LogoutOutlined,
    CloseOutlined,
    Select,
  },
  setup() {
    const appPLOC = inject(APP_PLOC_KEY) as AppPLOC;
    const appState = manageSubscription(appPLOC);

    const calendarPLOC = inject(CALENDAR_PLOC_KEY) as CalendarPLOC;
    const calendarState = manageSubscription(calendarPLOC);

    const loginPLOC = inject(LOGIN_PLOC_KEY) as LoginPLOC;
    const loginState = manageSubscription(loginPLOC);

    const usersPLOC = inject(USERS_PLOC_KEY) as UsersPLOC;

    const changeCalendarView = (newViewId: string) => {
      calendarPLOC.changeCalendarView(newViewId);
    };

    const editLoggedUser = () => {
      appPLOC.toggleLoggedUserMenuActive();
      usersPLOC.editLoggedUser(loginState.value.loggedUserId);
    };

    onMounted(() => {
      calendarPLOC.loadSelectableViews();
    });

    const getCurrentPageTitle = (currentPage: string) => {
      return appPLOC.getCurrentPageTitle(currentPage);
    };

    const getLoggedUser = (userId: string): UserDS => {
      return usersPLOC.getUserById(userId);
    };

    const toggleLoggedUserMenuActive = () => {
      appPLOC.toggleLoggedUserMenuActive();
    };

    const closeLoggedUserMenu = () => {
      appPLOC.closeLoggedUserMenu();
    };

    const doLogout = async () => {
      await loginPLOC.doLogout();
      appPLOC.onLogout(!loginState.value.loginOk);
    };

    const getCalendarPageId = () => {
      return CALENDAR_PAGE_ID;
    };

    const getViewTitleFromId = (id: string) => {
      return calendarPLOC.getAvailableViewsObj()[id].title;
    };

    const getCalendarPLOCKey = () => {
      return CALENDAR_PLOC_KEY;
    };

    return {
      appState,
      loginState,
      calendarState,
      getCurrentPageTitle,
      getLoggedUser,
      toggleLoggedUserMenuActive,
      closeLoggedUserMenu,
      doLogout,
      changeCalendarView,
      getCalendarPageId,
      getViewTitleFromId,
      getCalendarPLOCKey,
      editLoggedUser,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";
$icon-font-size: 20px;
.page-header {
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: $page-header-height-mobile;
  border-bottom: 1px solid $gray-300;

  .page-header-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .app-select-container {
      margin-right: 10px;

      .multiselect {
        min-height: 0px;

        @media only screen and (min-width: 600px) {
          min-height: 40px;
        }

        .multiselect__tags {
          height: 25px;
          min-height: 0px;
          padding-top: 3px;

          @media only screen and (min-width: 600px) {
            min-height: 40px;
            padding-top: 8px;
          }

          .multiselect__single {
            line-height: 0px;

            @media only screen and (min-width: 500px) {
              line-height: 26px;
            }
          }
        }

        .multiselect__select {
          height: 28px;

          @media only screen and (min-width: 600px) {
            height: 38px;
          }
        }
      }
    }
  }

  .user-menu-item-icon {
    font-size: $icon-font-size;
    margin-right: 5px;
  }

  .user-menu-exit {
    align-self: flex-end;
    cursor: pointer;
  }

  .page-header-title {
    margin-left: 20px;
    font-weight: 500;
    color: $color-primary;
  }

  .calendar-filtered {
    color: rgb(255, 208, 0);
  }

  .page-header-account {
    margin-right: 20px;
    position: relative;
  }

  .dropdown-menu {
    padding-top: 20px;
    position: absolute;
    top: 60px;
    left: auto;
    right: -5px;
    bottom: auto;
    width: 230px;
    border: 1px solid $gray-300;
    background-color: #fff;
    padding: 15px 20px 5px;
    margin-top: 0;
    z-index: 900;
    border-radius: 0;
    display: flex;
    flex-direction: column;

    .dropdown-item {
      position: relative;
      padding: 0;
      height: 38px;
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #596882;
      cursor: pointer;

      & + .dropdown-item {
        border-top: 1px dotted #b4bdce;
      }

      i {
        margin-right: 10px;
      }
    }

    .dropdown-item:active {
      background-color: $gray-300;
    }
    .user-header-profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;

      h6 {
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 0;
      }
      span {
        font-size: 13px;
      }
    }
  }

  & > .user-avatar-show {
    &::before {
      content: "";
      position: absolute;
      bottom: -17px;
      left: 50%;
      margin-left: -7px;
      width: 14px;
      height: 14px;
      border: 2px solid transparent;
      border-top-color: #cdd4e0;
      border-left-color: #cdd4e0;
      transform: rotate(45deg);
      background-color: #fff;
      z-index: 901;
    }
  }
}

@media only screen and (min-width: 992px) {
  .app-select-container {
    select {
      padding: 10px;
    }
  }
}
@media only screen and (min-width: 600px) {
  .page-header {
    height: $page-header-height-desktop;
  }
}
</style>
