export class User {
  private userId: string;
  private mail: string;

  constructor(mail: string, userId: string) {
    this.mail = mail;
    this.userId = userId;
  }

  public static checkMail(mail: string) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail);
  }
}
