<template>
  <div class="list-container">
    <div class="list-header" ref="listHeader">
      <div class="search-bar-container">
        <search-bar :onChangeFn="onSearchBarChange"></search-bar>
      </div>
      <div class="macro-part">
        <slot name="customButtons" />
      </div>
    </div>
    <virtual-scrolling-list
      :viewportHeightStyle="viewportHeightStyle"
      :allItemsCount="state.filteredValuesCount"
      :itemHeight="itemHeight"
      :key="state.redrawList"
    >
      <template #item="{ itemIndex }"> <slot name="searchedItem" v-bind="{ item: getListItem(itemIndex) }" /></template
    ></virtual-scrolling-list>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import SearchBar from "../SearchBar.vue";
import VirtualScrollingList from "./VirtualScrollingList.vue";

export default defineComponent({
  components: { VirtualScrollingList, SearchBar },
  emits: ["fiteredValuesLength"],
  props: {
    fullListCount: Number,
    getListItemFn: Function,
    getListItemTextFn: Function,
    itemHeight: Number,
    viewportHeightStyle: String,
  },
  setup(props, { emit }) {
    const listHeader = ref<HTMLElement>();
    let state = ref({
      filteredValuesCount: props.fullListCount,
      redrawList: Date.now(),
      headerHeight: "0px",
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let filteredList = new Array<any>();

    if (props.fullListCount && props.getListItemFn) {
      for (let i = 0; i < props.fullListCount; i++) {
        const itemObj = props.getListItemFn(i);
        filteredList.push(itemObj);
      }
    }

    const getListItemText = (index: number) => {
      if (props.getListItemTextFn) return props.getListItemTextFn(filteredList[index]);
    };

    const onSearchBarChange = (textToSearch: string) => {
      if (props.fullListCount && props.getListItemFn && props.getListItemTextFn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filteredList = new Array<any>();
        for (let i = 0; i < props.fullListCount; i++) {
          const itemObj = props.getListItemFn(i);
          const itemText = props.getListItemTextFn(itemObj);
          if (itemText.toLowerCase().includes(textToSearch.toLowerCase())) filteredList.push(itemObj);
        }

        state.value.filteredValuesCount = filteredList.length;
        state.value.redrawList = Date.now();
        emit("fiteredValuesLength", textToSearch === "" ? -1 : state.value.filteredValuesCount);
      }
    };

    const getListItem = (index: number) => {
      return filteredList[index];
    };

    onMounted(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (entries[0].contentRect.width > 0 && entries[0].contentRect.height > 0) {
            state.value.headerHeight = Math.floor(entries[0].contentRect.height) + "px";
          }
        });
      });

      if (listHeader.value) resizeObserver.observe(listHeader.value);
    });

    return { state, listHeader, onSearchBarChange, getListItemText, getListItem };
  },
});
</script>
<style lang="scss">
@import "./public/styles/variables.scss";
.list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .list-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .search-bar-container {
      width: 100%;
      margin: 0px 5px 0px 5px;
    }

    .macro-part {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }
  }
}
</style>
