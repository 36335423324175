import { PLOC } from "@/core/common/presentation/PLOC";
import UsersRepository from "../domain/UsersRepository";
import { UsersState, usersInitialState } from "./UsersState";
import GetFilteredUsersUseCase from "../domain/usecases/GetFilteredUsersUseCase";
import FormatUserUseCase from "../domain/usecases/FormatUserUseCase";
import { UserDS } from "../domain/UserDS";
import UsersListOperationsUseCase from "../domain/usecases/UsersListOperationsUseCase";
import UserDetailUseCase from "../domain/usecases/UserDetailUseCase";
import DeleteUsersUseCase from "../domain/usecases/DeleteUsersUseCase";

export default class UsersPLOC extends PLOC<UsersState> {
  private usersRepository: UsersRepository;

  constructor(usersRepository: UsersRepository) {
    super(usersInitialState);
    this.usersRepository = usersRepository;
  }

  public getAllCustomersIds() {
    const result = GetFilteredUsersUseCase.getActiveCustomersIds(this.usersRepository);
    return result;
  }

  public async loadUsers() {
    const result = await this.usersRepository.loadUsers();
    return result;
  }

  public async loadActiveUsers() {
    const result = await this.usersRepository.loadUsers();

    if (result.success) {
      const activeUsers = this.usersRepository.getActiveUsersIds();
      this.changeState({
        ...this.state,
        activeUsersCount: activeUsers.length,
        redrawList: Date.now(),
        visibleUsersCount: activeUsers.length,
      });
    } else {
      this.changeState({ ...this.state, error: true });
      console.error("UsersPLOC/loadActiveUsers: error cannot load active users " + result.errorMsg);
    }
  }

  public visibleUsersChange(newValue: number) {
    this.changeState({
      ...this.state,
      visibleUsersCount: newValue >= 0 ? newValue : this.state.activeUsersCount,
    });
  }

  public getUserById(userId: string) {
    return this.usersRepository.getUserById(userId);
  }

  public getActiveUserByIndex(index: number) {
    return UsersListOperationsUseCase.getActiveUserByIndex(this.usersRepository, index);
  }

  public toggleUserSelection(userId: string) {
    const selectedUsersCount = UsersListOperationsUseCase.toggleUserSelection(userId);
    this.changeState({ ...this.state, selectedUsersCount });
  }

  public formatUser(userId: string) {
    return FormatUserUseCase.doFormat(this.usersRepository, userId);
  }

  public getAllEmployeesIds() {
    const result = GetFilteredUsersUseCase.getAllEmployeesIds(this.usersRepository);
    return result;
  }

  public getActiveCustomersIds() {
    const result = GetFilteredUsersUseCase.getActiveCustomersIds(this.usersRepository);
    return result;
  }

  public getActiveEmployeesIds() {
    const result = GetFilteredUsersUseCase.getActiveEmployeesIds(this.usersRepository);
    return result;
  }

  public getUsersMap() {
    return this.usersRepository.getUsersMap();
  }

  public async updateUser(user: UserDS) {
    return await this.usersRepository.updateUser(user);
  }

  public createNewUser() {
    this.changeState({
      ...this.state,
      userDeleteConfirmVisible: false,
      userDetail: {
        ...this.state.userDetail,
        visible: true,
        title: "Nuovo utente",
        saving: false,
        ...UserDetailUseCase.createNewUser(),
      },
    });
  }

  public changeUserType(newUserType: string) {
    this.changeState({
      ...this.state,
      userDetail: {
        ...this.state.userDetail,
        ...UserDetailUseCase.changeUserType(this.state.userDetail, newUserType),
      },
    });
    if (this.state.userDetail.saving)
      this.changeState({
        ...this.state,
        userDetail: {
          ...this.state.userDetail,
          ...UserDetailUseCase.validateUser(this.state.userDetail, this.usersRepository),
        },
      });
  }

  public async saveUser() {
    const userValidation = UserDetailUseCase.validateUser(this.state.userDetail, this.usersRepository);
    let visible = true;
    let redrawList = this.state.redrawList;
    let activeUsersCount = this.state.activeUsersCount;
    if (userValidation.canSave) {
      const saveResult = await UserDetailUseCase.saveUser(this.state.userDetail, this.usersRepository);

      if (saveResult.success) {
        redrawList = Date.now();
        if (this.state.userDetail.isNew) activeUsersCount++;
        visible = false;
      } else {
        visible = true;
      }
    }

    this.changeState({
      ...this.state,
      redrawList,
      activeUsersCount,
      visibleUsersCount: activeUsersCount,
      loggedUserDetailVisible: this.state.loggedUserDetailVisible ? visible : this.state.loggedUserDetailVisible,
      userDetail: {
        ...this.state.userDetail,
        ...userValidation,
        visible,
      },
    });
  }

  public validateName() {
    this.changeState({
      ...this.state,
      userDetail: {
        ...this.state.userDetail,
        nameOk: UserDetailUseCase.validateName(this.state.userDetail.name),
      },
    });
  }

  public validateSurname() {
    this.changeState({
      ...this.state,
      userDetail: {
        ...this.state.userDetail,
        surnameOk: UserDetailUseCase.validateSurname(this.state.userDetail.surname),
      },
    });
  }

  public validateColor() {
    this.changeState({
      ...this.state,
      userDetail: {
        ...this.state.userDetail,
        colorOk: UserDetailUseCase.validateColor(this.state.userDetail, this.usersRepository),
      },
    });
  }

  public validatePwd() {
    this.changeState({
      ...this.state,
      userDetail: {
        ...this.state.userDetail,
        pwdOk: UserDetailUseCase.validatePwd(this.state.userDetail),
      },
    });
  }

  public validateMail() {
    this.changeState({
      ...this.state,
      userDetail: {
        ...this.state.userDetail,
        mailOk: UserDetailUseCase.validateMail(this.state.userDetail, this.usersRepository),
      },
    });
  }

  public discardChanges() {
    this.changeState({
      ...this.state,
      loggedUserDetailVisible: false,
      userDetail: {
        ...this.state.userDetail,
        ...UserDetailUseCase.discardChanges(),
      },
    });
  }

  public editUser(userId: string) {
    this.changeState({
      ...this.state,
      userDeleteConfirmVisible: false,
      userDetail: {
        ...UserDetailUseCase.editUser(userId, this.usersRepository, true),
        title: "Modifica utente",
      },
    });
  }

  public editLoggedUser(userId: string) {
    this.changeState({
      ...this.state,
      loggedUserDetailVisible: true,
      userDeleteConfirmVisible: false,
      userDetail: {
        ...UserDetailUseCase.editUser(userId, this.usersRepository, false),
        title: "Modifica utente",
      },
    });
  }

  public resetPwd() {
    this.changeState({
      ...this.state,
      userDetail: {
        ...this.state.userDetail,
        ...UserDetailUseCase.resetPwd(this.state.userDetail),
      },
    });
  }

  public tryDeleteUser() {
    this.changeState({ ...this.state, userDeleteConfirmVisible: true });
  }

  public async deleteUsers() {
    let deleteResult;
    let selectedUsersCount = this.state.selectedUsersCount;

    if (this.state.userDetail.visible)
      deleteResult = await DeleteUsersUseCase.deleteSingleUser(this.state.userDetail.id, this.usersRepository);
    else if (this.state.listUserClicked) {
      deleteResult = await UsersListOperationsUseCase.deleteSingleUser(
        this.state.listUserClicked,
        this.usersRepository
      );
      selectedUsersCount = deleteResult.newSelectedUsersCount;
    } else {
      deleteResult = await UsersListOperationsUseCase.deleteUsers(this.usersRepository);
      selectedUsersCount = 0;
    }

    if (deleteResult.success)
      this.changeState({
        ...this.state,
        selectedUsersCount,
        userDeleteConfirmVisible: false,
        activeUsersCount: deleteResult.newActiveUsersCount,
        visibleUsersCount: deleteResult.newActiveUsersCount,
        listUserClicked: "",
        redrawList: Date.now(),
        userDetail: { ...this.state.userDetail, visible: false },
      });
  }

  public closeUserDeleteConfirm() {
    this.changeState({ ...this.state, userDeleteConfirmVisible: false });
  }

  public canDeleteCurrentUser(loggedUserId: string) {
    return UserDetailUseCase.canDeleteCurrentUser(this.state.userDetail, loggedUserId);
  }

  public canDeleteUser(userId: string, loggedUserId: string) {
    return UserDetailUseCase.canDeleteUser(userId, loggedUserId);
  }

  public selectAllUsers() {
    const selectedUsersCount = UsersListOperationsUseCase.selectAllUsers(this.usersRepository);
    this.changeState({
      ...this.state,
      redrawList: Date.now(),
      selectedUsersCount,
    });
  }

  public deselectAllUsers() {
    const selectedUsersCount = UsersListOperationsUseCase.deselectAllUsers();
    this.changeState({
      ...this.state,
      redrawList: Date.now(),
      selectedUsersCount,
    });
  }

  public setListUserClicked(userId: string) {
    this.changeState({ ...this.state, listUserClicked: userId });
  }
}
