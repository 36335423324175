import CalendarEventDS, { IEventExtremesInfos } from "./CalendarEventDS";
import { LoadEventsResponse, GenericEventOperationResponse } from "./EventOperationsResponse";

export default abstract class EventsRepository {
  public abstract loadEvents(): Promise<LoadEventsResponse>;
  public abstract loadEventsRange(startDate: Date, endDate: Date): Promise<LoadEventsResponse>;
  public abstract saveEvent(event: CalendarEventDS): Promise<GenericEventOperationResponse>;
  public abstract updateEventExtremes(event: IEventExtremesInfos): Promise<GenericEventOperationResponse>;
  public abstract update(event: CalendarEventDS): Promise<GenericEventOperationResponse>;
  public abstract deleteEvent(eventId: string): Promise<GenericEventOperationResponse>;
  public abstract getSelectedUsers(): Set<string>;
  public abstract setSelectedUsers(value: Set<string>): void;
}
