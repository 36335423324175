<template>
  <VueMultiselect
    :style="{ width: width + 'px' }"
    :class="{ 'multiselect-class': multiple }"
    v-model="selectedState"
    :value="selectedSingle"
    :options="options"
    :close-on-select="true"
    :clear-on-select="true"
    :placeholder="placeholder"
    :showLabels="showLabels"
    :selectLabel="getSelectLabel()"
    :deselectLabel="getDeselectLabel()"
    :selectedLabel="getSelectedLabel()"
    :multiple="multiple"
    :searchable="searchable"
    :custom-label="customLabelFn"
    @select="onChange"
    @remove="onChange"
    :allowEmpty="false"
    :hideSelected="true"
  >
    <template v-slot:noResult>{{ getNoResultLabel() }}</template>
    <template v-slot:noOptions>{{ getNoOptionsLabel() }}</template>
  </VueMultiselect>
</template>
<script lang="ts">
import {
  MULTISELECT_DESELECT_LABEL,
  MULTISELECT_NO_OPTIONS_LABEL,
  MULTISELECT_NO_RESULT_LABEL,
  MULTISELECT_SELECTED_LABEL,
  MULTISELECT_SELECT_LABEL,
} from "@/core/common/utils/ResourceStrings";
import { defineComponent, ref, watch } from "vue";
import VueMultiselect from "vue-multiselect";

export default defineComponent({
  components: { VueMultiselect },
  props: {
    placeholder: String,
    showLabels: Boolean,
    width: Number,
    multiple: Boolean,
    options: Array,
    searchable: Boolean,
    customLabelFn: Function,
    changeFn: Function,
    selectedSingle: String,
    selectedMultiple: Array,
    selectKey: String,
  },
  setup(props) {
    let selectedState = ref();
    if (props.multiple) selectedState = ref(props.selectedMultiple);
    else selectedState = ref(props.selectedSingle);

    const getSelectLabel = () => {
      return MULTISELECT_SELECT_LABEL;
    };
    const getDeselectLabel = () => {
      return MULTISELECT_DESELECT_LABEL;
    };
    const getSelectedLabel = () => {
      return MULTISELECT_SELECTED_LABEL;
    };
    const getNoResultLabel = () => {
      return MULTISELECT_NO_RESULT_LABEL;
    };
    const getNoOptionsLabel = () => {
      return MULTISELECT_NO_OPTIONS_LABEL;
    };

    const onChange = () => {
      if (props.changeFn) props.changeFn(selectedState.value, props.selectKey);
    };

    watch(
      () => props.selectedSingle,
      (newSelected) => {
        selectedState.value = newSelected;
      }
    );

    watch(
      () => props.selectedMultiple,
      (newSelected) => {
        selectedState.value = newSelected;
      }
    );

    return {
      selectedState,
      getSelectLabel,
      getDeselectLabel,
      getSelectedLabel,
      getNoResultLabel,
      getNoOptionsLabel,
      onChange,
    };
  },
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
@import "./public/styles/variables.scss";

.multiselect {
  font-size: 0.875rem;

  .multiselect__input {
    font-size: 0.875rem;
    padding-left: 0px;
  }

  .multiselect__option--highlight {
    background: $selected-color;
    &::after {
      background: $selected-color;
    }
  }

  .multiselect__option--highlight.multiselect__option--selected {
    background: #ff6a6a;
    &::after {
      background: #ff6a6a;
    }
  }

  .multiselect__tag {
    background: $selected-color;
  }

  .multiselect__tags {
    @include form-control-thin-styles;
    padding: 8px 30px 0 8px;
  }

  .multiselect__single {
    font-size: 0.875rem;
  }

  .multiselect__option--highlight,
  .multiselect__option--selected {
    font-weight: 500;
  }
}

.multiselect-class {
  .multiselect__tags {
    height: auto;
  }
}
</style>
