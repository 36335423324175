import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-confirm" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "modal-close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBtnDenyClick && _ctx.onBtnDenyClick(...args)))
      }, [
        _createVNode(_component_close_outlined)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", null, _toDisplayString(_ctx.body), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBtnOkClick && _ctx.onBtnOkClick(...args))),
          class: "btn btn-outline btn-outline-confirm"
        }, "Si", 512), [
          [_vShow, _ctx.showBtnOk]
        ]),
        _withDirectives(_createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBtnDenyClick && _ctx.onBtnDenyClick(...args))),
          class: "btn btn-outline btn-outline-remove"
        }, "No", 512), [
          [_vShow, _ctx.showBtnDeny]
        ])
      ])
    ])
  ]))
}