import { CUSTOMER_TYPE_ID, EMPLOYEE_TYPE_ID } from "../UserDS";
import UsersRepository from "../UsersRepository";

export default class GetFilteredUsersUseCase {
  private static internalUsersByType(
    users: Array<string>,
    userType: string,
    usersRepository: UsersRepository
  ): Array<string> {
    return users.filter((userId: string) => usersRepository.getUserById(userId).type === userType);
  }

  public static getActiveCustomersIds(usersRepository: UsersRepository) {
    return this.internalUsersByType(usersRepository.getActiveUsersIds(), CUSTOMER_TYPE_ID, usersRepository);
  }
  public static getActiveEmployeesIds(usersRepository: UsersRepository) {
    return this.internalUsersByType(usersRepository.getActiveUsersIds(), EMPLOYEE_TYPE_ID, usersRepository);
  }

  public static getAllCustomersIds(usersRepository: UsersRepository) {
    return this.internalUsersByType(usersRepository.getAllUsersIds(), CUSTOMER_TYPE_ID, usersRepository);
  }

  public static getAllEmployeesIds(usersRepository: UsersRepository) {
    return this.internalUsersByType(usersRepository.getAllUsersIds(), EMPLOYEE_TYPE_ID, usersRepository);
  }

  public static getEmployeesByColor(
    usersRepository: UsersRepository,
    color: string,
    employeeToExclude: string
  ): Array<string> {
    const result = new Array<string>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    usersRepository.getUsersMap().forEach((value, key) => {
      if (value.type === EMPLOYEE_TYPE_ID && value.color === color && value.id !== employeeToExclude)
        result.push(value.id);
    });
    return result;
  }

  public static getEmployeesByMail(usersRepository: UsersRepository, mail: string, employeeToExclude: string) {
    const result = new Array<string>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    usersRepository.getUsersMap().forEach((value, key) => {
      if (value.type === EMPLOYEE_TYPE_ID && value.mail === mail && value.id !== employeeToExclude)
        result.push(value.id);
    });
    return result;
  }
}
