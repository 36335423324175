import { onMounted, onUnmounted, Ref, ref } from "vue";
import { PLOC } from "../../core/common/presentation/PLOC";

export function manageSubscription<S>(aPLOC: PLOC<S>): Ref<S> {
  const lState = ref(aPLOC.state) as Ref<S>;

  const stateSubscription = (aNewState: S) => {
    lState.value = aNewState;
  };

  onMounted(() => {
    aPLOC.subscribe(stateSubscription);
  });

  onUnmounted(() => {
    aPLOC.unsubscribe(stateSubscription);
  });

  return lState;
}
