export const DAY_VIEW_ID = "timeGridDay";
export const TIMEGRID_TYPE = "timeGrid";
export const DAYGRID_TYPE = "daygrid";
export const LIST_WEEK_TYPE = "listWeek";
export const TWO_DAYS_VIEW_ID = "agendaTwoDays";
export const THREE_DAYS_VIEW_ID = "agendaThreeDays";
export const FOUR_DAYS_VIEW_ID = "agendaFourDays";
export const FIVE_DAYS_VIEW_ID = "agendaFiveDays";
export const SIX_DAYS_VIEW_ID = "agendaSixDays";
export const WEEEK_VIEW_ID = "timeGridWeek";
export const MONTH_VIEW_ID = "dayGridMonth";
export const LIST_WEEK_VIEW_ID = "listWeek";
