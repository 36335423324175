import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueMultiselect = _resolveComponent("VueMultiselect")!

  return (_openBlock(), _createBlock(_component_VueMultiselect, {
    style: _normalizeStyle({ width: _ctx.width + 'px' }),
    class: _normalizeClass({ 'multiselect-class': _ctx.multiple }),
    modelValue: _ctx.selectedState,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedState) = $event)),
    value: _ctx.selectedSingle,
    options: _ctx.options,
    "close-on-select": true,
    "clear-on-select": true,
    placeholder: _ctx.placeholder,
    showLabels: _ctx.showLabels,
    selectLabel: _ctx.getSelectLabel(),
    deselectLabel: _ctx.getDeselectLabel(),
    selectedLabel: _ctx.getSelectedLabel(),
    multiple: _ctx.multiple,
    searchable: _ctx.searchable,
    "custom-label": _ctx.customLabelFn,
    onSelect: _ctx.onChange,
    onRemove: _ctx.onChange,
    allowEmpty: false,
    hideSelected: true
  }, {
    noResult: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.getNoResultLabel()), 1)
    ]),
    noOptions: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.getNoOptionsLabel()), 1)
    ]),
    _: 1
  }, 8, ["style", "class", "modelValue", "value", "options", "placeholder", "showLabels", "selectLabel", "deselectLabel", "selectedLabel", "multiple", "searchable", "custom-label", "onSelect", "onRemove"]))
}