import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pivot-root section-content" }
const _hoisted_2 = { class: "pivot-opts-bar" }
const _hoisted_3 = { class: "loader-container" }
const _hoisted_4 = {
  ref: "pivotTable",
  class: "pivot-table"
}
const _hoisted_5 = { class: "status-icon-container" }
const _hoisted_6 = { class: "warning-icon" }
const _hoisted_7 = { class: "status-icon-container" }
const _hoisted_8 = { class: "error-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_download_outlined = _resolveComponent("download-outlined")!
  const _component_font_size_outlined = _resolveComponent("font-size-outlined")!
  const _component_swap_outlined = _resolveComponent("swap-outlined")!
  const _component_filter_outlined = _resolveComponent("filter-outlined")!
  const _component_deployment_unit_outlined = _resolveComponent("deployment-unit-outlined")!
  const _component_setting_outlined = _resolveComponent("setting-outlined")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_warning_outlined = _resolveComponent("warning-outlined")!
  const _component_exclamation_circle_outlined = _resolveComponent("exclamation-circle-outlined")!
  const _component_pivot_settings = _resolveComponent("pivot-settings")!
  const _component_pivot_entities = _resolveComponent("pivot-entities")!
  const _component_pivot_ordering = _resolveComponent("pivot-ordering")!
  const _component_pivot_formats = _resolveComponent("pivot-formats")!
  const _component_pivot_filters = _resolveComponent("pivot-filters")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass({ 'opt-item': true, disabled: _ctx.state.loading || _ctx.state.error, enabled: !_ctx.state.loading && !_ctx.state.error }),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doExportCSV()))
        }, [
          _createVNode(_component_download_outlined)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass({ 'opt-item': true, disabled: _ctx.state.loading || _ctx.state.error, enabled: !_ctx.state.loading && !_ctx.state.error }),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openOptions(_ctx.getFormatsModalId())))
        }, [
          _createVNode(_component_font_size_outlined)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass({ 'opt-item': true, disabled: _ctx.state.loading || _ctx.state.error, enabled: !_ctx.state.loading && !_ctx.state.error }),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openOptions(_ctx.getOrderingModalId())))
        }, [
          _createVNode(_component_swap_outlined, { rotate: 90 })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass({
          'opt-item': true,
          disabled: _ctx.state.loading || _ctx.state.error,
          enabled: !_ctx.state.loading && !_ctx.state.error,
          filtered: _ctx.isFiltered(),
        }),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openOptions(_ctx.getFiltersModalId())))
        }, [
          _createVNode(_component_filter_outlined)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass({ 'opt-item': true, disabled: _ctx.state.loading || _ctx.state.error, enabled: !_ctx.state.loading && !_ctx.state.error }),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openOptions(_ctx.getEntitiesModalId())))
        }, [
          _createVNode(_component_deployment_unit_outlined)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass({ 'opt-item': true, disabled: _ctx.state.loading || _ctx.state.error, enabled: !_ctx.state.loading && !_ctx.state.error }),
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openOptions(_ctx.getSettingsModalId())))
        }, [
          _createVNode(_component_setting_outlined)
        ], 2)
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Loader)
      ], 512), [
        [_vShow, _ctx.state.loading && !_ctx.state.error]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, _cache[6] || (_cache[6] = [
        _createStaticVNode("<div class=\"pivot-viewport\"><div class=\"pivot-scrollbar-box\"><div class=\"pivot-alignment-box\"><div class=\"pivot-data-values\"></div></div><div class=\"pivot-full-height-width\"></div></div><div class=\"pivot-col-head\"><div class=\"pivot-col-head-entities-metadata cell cell-header\"></div><div class=\"pivot-col-head-hscroll-viewport\"><div class=\"pivot-col-head-hscroll-hidden\"><div class=\"pivot-col-head-alignment-box\"><div class=\"pivot-col-head-data\"></div></div><div class=\"pivot-col-head-full-height-width\"></div></div></div></div><div class=\"pivot-row-head\"><div class=\"pivot-row-head-entities-metadata\"></div><div class=\"pivot-row-head-viewport\"><div class=\"pivot-row-head-vscroll-hidden\"><div class=\"pivot-row-head-alignment-box\"><div class=\"pivot-row-head-data\"></div></div><div class=\"pivot-row-head-full-height-width\"></div></div></div></div></div><canvas id=\"cellWidthCalculator\" style=\"display:none;\"></canvas><div class=\"pivot-tooltip\"></div>", 3)
      ]), 512), [
        [_vShow, !_ctx.state.loading && !_ctx.state.emptyData && !_ctx.state.error]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_warning_outlined)
        ]),
        _cache[7] || (_cache[7] = _createElementVNode("span", null, "Non ci sono dati da mostrare", -1))
      ], 512), [
        [_vShow, _ctx.state.emptyData && !_ctx.state.error && !_ctx.state.loading]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_exclamation_circle_outlined)
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("span", null, "Errore durante il caricamento della pivot, consultare i log per dettagli", -1))
      ], 512), [
        [_vShow, _ctx.state.error && !_ctx.state.emptyData]
      ])
    ]),
    (_ctx.state.modalVisible && _ctx.state.modalId === _ctx.getSettingsModalId())
      ? (_openBlock(), _createBlock(_component_pivot_settings, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.state.modalVisible && _ctx.state.modalId === _ctx.getEntitiesModalId())
      ? (_openBlock(), _createBlock(_component_pivot_entities, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.state.modalVisible && _ctx.state.modalId === _ctx.getOrderingModalId())
      ? (_openBlock(), _createBlock(_component_pivot_ordering, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.state.modalVisible && _ctx.state.modalId === _ctx.getFormatsModalId())
      ? (_openBlock(), _createBlock(_component_pivot_formats, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.state.modalVisible && _ctx.state.modalId === _ctx.getFiltersModalId())
      ? (_openBlock(), _createBlock(_component_pivot_filters, { key: 4 }))
      : _createCommentVNode("", true)
  ], 64))
}