<template>
  <div class="calendar-filters">
    <div>
      <div class="title">
        <span class="title-icon"><filter-outlined /></span> <span>Utenti:</span>
      </div>
      <div v-show="calendarState.filters.loading"><loader></loader></div>
      <div v-show="!calendarState.filters.loading" class="filter-values-container">
        <virtual-searchable-list
          :full-list-count="calendarState.filters.allUsersCount"
          :get-list-item-fn="getListFilterValue"
          :get-list-item-text-fn="getListItemTextFn"
          :key="calendarState.filters.redrawList"
          :item-height="27.2"
        >
          <template #customButtons>
            <button class="list-macro-btn" @click="selectAllFilterUsers">Seleziona tutto</button>
            <button class="list-macro-btn" @click="selectNoneFilterUsers">Deseleziona tutto</button>
          </template>
          <template #searchedItem="{ item }">
            <div class="filter-value-container">
              <label class="filter-value-item custom-checkbox" :for="'input_' + item.id">
                {{ item.description }}
                <input
                  type="checkbox"
                  :id="'input_' + item.id"
                  v-model="item.selected"
                  @click="toggleUserSelection(item.id)"
                />

                <span class="checkmark"></span>
              </label>
              <span class="only" :id="'only_' + item.id" @click="selectOneFilterUser(item.id)">only</span>
            </div>
          </template></virtual-searchable-list
        >
      </div>
      <div class="footer">
        <div class="selected-info">
          <span class="selected-number">{{ calendarState.filters.selectedUsersCount }} </span> Elementi selezionati
        </div>
        <div class="footer-btns">
          <button
            class="btn btn-outline btn-outline-remove"
            @click="removeFilters"
            v-if="calendarState.filters.isFilterActive"
          >
            <close-outlined /></button
          ><button class="btn btn-outline btn-outline-filter" @click="refetchEvents">
            <filter-outlined /><right-outlined></right-outlined>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject, onMounted } from "vue";
import { CALENDAR_PLOC_KEY } from "@/components/common/GUIConsts";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import VirtualSearchableList from "../controls/list/VirtualSearchableList.vue";
import { CalendarPLOC } from "@/core/calendar/presentation/CalendarPLOC";
import { FilterOutlined, CloseOutlined, RightOutlined } from "@ant-design/icons-vue";
import Loader from "../controls/Loader.vue";

export default defineComponent({
  components: {
    VirtualSearchableList,
    FilterOutlined,
    CloseOutlined,
    RightOutlined,
    Loader,
  },
  setup() {
    const calendarPLOC = inject(CALENDAR_PLOC_KEY) as CalendarPLOC;
    let calendarState = manageSubscription(calendarPLOC);

    const getListFilterValue = (index: number) => {
      return calendarPLOC.getUserByIndex(index);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getListItemTextFn = (item: any) => {
      return item.description;
    };

    const toggleUserSelection = (userId: string) => {
      calendarPLOC.toggleFilterUserSelection(userId);
    };

    const selectAllFilterUsers = () => {
      calendarPLOC.selectAllFilterUsers();
    };
    const selectNoneFilterUsers = () => {
      calendarPLOC.deselectAllFilterUsers();
    };
    const selectOneFilterUser = (userId: string) => {
      calendarPLOC.selectOneFilterUser(userId);
    };

    const refetchEvents = () => {
      calendarPLOC.refetchEvents();
    };

    const removeFilters = () => {
      calendarPLOC.removeFilters();
    };

    onMounted(async () => {
      await calendarPLOC.setupCalendarFilters();
    });

    return {
      calendarState,
      getListItemTextFn,
      getListFilterValue,
      toggleUserSelection,
      selectAllFilterUsers,
      selectNoneFilterUsers,
      selectOneFilterUser,
      refetchEvents,
      removeFilters,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

/* Modal Content */

.calendar-filters {
  margin-top: 10px;
  margin-right: 15px;
  margin-left: 15px;
  font-size: 0.85rem;

  .loader {
    height: 300px;
  }

  .title {
    .title-icon {
      margin-right: 3px;
    }
  }
  .filter-values-container {
    height: 300px;
    .search-bar {
      input {
        background: transparent;
      }
    }

    .macro-part {
      margin-left: 10px;
      .list-macro-btn {
        font-size: 0.75rem;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    .selected-info {
      margin-left: 5px;

      .selected-number {
        font-weight: 700;
      }
    }

    .footer-btns {
      display: flex;
      .btn {
        min-height: 0px;
        padding: 0px 10px 0px 10px;
        margin-left: 3px;
      }
    }
  }
}
</style>
