export interface AppState {
  currentPage: string;
  loggedIn: boolean;
  loggedUserMenuActive: boolean;
  sessionExpired: boolean;
  isIconbarOpen: boolean;
}

export const appInitialState: AppState = {
  currentPage: "",
  loggedIn: false,
  loggedUserMenuActive: false,
  sessionExpired: false,
  isIconbarOpen: false,
};
