import DateUtils from "@/core/common/utils/DateUtils";
import CalendarEventDS, { IEventExtremesInfos, SerializedCalendarEventDS } from "./CalendarEventDS";

export default class CalendarEvent {
  private static instance: CalendarEvent;
  private minExtremesRangeInMinutes: number;

  private constructor() {
    this.minExtremesRangeInMinutes = 0;
  }

  public static getInstance() {
    if (!CalendarEvent.instance) {
      CalendarEvent.instance = new CalendarEvent();
    }

    return CalendarEvent.instance;
  }

  public setMinExtremesRangeInMinutes(value: number) {
    this.minExtremesRangeInMinutes = value;
  }

  public validateExtremes(event: CalendarEventDS) {
    return event.start < event.end && DateUtils.diffMinutes(event.start, event.end) >= this.minExtremesRangeInMinutes;
  }

  public validateCustomer(event: CalendarEventDS) {
    return event.customer !== "";
  }

  public validatePerformance(event: CalendarEventDS) {
    return event.performance !== "";
  }
  public validateEmployees(event: CalendarEventDS) {
    return event.employees && event.employees.length > 0;
  }
  public validateCost(event: CalendarEventDS) {
    return event.cost >= 0;
  }
  public serialize(event: CalendarEventDS): SerializedCalendarEventDS {
    const extremes = this.serializeEventExtremes(event);
    return {
      id: extremes.id,
      start: extremes.start,
      end: extremes.end,
      allDay: event.allDay,
      employees: event.employees,
      performance: event.performance,
      customer: event.customer,
      cost: event.cost,
      title: event.title,
      visible: event.visible,
      borderColor: event.borderColor,
      backgroundColor: event.backgroundColor,
    };
  }
  public serializeEventExtremes(extremes: IEventExtremesInfos) {
    return {
      id: extremes.id,
      start: extremes.start.toLocaleString("en-IT", { timeZone: "Europe/Rome" }),
      end: extremes.end.toLocaleString("en-IT", { timeZone: "Europe/Rome" }),
    };
  }

  public deserialize(event: SerializedCalendarEventDS): CalendarEventDS {
    return {
      id: event.id,
      start: new Date(event.start.replace("Z", "")),
      end: new Date(event.end.replace("Z", "")),
      allDay: event.allDay,
      employees: event.employees,
      performance: event.performance,
      customer: event.customer,
      cost: event.cost,
      title: event.title,
      visible: event.visible,
      borderColor: event.borderColor,
      backgroundColor: event.backgroundColor,
    };
  }

  public validate(event: CalendarEventDS) {
    const costOk = this.validateCost(event);
    const employeesOk = this.validateEmployees(event);
    const performanceOk = this.validatePerformance(event);
    const customerOk = this.validateCustomer(event);
    const timeExtremesOk = this.validateExtremes(event);
    return;
    customerOk && employeesOk && costOk && performanceOk && timeExtremesOk;
  }
}
