/* eslint-disable @typescript-eslint/no-explicit-any */
export const debounce = (func: any, timeout = 300, immediate = false) => {
  let timer: number;
  return (...args: any) => {
    const callNow = immediate && !timer;
    clearTimeout(timer);
    timer = window.setTimeout(() => {
      timer = -1;
      if (!immediate) {
        func.apply(this, args);
      }
    }, timeout);
    if (callNow) {
      func.apply(this, args);
    }
  };
};

export function getDescFromCode(code: string, codeToDesc: Map<string, string>, dimensionAlias = "") {
  if (code === undefined || code === null) {
    return "";
  }

  const searchCode = dimensionAlias ? `${code}_${dimensionAlias}` : code;

  return codeToDesc.get(searchCode) ?? code;
}

export function valueIsValid(value: any) {
  return value || value === 0;
}

export function castCodeValue(codeStr: string, entityAlias: string) {
  switch (entityAlias) {
    case "D_DATE":
      return new Date(codeStr);
    default:
      return codeStr;
  }
}
