import CalendarEventDS from "../domain/CalendarEventDS";
import FullCalendarEvent from "./FullCalendarEvent";

export default class FullCalendarController {
  public static fullcalendarToAppEvent(event: FullCalendarEvent): CalendarEventDS {
    return {
      id: event.id,
      start: event.start,
      end: event.end,
      title: event.title,
      borderColor: event.borderColor,
      backgroundColor: event.backgroundColor,
      ...event.extendedProps,
    };
  }

  public static appToFullcalendarEvent(event: CalendarEventDS): FullCalendarEvent {
    return {
      id: event.id,
      start: event.start,
      end: event.end,
      title: event.title,
      borderColor: event.borderColor,
      backgroundColor: event.backgroundColor,
      extendedProps: {
        employees: event.employees,
        performance: event.performance,
        customer: event.customer,
        cost: event.cost,
        visible: event.visible,
      },
    };
  }
}
