export const INVALID_CREDENTIALS = "Credenziali non valide.";
export const INVALID_MAIL = "Indirizzo email non valido";
export const INVALID_RECAPTCHA = "Errore: Recaptcha non passato.";
export const ALL_DAY_TEXT = "Tutto il giorno";
export const TODAY_BUTTON_TEXT = "Torna a oggi";
export const CALENDAR_PAGE_TITLE = "Calendario";
export const ANALYSIS_PAGE_TITLE = "Analisi";
export const PERFORMANCES_PAGE_TITLE = "Prestazioni";
export const USERS_PAGE_TITLE = "Utenti";
export const TWO_DAYS_VIEW_TITLE = "2 Giorni";
export const THREE_DAYS_VIEW_TITLE = "3 Giorni";
export const FOUR_DAYS_VIEW_TITLE = "4 Giorni";
export const FIVE_DAYS_VIEW_TITLE = "5 Giorni";
export const SIX_DAYS_VIEW_TITLE = "6 Giorni";
export const WEEEK_VIEW_TITLE = "Settimana";
export const DAY_VIEW_TITLE = "Giorno";
export const MONTH_VIEW_TITLE = "Mese";
export const LIST_WEEK_TITLE = "Lista";
export const LOADING_EVENTS_ERROR = "Errore durante il caricamento degli eventi:";
export const MULTISELECT_SELECT_LABEL = "seleziona";
export const MULTISELECT_DESELECT_LABEL = "rimuovi";
export const MULTISELECT_SELECTED_LABEL = "selezionato";
export const MULTISELECT_NO_OPTIONS_LABEL = "Nessun elemento trovato";
export const MULTISELECT_NO_RESULT_LABEL = "Nessun elemento disponibile";
export const SELECT_EVENT_CUSTOMER = "Seleziona un cliente";
export const SELECT_EVENT_EMPLOYEES = "Seleziona uno o più impiegati";
export const EVENT_HOURS = "Orario";
export const EVENT_HOURS_START = "Inizio:";
export const EVENT_HOURS_END = "Fine:";
export const EVENT_COST = "Costo:";
export const SELECT_EVENT_PERFORMANCE = "Seleziona una prestazione";
export const EVENT_CUSTOMER = "Cliente:";
export const EVENT_EMPLOYEES = "Impiegati:";
export const EVENT_PERFORMANCE = "Prestazione:";
export const SAVE = "Salva";
export const DELETE = "Elimina";
export const DISCARD = "Annulla";
export const LOADING_USERS_ERROR = "Errore durante il caricamento degli utenti:";
export const LOADING_PERFORMANCES_ERROR = "Errore durante il caricamento delle prestazioni:";
export const NEW_EVENT = "Nuovo evento";
export const TIME_EXTREMES_ERROR = "Estremi temporali non validi.";
export const EVENT_CUSTOMER_ERROR = "Selezionare un cliente.";

export const EVENT_EMPLOYEES_ERROR = "Selezionare almeno un impiegato.";

export const EVENT_PERFORMANCE_ERROR = "Selezionare una prestazione.";

export const EVENT_COST_ERROR = "Il costo non può essere negativo";
export const SAVE_EVENT_ERROR = "Errore durante il salvataggio dell'evento";
export const UPDATE_EVENT_EXTREMES_ERROR = "Errore durante l'aggiornamento degli estremi dell'evento";
export const UPDATE_EVENT_ERROR = "Errore durante l'aggiornamento dell'evento";
export const DELETE_EVENT_ERROR = "Errore durante l'eliminazione dell'evento";
export const TOTAL_LABEL = "Totale";
export const LOADING_PIVOT_DATA_ERROR = "Erorre durante il caricamento dei dati.";
export const UPDATE_USER_ERROR = "Errore durante l'aggiornamento delle impostazioni utente.";
export const LOADING_PIVOT_OPTS_ERROR = "Errore durante il caricamento delle opzioni della pivot.";
export const SAVE_PIVOT_OPTS_ERROR = "Errore durante il caricamento delle opzioni della pivot.";
export const SAVE_USER_ERROR = "Errore durante la creazione del nuovo utente.";
export const DELETE_USER_ERROR = "Errore durante l'eliminazione degli utenti.";
export const DELETE_PERFORMANCE_ERROR = "Errore durante l'eliminazione delle prestazioni.";
export const SAVE_PERFORMANCE_ERROR = "Errore durante la creazione della nuova prestazione.";
export const UPDATE_PERFORMANCE_ERROR = "Errore durante l'aggiornamento della prestazione.";
