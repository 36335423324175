import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "modal pivot-modal pivot-filters-modal",
  id: "pivotFilters"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-close" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "title-icon" }
const _hoisted_6 = { class: "modal-body filters-modal-body" }
const _hoisted_7 = { class: "filter-subtitle" }
const _hoisted_8 = { class: "filter-list" }
const _hoisted_9 = { class: "filter-left" }
const _hoisted_10 = { class: "filter-text" }
const _hoisted_11 = { class: "filter-right" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "edit-filter" }
const _hoisted_15 = { class: "filter-on" }
const _hoisted_16 = {
  key: 0,
  class: "filter-values-container"
}
const _hoisted_17 = { class: "filter-value-container" }
const _hoisted_18 = ["for"]
const _hoisted_19 = ["id", "onUpdate:modelValue"]
const _hoisted_20 = ["id"]
const _hoisted_21 = {
  key: 1,
  class: "date-filter-values"
}
const _hoisted_22 = { class: "date-filter-value" }
const _hoisted_23 = { class: "date-filter-value" }
const _hoisted_24 = { class: "error" }
const _hoisted_25 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_filter_outlined = _resolveComponent("filter-outlined")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_Select = _resolveComponent("Select")!
  const _component_virtual_searchable_list = _resolveComponent("virtual-searchable-list")!
  const _component_date_picker = _resolveComponent("date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_close_outlined, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_filter_outlined)
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Filtri", -1))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("span", null, [
              _createElementVNode("span", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.exitEditMode && _ctx.exitEditMode(...args))),
                class: "breadcrumb"
              }, "Elenco filtri attivi"),
              _cache[10] || (_cache[10] = _createElementVNode("span", null, " | ", -1))
            ], 512), [
              [_vShow, _ctx.pivotState.filtersEditing.editFilterMode]
            ]),
            _createElementVNode("h3", null, _toDisplayString(_ctx.pivotState.filtersEditing.filterSubtitle), 1)
          ]),
          _withDirectives(_createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.createNewFilter())),
            class: "btn btn-outline btn-new-filter btn-outline-filter"
          }, [
            _createVNode(_component_plus_outlined)
          ], 512), [
            [_vShow, !_ctx.pivotState.filtersEditing.editFilterMode]
          ])
        ], 512), [
          [_vShow, _ctx.subtitleVisible()]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pivotState.optsWorkingCopy.filters, (filter) => {
            return (_openBlock(), _createElementBlock("div", {
              key: filter.entityAlias,
              class: "filter"
            }, [
              _createElementVNode("div", _hoisted_9, [
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "filter-icon" }, null, -1)),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getFilterDescription(filter)), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", {
                  class: "filter-action",
                  onClick: ($event: any) => (_ctx.editFilter(filter.entityAlias))
                }, [
                  _createVNode(_component_edit_outlined)
                ], 8, _hoisted_12),
                _createElementVNode("span", {
                  class: "filter-action",
                  onClick: ($event: any) => (_ctx.removeFilter(filter.entityAlias))
                }, [
                  _createVNode(_component_delete_outlined)
                ], 8, _hoisted_13)
              ])
            ]))
          }), 128))
        ], 512), [
          [_vShow, !_ctx.pivotState.filtersEditing.editFilterMode]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[12] || (_cache[12] = _createElementVNode("span", null, "Filtro su: ", -1)),
            _createVNode(_component_Select, {
              disabled: !_ctx.isNewFilter(),
              placeholder: 'Seleziona un valore',
              showLabels: false,
              width: 200,
              options: [..._ctx.getFilterableDims()],
              selectedSingle: _ctx.pivotState.filtersEditing.filterOnEdit.entityAlias,
              customLabelFn: _ctx.getDimensionDescription,
              searchable: false,
              selectKey: _ctx.pivotState.filtersEditing.filterOnEdit.entityAlias,
              changeFn: _ctx.setFilterEntity
            }, null, 8, ["disabled", "options", "selectedSingle", "customLabelFn", "selectKey", "changeFn"])
          ]),
          (_ctx.isValuesListFilter())
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                (_openBlock(), _createBlock(_component_virtual_searchable_list, {
                  "full-list-count": _ctx.pivotState.filtersEditing.filterOnEdit.allValuesCount,
                  "get-list-item-fn": _ctx.getListFilterValue,
                  "get-list-item-text-fn": _ctx.getListItemTextFn,
                  key: _ctx.pivotState.filtersEditing.filterOnEdit.entityAlias + _ctx.internalState.redrawList,
                  "item-height": 28
                }, {
                  customButtons: _withCtx(() => [
                    _createElementVNode("button", {
                      class: "list-macro-btn",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.selectAllFilterValues && _ctx.selectAllFilterValues(...args)))
                    }, "Seleziona tutto"),
                    _createElementVNode("button", {
                      class: "list-macro-btn",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.selectNoneFilterValues && _ctx.selectNoneFilterValues(...args)))
                    }, "Deseleziona tutto")
                  ]),
                  searchedItem: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("label", {
                        class: "filter-value-item custom-checkbox",
                        for: 'input_' + item.id
                      }, [
                        _createTextVNode(_toDisplayString(item.description) + " ", 1),
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          id: 'input_' + item.id,
                          "onUpdate:modelValue": ($event: any) => ((item.selected) = $event),
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleFilterSelectedValue($event)))
                        }, null, 8, _hoisted_19), [
                          [_vModelCheckbox, item.selected]
                        ]),
                        _cache[13] || (_cache[13] = _createElementVNode("span", { class: "checkmark" }, null, -1))
                      ], 8, _hoisted_18),
                      _createElementVNode("span", {
                        class: "only",
                        id: 'only_' + item.id,
                        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.selectOneFilterValue && _ctx.selectOneFilterValue(...args)))
                      }, "only", 8, _hoisted_20)
                    ])
                  ]),
                  _: 1
                }, 8, ["full-list-count", "get-list-item-fn", "get-list-item-text-fn"]))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _cache[14] || (_cache[14] = _createElementVNode("span", null, "Data di inizio:", -1)),
                  _createVNode(_component_date_picker, {
                    initialValue: _ctx.getTemporalFilterValue().start,
                    onChange: _ctx.onStartFilterValueChange
                  }, null, 8, ["initialValue", "onChange"])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _cache[15] || (_cache[15] = _createElementVNode("span", null, "Data di fine:", -1)),
                  _createVNode(_component_date_picker, {
                    initialValue: _ctx.getTemporalFilterValue().end,
                    onChange: _ctx.onEndFilterValueChange
                  }, null, 8, ["initialValue", "onChange"])
                ])
              ])),
          _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.pivotState.filtersEditing.filterSaveErrorMsg), 1)
        ], 512), [
          [_vShow, _ctx.pivotState.filtersEditing.editFilterMode]
        ])
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-confirm",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.saveOptions && _ctx.saveOptions(...args)))
        }, _toDisplayString(_ctx.getSaveString()), 1),
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-discard",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, _toDisplayString(_ctx.getDiscardString()), 1)
      ])
    ])
  ]))
}