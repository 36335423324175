import DateUtils from "@/core/common/utils/DateUtils";
import Calendar from "../Calendar";

export default class GoToDateRangeUseCase {
  public static goToDateRange(dateRange: Array<Date>, calendar: Calendar): string {
    let targetView = calendar.getCurrentViewId();
    const diffDaysToViewId = calendar.getViewIds();

    if (!DateUtils.dateRangesAreEquals(dateRange, calendar.getDateRange())) {
      let diffDays = 0;
      if (dateRange.length === 1) diffDays = 0;
      else if (dateRange.length === 2) diffDays = DateUtils.diffDays(dateRange[0], dateRange[1]);

      if (diffDaysToViewId[diffDays]) {
        targetView = diffDaysToViewId[diffDays];
        calendar.changeView(targetView);
        calendar.goToDate(dateRange[0]);
      }
    }

    return targetView;
  }
}
