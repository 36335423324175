import PerformancesRepository from "../PerformancesRepository";
import DeletePerformancesUseCase from "./DeletePerformancesUseCase";

export default class PerformancesListOperationsUseCase {
  private static selectedPerformances: Set<string> = new Set<string>();

  public static getActivePerformanceByIndex(performancesRepository: PerformancesRepository, index: number) {
    const performancesIds = performancesRepository.getActivePerformancesIds();
    if (index < performancesIds.length) {
      const performance = performancesRepository.getPerformanceById(performancesIds[index]);
      return {
        id: performance.id,
        description: performance.name,
        selected: PerformancesListOperationsUseCase.selectedPerformances.has(performance.id),
      };
    } else {
      console.error(
        "PerformancesListOperationsUseCase/getActivePerformanceByIndex: trying to access non existing performance index " +
          index
      );
      return { id: "not_found_" + index, description: "prestazione non trovata", selected: false };
    }
  }

  public static togglePerformanceSelection(performanceId: string) {
    if (PerformancesListOperationsUseCase.selectedPerformances.has(performanceId))
      PerformancesListOperationsUseCase.selectedPerformances.delete(performanceId);
    else PerformancesListOperationsUseCase.selectedPerformances.add(performanceId);

    return PerformancesListOperationsUseCase.selectedPerformances.size;
  }

  public static async deletePerformances(performancesRepository: PerformancesRepository) {
    const performances = Array.from(PerformancesListOperationsUseCase.selectedPerformances);
    const result = await DeletePerformancesUseCase.deletePerformances(performances, performancesRepository);
    if (result.success) {
      PerformancesListOperationsUseCase.selectedPerformances.clear();
    }
    return { ...result };
  }

  public static selectAllPerformances(performancesRepository: PerformancesRepository) {
    const allPerformances = performancesRepository.getActivePerformancesIds();
    PerformancesListOperationsUseCase.selectedPerformances = new Set(allPerformances);
    return allPerformances.length;
  }

  public static deselectAllPerformances() {
    PerformancesListOperationsUseCase.selectedPerformances.clear();
    return 0;
  }
  public static async deleteSinglePerformance(performanceId: string, performanceRepository: PerformancesRepository) {
    let newSelectedPerformanceCount = PerformancesListOperationsUseCase.selectedPerformances.size;
    const result = await DeletePerformancesUseCase.deleteSinglePeformance(performanceId, performanceRepository);
    if (result.success) {
      if (PerformancesListOperationsUseCase.selectedPerformances.has(performanceId)) {
        PerformancesListOperationsUseCase.selectedPerformances.delete(performanceId);
        newSelectedPerformanceCount--;
      }
    }
    return { ...result, newSelectedPerformanceCount };
  }
}
