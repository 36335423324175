export interface SerializedCalendarEventDS {
  id: string;
  start: string;
  end: string;
  allDay: boolean;
  employees: Array<string>;
  performance: string;
  customer: string;
  cost: number;
  title: string;
  visible: boolean;
  borderColor: string;
  backgroundColor: string;
}

export default interface CalendarEventDS {
  id: string;
  start: Date;
  end: Date;
  allDay: boolean;
  employees: Array<string>;
  performance: string;
  customer: string;
  cost: number;
  title: string;
  visible: boolean;
  borderColor: string;
  backgroundColor: string;
}

export type TCalendarEventWithValidation = CalendarEventDS & {
  timeExtremesOk: boolean;
  performanceOk: boolean;
  customerOk: boolean;
  employeesOk: boolean;
  costOk: boolean;
  toSave: boolean;
  isNew: boolean;
};

export enum EventAction {
  Save,
  Delete,
  DiscardChanges,
}

export interface IEventExtremesInfos {
  id: string;
  start: Date;
  end: Date;
}

export interface EventsLoadingResponse {
  events: Array<object>;
  success: boolean;
}

export const MULTI_EMPLOYEES_COLOR = "#ff824d";
