/* eslint-disable */
export interface WebEnvGateway {
  localStorage: {
    getItem(aKey: string): string;
    setItem(aKey: string, aValue: string): any;
  };
  location: {
    hash: string;
  };
  history: {
    pushState(p1: any, p2: any, p3: string): any;
  };
  scrollTo(x: number, y: number): any;
  document: {
    getElementsByTagName(aTagName: string): any;
    getElementById(aId: string): any;
  };
}

export const CURRENT_PAGE_KEY = "currentPage";
