import { HTTPClient, HTTPMethod, HTTPResponse } from "@/core/common/communication/HTTPClient";
import { IPivotIndex } from "../domain/PivotDS";
import {
  LOADING_PIVOT_DATA_ERROR,
  LOADING_PIVOT_OPTS_ERROR,
  SAVE_PIVOT_OPTS_ERROR,
} from "@/core/common/utils/ResourceStrings";
import { TFilterValues, IPivotOptions, IEntity, IDateFilterValue } from "../domain/PivotOptionsDS";
import PivotData from "../domain/PivotData";
import { IGetIndexedDataResponse, ILoadPivotOptionsResponse, ISaveOptionsResponse } from "../domain/PivotDataDS";
import { DATE_DIM_CODE } from "../common/PivotConsts";

interface APIGetIndexedPivotDataResponse {
  indexedPivotData: IPivotIndex;
  result: boolean;
  detail: string;
}

type TAPIFilterValues = Array<string> | IDateFilterValue;

interface APIFilter {
  entityAlias: string;
  values: TAPIFilterValues;
  allValuesCount: number;
}

interface APIPivotOptions {
  columns: Array<IEntity>;
  rows: Array<IEntity>;
  measures: Array<IEntity>;
  measuresOnColumn: boolean;
  bigTotalOnRow: boolean;
  bigTotalOnColumn: boolean;
  subTotalsOnColumn: boolean;
  subTotalsOnRow: boolean;
  filters: Array<APIFilter>;
}

interface APIGetPivotOptionsResponse {
  opts: APIPivotOptions;
  result: boolean;
  detail: string;
}

interface APISavePivotOptionsResponse {
  result: boolean;
  detail: string;
}
export default class PivotDataImpl extends PivotData {
  private httpClient: HTTPClient;
  private filterDimToSelectedValues: Map<string, TAPIFilterValues>;

  constructor(httpClient: HTTPClient) {
    super();
    this.httpClient = httpClient;
    this.filterDimToSelectedValues = new Map();
  }

  public async getIndexedPivotData(opts: IPivotOptions): Promise<IGetIndexedDataResponse> {
    const result = {
      indexedData: {
        valuesTreeMap: {},
        rowsUniques: new Array<Array<string>>(),
        columnsUniques: new Array<Array<string>>(),
      },
      success: true,
      errorMsg: "",
      emptyData: false,
    };

    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/getIndexedPivotData", {
      opts,
    });
    if (httpResponse.success && (<APIGetIndexedPivotDataResponse>httpResponse.data).result) {
      result.indexedData = (<APIGetIndexedPivotDataResponse>httpResponse.data).indexedPivotData;
      result.emptyData =
        (!result.indexedData.rowsUniques || result.indexedData.rowsUniques.length === 0) &&
        (!result.indexedData.valuesTreeMap || Object.keys(result.indexedData.valuesTreeMap).length === 0);
    } else {
      result.success = false;
      result.errorMsg = httpResponse.success
        ? `${LOADING_PIVOT_DATA_ERROR}${(<APIGetIndexedPivotDataResponse>httpResponse.data).detail}`
        : `${LOADING_PIVOT_DATA_ERROR}${httpResponse.errorMsg}`;
    }

    return Promise.resolve(result);
  }

  private extractSelectedFiltersValues(opts: APIPivotOptions) {
    if (opts && opts.filters)
      opts.filters.forEach((filter) => {
        if (filter.entityAlias === DATE_DIM_CODE)
          this.filterDimToSelectedValues.set(filter.entityAlias, <IDateFilterValue>filter.values);
        else this.filterDimToSelectedValues.set(filter.entityAlias, <Array<string>>filter.values);
        filter.values = [];
      });
  }

  private injectSelectedFiltersValues(opts: IPivotOptions): APIPivotOptions {
    const result: APIPivotOptions = {
      ...opts,
      filters: new Array<APIFilter>(),
    };
    if (opts && opts.filters)
      opts.filters.forEach((filter) => {
        result.filters.push({ ...filter, values: this.filterDimToSelectedValues.get(filter.entityAlias) || [] });
      });
    return result;
  }

  public async loadOptions(): Promise<ILoadPivotOptionsResponse> {
    const result = {
      opts: {} as IPivotOptions,
      success: true,
      errorMsg: "",
    };

    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/getPivotOptions", {});

    if (httpResponse.success && (<APIGetPivotOptionsResponse>httpResponse.data).result) {
      const apiOpts = (<APIGetPivotOptionsResponse>httpResponse.data).opts;
      this.extractSelectedFiltersValues(apiOpts);
      result.opts = apiOpts;
    } else {
      result.success = false;
      result.errorMsg = httpResponse.success
        ? `${LOADING_PIVOT_OPTS_ERROR}${(<APIGetPivotOptionsResponse>httpResponse.data).detail}`
        : `${LOADING_PIVOT_OPTS_ERROR}${httpResponse.errorMsg}`;
    }

    return Promise.resolve(result);
  }

  public async saveOptions(opts: IPivotOptions): Promise<ISaveOptionsResponse> {
    const result = {
      success: true,
      errorMsg: "",
    };

    const apiOpts = this.injectSelectedFiltersValues(opts);
    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/updatePivotOptions", {
      opts: apiOpts,
    });

    if (!httpResponse.success || !(<APISavePivotOptionsResponse>httpResponse.data).result) {
      result.success = false;
      result.errorMsg = httpResponse.success
        ? `${SAVE_PIVOT_OPTS_ERROR}${(<APISavePivotOptionsResponse>httpResponse.data).detail}`
        : `${SAVE_PIVOT_OPTS_ERROR}${httpResponse.errorMsg}`;
    }

    return Promise.resolve(result);
  }

  public getFilterSelectedValues(dimAlias: string): TFilterValues {
    if (dimAlias === DATE_DIM_CODE) {
      if (this.filterDimToSelectedValues.has(dimAlias)) {
        const values = this.filterDimToSelectedValues.get(dimAlias);
        const copy = JSON.parse(JSON.stringify(values));
        return <IDateFilterValue>copy;
      } else return { start: new Date(), end: new Date() };
    } else {
      const values = this.filterDimToSelectedValues.has(dimAlias)
        ? <Array<string>>this.filterDimToSelectedValues.get(dimAlias)
        : new Array<string>();
      const copy = new Set(values);
      return copy;
    }
  }

  public saveFiltersValues(filterDimToSelectedValues: Map<string, TFilterValues>) {
    filterDimToSelectedValues.forEach((selectedValues, dimAlias) => {
      if (dimAlias === DATE_DIM_CODE) {
        this.filterDimToSelectedValues.set(dimAlias, JSON.parse(JSON.stringify(selectedValues)));
      } else this.filterDimToSelectedValues.set(dimAlias, Array.from(selectedValues as Set<string>));
    });
  }
}
