import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal event-modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-close" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-field" }
const _hoisted_7 = { class: "modal-field-label" }
const _hoisted_8 = { class: "modal-field-value" }
const _hoisted_9 = { class: "modal-field" }
const _hoisted_10 = { class: "modal-field-label" }
const _hoisted_11 = { class: "modal-field-value employees-field-value" }
const _hoisted_12 = { class: "modal-field" }
const _hoisted_13 = { class: "modal-field-label" }
const _hoisted_14 = { class: "modal-field-value" }
const _hoisted_15 = { class: "modal-field" }
const _hoisted_16 = { class: "modal-field-label" }
const _hoisted_17 = { class: "modal-field-value" }
const _hoisted_18 = { class: "event-hour-ranges" }
const _hoisted_19 = { class: "event-hour" }
const _hoisted_20 = { class: "event-hour" }
const _hoisted_21 = { class: "modal-field" }
const _hoisted_22 = { class: "modal-field-label" }
const _hoisted_23 = { class: "modal-field-value" }
const _hoisted_24 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_Select = _resolveComponent("Select")!
  const _component_TimePicker = _resolveComponent("TimePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_close_outlined, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.calendarState.event.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.getEventCustomerLabel()), 1),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Select, {
              placeholder: _ctx.getSelectEventCustomerPh(),
              showLabels: true,
              width: 300,
              options: _ctx.getActiveCustomersIds(),
              selectedSingle: _ctx.calendarState.event.customer,
              customLabelFn: _ctx.formatUser,
              searchable: true,
              changeFn: _ctx.setEventCustomer
            }, null, 8, ["placeholder", "options", "selectedSingle", "customLabelFn", "changeFn"]),
            _withDirectives(_createElementVNode("div", { class: "error" }, _toDisplayString(_ctx.getEventCustomerErrorMsg()), 513), [
              [_vShow, !_ctx.calendarState.event.customerOk && _ctx.calendarState.event.toSave]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.getEventEmployeesLabel()), 1),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_Select, {
              placeholder: _ctx.getSelectEventEmployeesPh(),
              showLabels: true,
              width: 300,
              multiple: true,
              options: _ctx.getActiveEmployeesIds(),
              customLabelFn: _ctx.formatUser,
              searchable: true,
              selectedMultiple: _ctx.calendarState.event.employees,
              changeFn: _ctx.setEventEmployees
            }, null, 8, ["placeholder", "options", "customLabelFn", "selectedMultiple", "changeFn"]),
            _withDirectives(_createElementVNode("div", { class: "error" }, _toDisplayString(_ctx.getEventEmployeesErrorMsg()), 513), [
              [_vShow, !_ctx.calendarState.event.employeesOk && _ctx.calendarState.event.toSave]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.getEventPerformanceLabel()), 1),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_Select, {
              placeholder: _ctx.getSelectEventPerformancePh(),
              showLabels: true,
              width: 300,
              options: _ctx.getAllPerformancesIds(),
              selectedSingle: _ctx.calendarState.event.performance,
              customLabelFn: _ctx.getPefromanceName,
              searchable: true,
              changeFn: _ctx.setEventPerformance
            }, null, 8, ["placeholder", "options", "selectedSingle", "customLabelFn", "changeFn"]),
            _withDirectives(_createElementVNode("div", { class: "error" }, _toDisplayString(_ctx.getEventPerformanceErrorMsg()), 513), [
              [_vShow, !_ctx.calendarState.event.performanceOk && _ctx.calendarState.event.toSave]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.getEventHoursLabel()), 1),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("label", null, _toDisplayString(_ctx.getEventHoursLabelStart()), 1),
                _createVNode(_component_TimePicker, {
                  minTime: _ctx.getMinSelectableTime(),
                  maxTime: _ctx.getMaxSelectableTime(),
                  date: _ctx.calendarState.event.start,
                  changeFn: _ctx.setEventStart
                }, null, 8, ["minTime", "maxTime", "date", "changeFn"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("label", null, _toDisplayString(_ctx.getEventHoursLabelEnd()), 1),
                _createVNode(_component_TimePicker, {
                  minTime: _ctx.getMinSelectableTime(),
                  maxTime: _ctx.getMaxSelectableTime(),
                  date: _ctx.calendarState.event.end,
                  changeFn: _ctx.setEventEnd
                }, null, 8, ["minTime", "maxTime", "date", "changeFn"])
              ])
            ]),
            _withDirectives(_createElementVNode("div", { class: "error" }, _toDisplayString(_ctx.getTimeExtremesErrorMsg()), 513), [
              [_vShow, !_ctx.calendarState.event.timeExtremesOk && _ctx.calendarState.event.toSave]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.getSelectEventCostPh()), 1),
          _createElementVNode("div", _hoisted_23, [
            _withDirectives(_createElementVNode("input", {
              type: "number",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.calendarState.event.cost) = $event)),
              class: "form-control-thin",
              onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.validateEventCost && _ctx.validateEventCost(...args)))
            }, null, 544), [
              [_vModelText, _ctx.calendarState.event.cost]
            ]),
            _withDirectives(_createElementVNode("div", { class: "error" }, _toDisplayString(_ctx.getEventCostErrorMsg()), 513), [
              [_vShow, !_ctx.calendarState.event.costOk && _ctx.calendarState.event.toSave]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-confirm",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.saveEvent && _ctx.saveEvent(...args)))
        }, _toDisplayString(_ctx.getSaveString()), 1),
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-remove",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.tryDeleteEvent && _ctx.tryDeleteEvent(...args)))
        }, _toDisplayString(_ctx.getDeleteString()), 1),
        _createElementVNode("button", {
          class: "btn btn-outline btn-outline-discard",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, _toDisplayString(_ctx.getDiscardString()), 1)
      ])
    ])
  ]))
}