<template>
  <div class="modal pivot-modal pivot-fromats-modal">
    <div class="modal-content">
      <div class="modal-close">
        <close-outlined @click="closeModal()"></close-outlined>
      </div>
      <div class="modal-header">
        <div class="title-icon"><font-size-outlined></font-size-outlined></div>
        <h3>Formattazione</h3>
      </div>
      <div class="modal-body">
        <div class="entity-format-header">
          <h5>Dimensione</h5>
          <h5>Formato</h5>
        </div>
        <div class="entity-format" v-for="dim in getFormattableDimensions()" :key="dim.alias">
          <div class="entity-name">{{ dim.desc }}</div>
          <div class="entity-format-opts">
            <Select
              :placeholder="getSelectPh()"
              :showLabels="false"
              :width="270"
              :options="[...getEntityFormats(dim.alias)]"
              :selectedSingle="(dim.format as string)"
              :customLabelFn="getEntityFormatDesc"
              :searchable="false"
              :selectKey="dim.alias"
              :changeFn="setEntityFormat"
            >
            </Select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline btn-outline-confirm" @click="saveOptions">
          {{ getSaveString() }}
        </button>

        <button class="btn btn-outline btn-outline-discard" @click="closeModal">
          {{ getDiscardString() }}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { CloseOutlined, FontSizeOutlined } from "@ant-design/icons-vue";
import { DISCARD, SAVE } from "@/core/common/utils/ResourceStrings";
import { PIVOT_PLOC_KEY } from "@/components/common/GUIConsts";
import PivotPLOC from "@/core/pivot/presentation/PivotPLOC";
import { manageSubscription } from "@/components/common/SubscriptionManager";
import Select from "../../controls/Select.vue";
import { FORMATS_MODAL_ID } from "@/core/pivot/common/PivotConsts";

export default defineComponent({
  components: { CloseOutlined, FontSizeOutlined, Select },
  setup() {
    const pivotPLOC = inject(PIVOT_PLOC_KEY) as PivotPLOC;
    let pivotState = manageSubscription(pivotPLOC);

    const closeModal = () => {
      pivotPLOC.closeOptionsModal();
    };

    const getSaveString = () => {
      return SAVE;
    };
    const getDiscardString = () => {
      return DISCARD;
    };

    const saveOptions = async () => {
      pivotPLOC.saveOptions(FORMATS_MODAL_ID);
    };

    const getEntityFormatDesc = (entityFormatCode: string) => {
      return pivotPLOC.getEntityFormatDesc(entityFormatCode);
    };

    const setEntityFormat = (value: string, alias: string) => {
      pivotPLOC.setEntityFormat(value, alias);
    };

    const getSelectPh = () => {
      return "Seleziona un valore";
    };

    const getFormattableDimensions = () => {
      return pivotPLOC.getWorkingFormattableDimensions();
    };

    const getEntityFormats = (alias: string) => {
      return pivotPLOC.getEntityFormats(alias);
    };

    return {
      getSaveString,
      getDiscardString,
      closeModal,
      saveOptions,
      pivotState,
      getEntityFormatDesc,
      setEntityFormat,
      getSelectPh,
      getFormattableDimensions,
      getEntityFormats,
    };
  },
});
</script>

<style lang="scss">
@import "./public/styles/variables.scss";

/* Modal Content */

.pivot-fromats-modal {
  .modal-content {
    .modal-body {
      align-items: center;
      .entity-format-header {
        display: none;

        h5 {
          display: flex;
          width: 100%;
          justify-content: center;
        }
      }

      @media only screen and (min-width: 550px) {
        .entity-format-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          margin-bottom: 25px;
          width: 100%;
        }
      }

      .entity-format {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
        width: 100%;

        .entity-format-opts {
          display: flex;
          width: 100%;
        }

        .entity-name {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          margin-bottom: 5px;
        }

        @media only screen and (min-width: 550px) {
          .entity-format-opts {
            justify-content: center;
          }
        }
      }
      @media only screen and (min-width: 550px) {
        .entity-format {
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
        }
      }
    }
  }
}
</style>
