import {
  COST_MEAS_CODE,
  CUSTOMER_DIM_CODE,
  DATE_DIM_CODE,
  EMPLOYEES_DIM_CODE,
  FULL_USER_FORMAT,
  LONG_DATE_FORMAT,
  ORDER_TYPE_ASC,
  PERFORMANCE_DIM_CODE,
} from "../common/PivotConsts";

export interface IDateFilterValue {
  start: Date;
  end: Date;
}
export type TFilterValues = Set<string> | IDateFilterValue;

export interface IFilter {
  entityAlias: string;
  allValuesCount: number;
}

export interface IPivotOptions {
  columns: Array<IEntity>;
  rows: Array<IEntity>;
  measures: Array<IEntity>;
  measuresOnColumn: boolean;
  bigTotalOnRow: boolean;
  bigTotalOnColumn: boolean;
  subTotalsOnColumn: boolean;
  subTotalsOnRow: boolean;
  filters: Array<IFilter>;
}

export interface IEntity {
  desc: string;
  format: string | INumberFormatOptions;
  alias: string;
  orderBy: string;
  orderType: string;
}

export interface INumberFormatOptions {
  decimalSeparator: string;
  precision: number;
  groupSeparator: string;
  decimals: number;
  prefix: string;
  suffix: string;
}

export const allDimensions = [
  {
    alias: EMPLOYEES_DIM_CODE,
    desc: "Impiegati",
    format: FULL_USER_FORMAT,
    orderBy: EMPLOYEES_DIM_CODE,
    orderType: ORDER_TYPE_ASC,
  },
  {
    alias: CUSTOMER_DIM_CODE,
    desc: "Cliente",
    format: FULL_USER_FORMAT,
    orderBy: CUSTOMER_DIM_CODE,
    orderType: ORDER_TYPE_ASC,
  },
  {
    alias: PERFORMANCE_DIM_CODE,
    desc: "Prestazione",
    format: "",
    orderBy: PERFORMANCE_DIM_CODE,
    orderType: ORDER_TYPE_ASC,
  },
  {
    alias: DATE_DIM_CODE,
    desc: "Data",
    format: LONG_DATE_FORMAT,
    orderBy: DATE_DIM_CODE,
    orderType: ORDER_TYPE_ASC,
  },
];

export const allMeasures = [
  {
    alias: COST_MEAS_CODE,
    desc: "Fatturato",
    format: { decimalSeparator: ",", precision: 0, groupSeparator: ".", decimals: 2, prefix: "", suffix: "" },
    orderBy: "",
    orderType: "",
  },
];
export const defaultOptions = {
  columns: [
    {
      alias: EMPLOYEES_DIM_CODE,
      desc: "Impiegati",
      format: FULL_USER_FORMAT,
      orderBy: EMPLOYEES_DIM_CODE,
      orderType: ORDER_TYPE_ASC,
    },
  ],
  rows: [
    {
      alias: CUSTOMER_DIM_CODE,
      desc: "Cliente",
      format: FULL_USER_FORMAT,
      orderBy: CUSTOMER_DIM_CODE,
      orderType: ORDER_TYPE_ASC,
    },
    {
      alias: PERFORMANCE_DIM_CODE,
      desc: "Prestazione",
      format: "",
      orderBy: PERFORMANCE_DIM_CODE,
      orderType: ORDER_TYPE_ASC,
    },
    {
      alias: DATE_DIM_CODE,
      desc: "Data",
      format: LONG_DATE_FORMAT,
      orderBy: DATE_DIM_CODE,
      orderType: ORDER_TYPE_ASC,
    },
  ],
  measures: [
    {
      alias: COST_MEAS_CODE,
      desc: "Fatturato",
      format: { decimalSeparator: ",", precision: 0, groupSeparator: ".", decimals: 2, prefix: "", suffix: "" },
      orderBy: "",
      orderType: "",
    },
  ],
  measuresOnColumn: true,
  bigTotalOnRow: true,
  bigTotalOnColumn: true,
  subTotalsOnColumn: true,
  subTotalsOnRow: true,
  filters: new Array<IFilter>(),
};

export interface IListFilterValue {
  id: string;
  description: string;
  selected: boolean;
}
