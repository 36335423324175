import NotificationsManager from "@/core/notifications/domain/NotificationsManager";
import { IEventExtremesInfos } from "../CalendarEventDS";
import { GenericEventOperationResponse } from "../EventOperationsResponse";
import EventsRepository from "../EventsRepository";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";

export default class UpdateEventExtremesUseCase {
  public static async doUpdateEventExtremes(
    event: IEventExtremesInfos,
    eventsRepository: EventsRepository
  ): Promise<GenericEventOperationResponse> {
    const notificationsManager = NotificationsManager.getInstance();

    const result = await eventsRepository.updateEventExtremes(event);
    if (result.success)
      notificationsManager.pushNewNotification("Evento spostato con successo", "", NotificationType.Ok);
    else
      notificationsManager.pushNewNotification("Spostamento dell'evento fallito", result.errorMsg, NotificationType.Ko);

    return result;
  }
}
