import { HTTPClient, HTTPMethod, HTTPResponse } from "@/core/common/communication/HTTPClient";
import SessionHeartBeat, { ISessionHeartBeatResponse } from "../domain/SessionHeartBeat";

interface SessionHeartBeatResponse {
  result: boolean;
}

export default class SessionHeartBeatImpl extends SessionHeartBeat {
  private httpClient: HTTPClient;

  constructor(httpClient: HTTPClient) {
    super();
    this.httpClient = httpClient;
  }

  public async doSessionHearBeat(): Promise<ISessionHeartBeatResponse> {
    const result = {
      apiCallSuccess: false,
      sessionOk: false,
      apiCallErrorMsg: "",
    };
    const httpResponse: HTTPResponse = await this.httpClient.send(HTTPMethod.POST, "/api/sessionHeartBeat", {});
    if (httpResponse.success && <SessionHeartBeatResponse>httpResponse.data) {
      result.apiCallSuccess = true;
      result.sessionOk = (<SessionHeartBeatResponse>httpResponse.data).result;
    } else {
      result.apiCallSuccess = false;
      result.apiCallErrorMsg = httpResponse.errorMsg;
      console.error(
        "SessionHeartBeatImpl/doSessionHearBeat: error while calling /api/sessionHeartBeat, " + httpResponse.errorMsg
      );
    }

    return Promise.resolve(result);
  }
}
