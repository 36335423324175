import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createBlock(_component_VueDatePicker, {
    class: "app-date-picker",
    modelValue: _ctx.state.date,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.date) = $event)),
    inline: false,
    range: false,
    autoApply: true,
    "enable-time-picker": false,
    locale: 'it-IT',
    onInternalModelChange: _ctx.handleInternal,
    "calendar-cell-class-name": "dp-custom-cell"
  }, null, 8, ["modelValue", "onInternalModelChange"]))
}