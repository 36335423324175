import CalendarEvent from "../CalendarEvent";
import { TCalendarEventWithValidation } from "../CalendarEventDS";

export default class EventValidationUseCase {
  public static validateEventExtremes(currentEvent: TCalendarEventWithValidation) {
    const newEvent = {
      ...currentEvent,
      timeExtremesOk: CalendarEvent.getInstance().validateExtremes(currentEvent),
    };
    return newEvent;
  }

  public static validateEventCustomer(currentEvent: TCalendarEventWithValidation) {
    const newEvent = {
      ...currentEvent,
      customerOk: CalendarEvent.getInstance().validateCustomer(currentEvent),
    };
    return newEvent;
  }
  public static validateEventPerformance(currentEvent: TCalendarEventWithValidation) {
    const newEvent = {
      ...currentEvent,
      performanceOk: CalendarEvent.getInstance().validatePerformance(currentEvent),
    };
    return newEvent;
  }
  public static validateEventEmployees(currentEvent: TCalendarEventWithValidation) {
    const newEvent = {
      ...currentEvent,
      employeesOk: CalendarEvent.getInstance().validateEmployees(currentEvent),
    };
    return newEvent;
  }
  public static validateEventCost(currentEvent: TCalendarEventWithValidation) {
    const newEvent = {
      ...currentEvent,
      costOk: CalendarEvent.getInstance().validateCost(currentEvent),
    };
    return newEvent;
  }

  public static validateEvent(currentEvent: TCalendarEventWithValidation) {
    const calendarEvent = CalendarEvent.getInstance();
    const costOk = calendarEvent.validateCost(currentEvent);
    const employeesOk = calendarEvent.validateEmployees(currentEvent);
    const performanceOk = calendarEvent.validatePerformance(currentEvent);
    const customerOk = calendarEvent.validateCustomer(currentEvent);
    const timeExtremesOk = calendarEvent.validateExtremes(currentEvent);
    const canSave = customerOk && employeesOk && costOk && performanceOk && timeExtremesOk;

    return {
      canSave,
      validatedEvent: {
        ...currentEvent,
        costOk,
        employeesOk,
        performanceOk,
        customerOk,
        timeExtremesOk,
        toSave: true,
      },
    };
  }
}
