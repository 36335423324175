export enum HTTPMethod {
  GET,
  POST,
}
export interface HTTPResponse {
  success: boolean;
  errorMsg: string;
  data: object;
}
export abstract class HTTPClient {
  public abstract send(method: HTTPMethod, url: string, params: object): Promise<HTTPResponse>;
}
