import { v4 as uuidv4 } from "uuid";
import PerformanceDS, { TPerformanceWithValidation } from "../PerformanceDS";
import PerformancesRepository from "../PerformancesRepository";
import NotificationsManager from "@/core/notifications/domain/NotificationsManager";
import { NotificationType } from "@/core/notifications/domain/NotificationDS";

export default class PerformanceDetailUseCase {
  public static createNewPerformance() {
    return {
      id: uuidv4(),
      name: "",
      duration: 0,
      cost: 0,
      deleted: false,
      nameOk: false,
      durationOk: false,
      isNew: true,
      costOk: false,
    };
  }

  public static validateName(name: string) {
    return name !== "" && name.length <= 20;
  }

  public static validateDuration(duration: number) {
    return duration >= 0;
  }

  public static validateCost(cost: number) {
    return cost >= 0;
  }

  public static validatePerformance(performanceDetail: TPerformanceWithValidation) {
    const nameOk = PerformanceDetailUseCase.validateName(performanceDetail.name);
    const durationOk: boolean = PerformanceDetailUseCase.validateDuration(performanceDetail.duration);
    const costOk: boolean = PerformanceDetailUseCase.validateCost(performanceDetail.cost);
    return {
      costOk,
      durationOk,
      nameOk,
      saving: true,
      canSave: nameOk && durationOk && costOk,
    };
  }

  public static async savePerformance(
    performanceDetail: TPerformanceWithValidation,
    performanceRepository: PerformancesRepository
  ) {
    let saveResult = { success: true, errorMsg: "" };
    const notificationsManager = NotificationsManager.getInstance();
    let okMsg = "";
    let koMsg = "";
    const performanceToSave: PerformanceDS = {
      id: performanceDetail.id,
      name: performanceDetail.name,
      duration: performanceDetail.duration,
      cost: performanceDetail.cost,
      deleted: false,
    };

    if (performanceDetail.isNew) {
      saveResult = await performanceRepository.savePerformance(performanceToSave);
      okMsg = "Prestazione creata con successo";
      koMsg = "Creazione della prestazione fallita";
    } else {
      saveResult = await performanceRepository.updatePerformance(performanceToSave);
      okMsg = "Prestazione aggiornata con successo";
      koMsg = "Aggiornamento della prestazione fallito";
    }

    if (saveResult.success) notificationsManager.pushNewNotification(okMsg, "", NotificationType.Ok);
    else notificationsManager.pushNewNotification(koMsg, saveResult.errorMsg, NotificationType.Ko);

    return saveResult;
  }

  public static discardChanges() {
    return {
      id: "",
      name: "",
      duration: 0,
      cost: 0,
      deleted: false,
      nameOk: false,
      durationOk: false,
      isNew: false,
      costOk: false,
      saving: false,
    };
  }

  public static editPerformance(userId: string, performanceRepository: PerformancesRepository) {
    const performance = performanceRepository.getPerformanceById(userId);
    return {
      ...performance,
      visible: true,
      title: "",
      nameOk: false,
      durationOk: false,
      costOk: false,
      saving: false,
      isNew: false,
    };
  }
}
